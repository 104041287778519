<template>
  <div class="grid grid-cols-8 gap-4">
    <div class="col-span-4 md:col-span-2">
      <label for="linkType" class="block text-sm font-medium text-gray-700">{{
        t('vacancy.publication.linkType.label')
      }}</label>
      <select
        id="linkType"
        name="linkType"
        class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        :value="linkType"
        @change="(e) => onLinkTypeChange(e.target.value)"
      >
        <option value="other" disabled></option>
        <option value="link">
          {{ t('vacancy.publication.linkType.link') }}
        </option>
        <option value="email">
          {{ t('vacancy.publication.linkType.email') }}
        </option>
      </select>
    </div>
    <div class="col-span-8 md:col-span-6">
      <FormInput
        v-if="linkType === 'email'"
        :id="`${siteId}applicationUrl`"
        type="email"
        :value="applicationUrl.substring(7)"
        placeholder="you@example.com"
        :onInput="(newEmail) => updateApplicationUrl(`mailto:${newEmail}`)"
        :rules="rules.email"
        :label="t(`vacancy.publication.linkType.${linkType}`) + '*'"
        inputClass="pl-10"
      >
        <template #inputPrefix>
          <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </template>
      </FormInput>
      <FormInput
        v-else-if="linkType === 'link'"
        :id="`${siteId}applicationUrl`"
        type="text"
        :value="applicationUrl.substring(8)"
        placeholder="www.example.com"
        :onInput="(newUrl) => onLinkInput(newUrl)"
        :rules="rules.link"
        :label="t(`vacancy.publication.linkType.${linkType}`) + '*'"
        inputClass="pl-16"
      >
        <template #inputPrefix>
          <span class="text-gray-500 sm:text-sm">https://</span>
        </template>
      </FormInput>
      <FormInput
        v-else
        :id="`${siteId}applicationUrl`"
        type="text"
        :value="applicationUrl || ''"
        placeholder="www.example.com"
        :onInput="(newLink) => updateApplicationUrl(newLink)"
        :rules="rules.link"
        :label="t(`vacancy.publication.linkType.${linkType}`) + '*'"
      />
    </div>
  </div>
</template>

<script setup>
import { useVacancyStore } from '../../store/vacancyStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { EnvelopeIcon } from '@heroicons/vue/20/solid'
import { required, email, helpers } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'

const props = defineProps(['siteId', 'applicationUrl'])

const vacancyStore = useVacancyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const linkType = computed(() => {
  if (props.applicationUrl.startsWith('mailto:')) return 'email'
  return 'link'
})

const updateApplicationUrl = (applicationUrl) => {
  const publication = vacancyStore.publications.find(
    (p) => p.siteId === props.siteId,
  )
  publication[`${publication.scope}ApplicationUrl`] = applicationUrl
}

const onLinkTypeChange = (value) => {
  if (value === 'email') updateApplicationUrl('mailto:')
  if (value === 'link') updateApplicationUrl('https://')
}

const onLinkInput = (newValue) => {
  const newValueWithoutProtocol = newValue
    .replace(/https:\/\//g, '')
    .replace(/http:\/\//g, '')
  updateApplicationUrl(`https://${newValueWithoutProtocol}`)
}

const domainRegex =
  /^(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
const domain = helpers.regex(domainRegex)

const rules = {
  email: {
    required: helpers.withMessage(
      t('validations.applicationUrl.email.required'),
      required,
    ),
    email: helpers.withMessage(
      t('validations.applicationUrl.email.email'),
      email,
    ),
    $autoDirty: true,
  },
  link: {
    required: helpers.withMessage(
      t('validations.applicationUrl.link.required'),
      required,
    ),
    domain: helpers.withMessage(
      t('validations.applicationUrl.link.domain'),
      domain,
    ),
    $autoDirty: true,
  },
}
</script>
