<template>
  <div class="grid grid-cols-1 gap-6 lg:grid-cols-9">
    <div class="lg:col-span-3">
      <div>
        <label
          for="salaryMin"
          class="block text-sm font-medium text-gray-700"
          >{{ t('minSalary') }}</label
        >
        <div class="relative mt-1 rounded-md shadow-sm">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <span class="text-gray-500 sm:text-sm">€</span>
          </div>
          <input
            id="salaryMin"
            type="number"
            name="salaryMin"
            :min="0"
            :value="vacancyStore.vacancy.salary.min"
            class="hide-arrows block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-describedby="price-currency"
            @change="changeSalaryMin"
          />
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <span id="price-currency" class="text-gray-500 sm:text-sm">
              /
              {{
                t(
                  `vacancy.salary.salaryPeriod.${vacancyStore.vacancy.salaryPeriod}`,
                )
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:col-span-3">
      <div>
        <label
          for="salaryMax"
          class="block text-sm font-medium text-gray-700"
          >{{ t('maxSalary') }}</label
        >
        <div class="relative mt-1 rounded-md shadow-sm">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <span class="text-gray-500 sm:text-sm">€</span>
          </div>
          <input
            id="salaryMax"
            type="number"
            name="salaryMax"
            :min="0"
            :value="vacancyStore.vacancy.salary.max"
            class="hide-arrows block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-describedby="price-currency"
            @change="changeSalaryMax"
          />
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <span id="price-currency" class="text-gray-500 sm:text-sm">
              /
              {{
                t(
                  `vacancy.salary.salaryPeriod.${vacancyStore.vacancy.salaryPeriod}`,
                )
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:col-span-3">
      <label
        for="periodicity"
        class="block text-sm font-medium text-gray-700"
        >{{ t('vacancy.salary.salaryPeriod.title') }}</label
      >
      <select
        id="periodicity"
        v-model="vacancyStore.vacancy.salaryPeriod"
        name="salaryScale"
        class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        @change="updateSalaryPeriod"
      >
        <option value="BPU">
          per {{ t('vacancy.salary.salaryPeriod.BPU') }}
        </option>
        <option value="BPD">
          per {{ t('vacancy.salary.salaryPeriod.BPD') }}
        </option>
        <option value="BPW">
          per {{ t('vacancy.salary.salaryPeriod.BPW') }}
        </option>
        <option value="BPM">
          per {{ t('vacancy.salary.salaryPeriod.BPM') }}
        </option>
        <option value="BPJ">
          per {{ t('vacancy.salary.salaryPeriod.BPJ') }}
        </option>
      </select>
    </div>
    <div class="lg:col-span-9">
      <label
        for="salaryScale"
        class="block text-sm font-medium text-gray-700"
        >{{ t('vacancy.salary.scale') }}</label
      >
      <select
        id="salaryScale"
        v-model="salaryScale"
        name="salaryScale"
        class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        @change="autofillSalaryScale"
      >
        <option disabled value="">{{ t('autofill') }}</option>
        <option
          v-for="scale in vacancyStore.salaryScales"
          :key="scale.name"
          :value="scale"
        >
          {{ scale.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useVacancyStore } from '../../store/vacancyStore'
import { ref } from 'vue'

const vacancyStore = useVacancyStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const salaryScale = ref({})
const changeSalaryMin = (e) => {
  const input = parseFloat(e.target.value)
  vacancyStore.vacancy.salary.min = input
  if (input > vacancyStore.vacancy.salary.max) {
    vacancyStore.vacancy.salary.max = input
  }
}
const changeSalaryMax = (e) => {
  const input = parseFloat(e.target.value)
  vacancyStore.vacancy.salary.max = input
  if (input < vacancyStore.vacancy.salary.min) {
    vacancyStore.vacancy.salary.min = input
  }
}

const updateSalaryPeriod = (e) => {
  vacancyStore.vacancy.salaryPeriod = e.target.value
}

const autofillSalaryScale = () => {
  vacancyStore.vacancy.salary.max = salaryScale.value.max
  vacancyStore.vacancy.salary.min = salaryScale.value.min
}
</script>
