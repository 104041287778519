import Dashboard from './views/Dashboard.vue'
import Settings from './views/Settings.vue'
import Vacancies from './views/Vacancies.vue'
import Sites from './views/Sites.vue'
import Publishers from './views/Publishers.vue'
import Publisher from './views/Publisher.vue'
import Vacancy from './views/Vacancy.vue'
import Site from './views/Site.vue'
import Companies from './views/Companies.vue'
import Company from './views/Company.vue'
import NotFound from './views/NotFound.vue'
import Forbidden from './views/Forbidden.vue'
import BeforeAuth from './views/BeforeAuth.vue'
import AllCandidates from './views/AllCandidates.vue'
import Candidate from './views/Candidate.vue'
import Flows from './views/Flows.vue'
import Flow from './views/Flow.vue'
import SalaryScales from './views/SalaryScales.vue'
import SalaryScale from './views/SalaryScale.vue'
import Subscriptions from './views/Subscriptions.vue'
import JobAlerts from './views/JobAlerts.vue'
import JobAlert from './views/JobAlert.vue'

export const routes = [
  {
    path: '/auth/callback',
    component: BeforeAuth,
  },
  {
    path: '/signup',
    component: BeforeAuth,
  },
  { path: '/', component: Dashboard },
  { path: '/vacancies', component: Vacancies },
  { path: '/vacancies/:id/vacancy', component: Vacancy, name: 'vacancy' },
  {
    path: '/vacancies/:id/publications',
    component: Vacancy,
    name: 'publications',
  },
  {
    path: '/vacancies/:id/campaign-pages',
    component: Vacancy,
    name: 'campaignPages',
  },
  { path: '/vacancies/:id/candidates', component: Vacancy, name: 'candidates' },
  {
    path: '/vacancies/:id/candidates/:candidateId',
    component: Vacancy,
    name: 'candidateDetail',
  },
  {
    path: '/vacancies/:id/flows',
    component: Vacancy,
    name: 'flows',
  },
  {
    path: '/sites',
    component: Sites,
  },
  {
    path: '/sites/:id/site',
    component: Site,
    name: 'site',
  },
  {
    path: '/sites/:id/tags',
    component: Site,
    name: 'tags',
  },
  {
    path: '/sites/:id/tags/:tagGroupId',
    component: Site,
    name: 'tagDetail',
  },
  { path: '/flows', component: Flows, name: 'publisherFlows' },
  { path: '/flows/:id', component: Flow },
  {
    path: '/publishers',
    component: Publishers,
  },
  { path: '/salary-scales', component: SalaryScales, name: 'salaryScales' },
  {
    path: '/salary-scales/:id',
    component: SalaryScale,
  },
  {
    path: '/job-alerts',
    component: JobAlerts,
    name: 'jobAlerts',
  },
  {
    path: '/job-alerts/:id',
    component: JobAlert,
    name: 'jobAlert',
  },
  {
    path: '/publishers/:id/general',
    component: Publisher,
    name: 'general',
  },
  {
    path: '/publishers/:id/users',
    component: Publisher,
    name: 'users',
  },
  {
    path: '/publishers/:id/subscription',
    component: Publisher,
    name: 'publisherSubscription',
  },
  {
    path: '/companies',
    component: Companies,
  },
  {
    path: '/companies/:id/company',
    component: Company,
    name: 'company',
  },
  {
    path: '/companies/:id/importer',
    component: Company,
    name: 'importer',
  },
  {
    path: '/companies/:id/events',
    component: Company,
    name: 'events',
  },
  {
    path: '/companies/:id/events/:eventId',
    component: Company,
    name: 'eventDetail',
  },
  {
    path: '/companies/:id/subscription',
    component: Company,
    name: 'companySubscription',
  },
  {
    path: '/subscriptions',
    component: Subscriptions,
  },
  { path: '/candidates', component: AllCandidates, name: 'allCandidates' },
  { path: '/candidates/:candidateId', component: Candidate, name: 'Candidate' },
  { path: '/settings', component: Settings },
  { path: '/403', component: Forbidden },
  { path: '/:path(.*)', component: NotFound },
]
