import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'

export const useJobAlertStore = defineStore('jobAlertStore', {
  state: () => ({
    isLoading: false,
    isDeletedSuccessfully: false,
    error: null,
    vacancies: [],
    jobAlert: {},
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load(id) {
      this.error = null
      this.isLoading = true

      const params = {
        page: this.page.number,
        size: this.page.size,
      }

      try {
        const jobAlert = await tooManagement.getJobAlert(id)
        const vacancies = await tooManagement.getJobAlertVacancies(id, params)

        this.jobAlert = jobAlert.data

        if (vacancies.meta.page.number === this.page.number) {
          this.vacancies = vacancies.data
          this.page = vacancies.meta.page
        }
      } catch (error) {
        this.jobAlert = {}
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
  },
})
