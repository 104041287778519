<template>
  <div
    class="grid items-center gap-4 bg-white px-4 py-4 sm:grid-cols-[14rem_1fr_18rem_14rem] sm:px-5"
  >
    <div>
      <p
        class="inline-flex h-fit rounded-full bg-gray-100 px-2 text-xs font-medium leading-5 text-gray-800"
      >
        {{ timeAgo.format(new Date(snapshot.createdDate)) }}
      </p>
      <p class="mt-1 flex items-center justify-between text-xs text-gray-500">
        {{ createdDate }}
      </p>
    </div>
    <div class="flex flex-col">
      <p
        v-for="(change, index) in snapshot.changes"
        :key="index"
        class="mt-1 flex items-center text-sm text-gray-600"
      >
        {{ change }}
      </p>
    </div>
    <div>
      <p class="mt-1 flex items-center justify-between text-sm text-indigo-600">
        {{
          `${snapshot.planCredits} ${t('company.subscription.snapshot.totalCredits')}`
        }}
      </p>
    </div>
    <div class="flex gap-x-4">
      <p
        class="mt-1 flex items-center justify-between break-all text-xs text-gray-500"
      >
        {{ snapshot.createdBy }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'
import TimeAgo from 'javascript-time-ago'

const props = defineProps({
  snapshot: {
    required: true,
    type: Object,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const timeAgo = new TimeAgo()

const createdDate = computed(() => {
  return format(props.snapshot.createdDate, 'dd/MM/yyyy, HH:mm')
})
</script>
