<template>
  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6 xl:col-span-3">
      <FormImage
        id="logoUrl"
        :label="t('company.logoUrl')"
        :imageUrl="logoUrl"
        :rules="imageRules"
        :recommendation="t('company.logo.recommendation')"
        @imagechange="updateLogoImage"
        @discard-image="discardLogoImage"
      />
    </div>
    <div class="col-span-6 xl:col-span-3">
      <FormImage
        id="boxPhotoUrl"
        :label="t('company.boxPhotoUrl')"
        :imageUrl="boxPhotoUrl"
        :rules="imageRules"
        :recommendation="t('company.boxPhoto.recommendation')"
        @imagechange="updateBoxPhotoImage"
        @discard-image="discardBoxPhotoImage"
      />
    </div>
    <div class="col-span-6">
      <FormImage
        id="coverPhotoUrl"
        :label="t('company.coverPhotoUrl')"
        :imageUrl="coverPhotoUrl"
        :rules="imageRules"
        :recommendation="t('company.coverPhoto.recommendation')"
        @imagechange="updateCoverPhotoImage"
        @discard-image="discardCoverPhotoImage"
      />
    </div>
    <div class="col-span-6">
      <FormCarousel
        :label="t('company.carouselImages')"
        :modelValue="carouselImages"
        @update:model-value="updateCarouselImages"
      />
    </div>
  </div>
</template>

<script setup>
import { useCompanyStore } from '../../store/companyStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import FormImage from '../FormImage.vue'
import FormCarousel from '../FormCarousel.vue'
import { getImgSrc } from '../../utils/imgSrc'

const companyStore = useCompanyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const boxPhotoUrl = computed(() =>
  getImgSrc({
    imgUrl: companyStore.company.boxPhotoUrl,
    height: 192,
  }),
)

const imageRules = {
  $autoDirty: true,
}

const updateBoxPhotoImage = (newValue) => {
  companyStore.boxPhotoImage = newValue
}

const discardBoxPhotoImage = () => {
  companyStore.boxPhotoImage = null
  companyStore.company.boxPhotoUrl = null
}

const coverPhotoUrl = computed(() =>
  getImgSrc({
    imgUrl: companyStore.company.coverPhotoUrl,
    height: 210,
  }),
)

const updateCoverPhotoImage = (newValue) => {
  companyStore.coverPhotoImage = newValue
}

const discardCoverPhotoImage = () => {
  companyStore.coverPhotoImage = null
  companyStore.company.coverPhotoUrl = null
}

const logoUrl = computed(() =>
  getImgSrc({
    imgUrl: companyStore.company.logoUrl,
    trim: 'color',
    trimColor: 'fff',
  }),
)

const updateLogoImage = (newValue) => {
  companyStore.logoImage = newValue
}
const discardLogoImage = () => {
  companyStore.logoImage = null
  companyStore.company.logoUrl = null
}

const carouselImages = computed(() => companyStore.company.carouselImagesUrls)

const updateCarouselImages = (images) => {
  companyStore.company.carouselImagesUrls = images
}
</script>
