import { emailTemplateContent } from './emailTemplates.js'

const emailConfig = (to, from, fromName, replyTo, subject, content) => ({
  to,
  from,
  fromName,
  replyTo,
  subject,
  content,
})

const atsConfig = (params, secretParams = {}) => ({
  params,
  secretParams,
})

export const flowActionTemplates = {
  'apply-candidate': emailConfig(
    '{{candidate.email}}',
    'no-reply@jobsrepublic.nl',
    '{{vacancy.contactName}}',
    '',
    'Thank you for your application',
    emailTemplateContent.candidateApplyEmailContent,
  ),
  'share-candidate': emailConfig(
    '{{candidate.email}}',
    '{{site.fromEmail}}',
    '{{site.name}}',
    '{{vacancy.contactEmail}}',
    'Vacature link: {{vacancy.title}}',
    emailTemplateContent.candidateShareByEmailContent,
  ),
  'apply-client': emailConfig(
    '{{vacancy.contactEmail}}',
    'no-reply@jobsrepublic.nl',
    '{{vacancy.contactName}}',
    '',
    'New candidate for {{vacancy.title}}',
    emailTemplateContent.clientApplyEmailContent,
  ),
  'contact-form-client': emailConfig(
    '{{vacancy.contactEmail}}',
    '{{site.fromEmail}}',
    '{{site.name}}',
    '{{candidate.email}}',
    'Vraag over de {{vacancy.title}} vacature: {{candidate.fullName}}',
    emailTemplateContent.clientContactEmailContent,
  ),
  'job-alert-client': emailConfig(
    '{{vacancy.contactEmail}}',
    '{{site.fromEmail}}',
    '{{site.name}}',
    '',
    'Nieuwe jobalert-abonnement voor vacature "{{vacancy.title}}": {{candidate.fullName}}',
    emailTemplateContent.clientJobAlertEmailContent,
  ),
  'newsletter-signup-client': emailConfig(
    '{{vacancy.contactEmail}}',
    '{{site.fromEmail}}',
    '{{site.name}}',
    '',
    'Nieuw nieuwsbriefabonnement voor vacature "{{vacancy.title}}": {{candidate.fullName}}',
    emailTemplateContent.clientNewsletterSignupEmailContent,
  ),
  'ats-recruitee': atsConfig({
    applyUrl: '{{publication.application_url}}',
    companyDomain: '',
    vacancySlug: '{{vacancy.slug}}',
  }),
  'ats-afas': atsConfig(
    {
      vacancyId: '',
      countryCode: 'NL',
      afasUrl:
        'https://60103.rest.afas.online/ProfitRestServices/connectors/HrCreateApplicant',
    },
    { afasToken: '' },
  ),
  'ats-connexys': atsConfig(
    {
      baseUrl: '',
      applyUrl: '',
      issuer: '',
      publicKey: '',
    },
    { privateKey: '' },
  ),
  'ats-mysolution': atsConfig(
    {
      portalDomain: '',
      companyDomain: '',
      clientId: '',
      username: '',
      jobId: '',
    },
    { clientSecret: '', password: '' },
  ),
}
