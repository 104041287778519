<template>
  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6">
      <FormInput
        id="vacancyTitle"
        type="text"
        :value="vacancyStore.vacancy.title"
        :label="t('vacancy.title') + '*'"
        :onInput="updateTitle"
        :rules="titleRules"
        :helpPopoverText="t('helpMessages.vacancyTitle')"
      />
    </div>
  </div>
  <div v-if="authStore.isAdmin" class="mt-6 grid grid-cols-6 gap-6">
    <div class="col-span-6">
      <FormInput
        id="vacancySlug"
        type="text"
        :value="vacancyStore.vacancy.slug"
        :label="t('vacancy.slug') + '*'"
        :onInput="updateSlug"
        :rules="slugRules"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useVacancyStore } from '../../store/vacancyStore'
import { required, helpers } from '@vuelidate/validators'
import { useAuthStore } from '../../store/authStore'
import FormInput from '../FormInput.vue'
import slugify from 'slugify'

const vacancyStore = useVacancyStore()
const authStore = useAuthStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const titleRules = {
  required: helpers.withMessage(
    t('validations.vacancyTitle.required'),
    required,
  ),
  $autoDirty: true,
}

const slugRules = {
  required: helpers.withMessage(t('validations.slug.required'), required),
  $autoDirty: true,
}

const updateSlug = (newSlug) => {
  const slugified = slugify(newSlug, { lower: true, strict: true })
  vacancyStore.vacancy.slug = slugified
}

const updateTitle = (newTitle) => {
  let doSlugify = false
  const previousSlug = slugify(vacancyStore.vacancy.title, {
    lower: true,
    strict: true,
  })
  if (
    previousSlug === vacancyStore.vacancy.slug ||
    !vacancyStore.vacancy.slug
  ) {
    doSlugify = true
  }
  vacancyStore.vacancy.title = newTitle
  if (doSlugify) {
    vacancyStore.vacancy.slug = slugify(newTitle, {
      lower: true,
      strict: true,
    })
  }
}
</script>
