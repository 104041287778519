<template>
  <div class="px-6 py-6">
    <div class="grid grid-cols-2 gap-x-2 gap-y-3">
      <div class="col-span-2">
        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="useApplyUrl"
              aria-describedby="use-apply-url"
              :disabled="isPreview"
              name="atsApplyType"
              type="radio"
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              :checked="flowStore.activeAction.paramSource === 'applyUrl'"
              @change="changeParamSource"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="useApplyUrl" class="font-medium text-gray-700">
              {{ t('vacancy.flows.atsFields.useApplyUrl') }}
            </label>
          </div>
        </div>
      </div>

      <div class="col-span-2">
        <FormInput
          id="applyUrl"
          placeholder=""
          type="text"
          :disabled="applyByUrlAtsDisabled || isPreview"
          :inputClass="applyByUrlAtsDisabled || isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.applyUrl"
          :label="`${t('vacancy.flows.atsFields.applyUrl')}*`"
          :onInput="updateApplyUrl"
          :rules="applyUrlRequired"
        />
      </div>

      <div class="col-span-2 mt-6">
        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="useManual"
              aria-describedby="use-manual"
              name="atsApplyType"
              type="radio"
              :disabled="isPreview"
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              :checked="flowStore.activeAction.paramSource === 'manual'"
              @change="changeParamSource"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="useManual" class="font-medium text-gray-700">
              {{ t('vacancy.flows.atsFields.useCompanyAndVacancySlug') }}
            </label>
          </div>
        </div>
      </div>

      <div class="col-span-1">
        <FormInput
          id="companyCode"
          :disabled="manualAtsInputDisabled || isPreview"
          :inputClass="manualAtsInputDisabled || isPreview ? 'bg-gray-100' : ''"
          type="text"
          :value="flowStore.activeAction.config.params.companyDomain"
          :label="`${t('vacancy.flows.atsFields.companyDomain')}*`"
          :onInput="updateCompanyCode"
          :rules="vacancySlugRequired"
        />
      </div>

      <div class="col-span-1">
        <FormInput
          id="vacancySlug"
          type="text"
          :disabled="manualAtsInputDisabled || isPreview"
          :inputClass="manualAtsInputDisabled || isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.vacancySlug"
          :label="`${t('vacancy.flows.atsFields.vacancySlug')}*`"
          :onInput="updateVacancySlug"
          :rules="vacancySlugRequired"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import FormInput from '../FormInput.vue'
import { useFlowStore } from '../../store/flowStore'
import { helpers, requiredIf } from '@vuelidate/validators'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const props = defineProps({
  isPreview: {
    type: Boolean,
    default: false,
  },
})

onBeforeMount(() => {
  if (flowStore.activeAction.config.params.applyUrl) {
    flowStore.activeAction.paramSource = 'applyUrl'
  } else {
    flowStore.activeAction.paramSource = 'manual'
  }
})

const updateApplyUrl = (value) => {
  flowStore.activeAction.config.params.applyUrl = value
}
const updateCompanyCode = (value) => {
  flowStore.activeAction.config.params.companyDomain = value
}
const updateVacancySlug = (value) => {
  flowStore.activeAction.config.params.vacancySlug = value
}
const manualAtsInputDisabled = computed(
  () => flowStore.activeAction.paramSource === 'applyUrl',
)
const applyByUrlAtsDisabled = computed(
  () => flowStore.activeAction.paramSource === 'manual',
)

const changeParamSource = (event) => {
  if (event.target.id === 'useApplyUrl') {
    flowStore.activeAction.paramSource = 'applyUrl'
    flowStore.activeAction.config.params.companyDomain = ''
    flowStore.activeAction.config.params.vacancySlug = ''
  }
  if (event.target.id === 'useManual') {
    flowStore.activeAction.paramSource = 'manual'
    flowStore.activeAction.config.params.applyUrl = ''
  }
}

const applyUrlRequired = {
  required: helpers.withMessage(
    t('validations.emptyField'),
    requiredIf(() => manualAtsInputDisabled.value),
  ),
}

const vacancySlugRequired = {
  required: helpers.withMessage(
    t('validations.emptyField'),
    requiredIf(() => applyByUrlAtsDisabled.value),
  ),
}

const flowStore = useFlowStore()
</script>
