<template>
  <Listbox v-slot="{ open }" v-model="cronSchedule" class="col-span-6">
    <div class="relative mt-1">
      <ListboxLabel class="block text-sm font-medium text-gray-700">{{
        t('company.importer.updateEvery')
      }}</ListboxLabel>
      <ListboxButton
        class="relative mt-1 min-h-[2.4rem] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
      >
        <span class="block truncate">{{ cronSchedule?.label }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronDownIcon
            class="mr-1 h-5 w-5 text-gray-400"
            :class="open ? 'rotate-180' : 'rotate-0'"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leaveActiveClass="transition duration-100 ease-in"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <ListboxOptions
          class="absolute z-[1] max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption value="" />
          <ListboxOption
            v-for="interval in importerStore.availableIntervals"
            v-slot="{ active, selected }"
            :key="interval.value"
            :value="interval"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900',
                'relative cursor-default select-none px-4 py-2',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
                >{{ interval.label }}</span
              >
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
      <div class="mt-1 text-sm text-gray-700">
        <span v-if="nextImport"
          >{{ t('company.importer.nextImportLabel') }}:
          <span class="font-semibold">{{ nextImport }}</span></span
        >
        <span v-else>{{ t('company.importer.firstImportInfo') }}</span>
      </div>
    </div>
  </Listbox>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useImporterStore } from '../../store/importerStore'
import { addMinutes, format } from 'date-fns'

const importerStore = useImporterStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const nextImport = computed(() => {
  if (importerStore.events.length) {
    const minutes =
      Number(importerStore.activeConfig.cronSchedule.substr(4, 2)) || 0
    const nextTime = addMinutes(
      new Date(importerStore.events[0].dateFinished),
      minutes,
    )
    return format(nextTime, 'dd MMM yyyy, HH:mm')
  }
  return null
})

const cronSchedule = computed({
  get() {
    return (
      importerStore.availableIntervals.find(
        (interval) =>
          interval.value === importerStore.activeConfig.cronSchedule,
      ) || {}
    )
  },
  set(newValue) {
    importerStore.activeConfig.cronSchedule = newValue.value
  },
})
</script>
