<template>
  <li>
    <div class="block">
      <div class="px-4 py-4 sm:px-6">
        <div class="flex items-center justify-between">
          <div class="flex shrink-0 pb-6 pt-6">
            <img
              v-if="site.fullLogoUrl"
              :src="site.fullLogoUrl"
              class="h-6"
              :alt="site.name"
            />
            <div v-else class="text-lg font-medium leading-6 text-gray-900">
              {{ site.name }}
            </div>
            <span
              v-if="publication?.status === 'published'"
              class="mx-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
            >
              {{ t('vacancy.publication.badges.published') }}
            </span>
            <span
              v-if="publication?.status === 'scheduled'"
              class="mx-2 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800"
            >
              {{ t('vacancy.publication.badges.scheduled') }}
            </span>
            <span
              v-if="publication?.status === 'closed'"
              class="mx-2 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
            >
              {{ t('vacancy.publication.badges.closed') }}
            </span>
          </div>
          <Toggle
            v-if="!publication || (publication && !isSavedOnServer)"
            srLabel="Publication"
            :checked="publication"
            :onToggle="onToggle"
          />
          <button
            v-if="publication?.status === 'published'"
            type="button"
            class="inline-flex items-center rounded border border-transparent bg-red-100 px-2.5 py-1.5 text-xs font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            @click="endNow"
          >
            {{ t('vacancy.publication.endNow') }}
          </button>
        </div>
        <Publication
          v-if="publication"
          :publication="publication"
          :siteTags="site.tags || []"
        />
      </div>
    </div>
  </li>
</template>

<script setup>
import Toggle from '../Toggle.vue'
import Publication from './Publication.vue'
import { computed } from 'vue'
import { useVacancyStore } from '../../store/vacancyStore'
import { startOfDay, endOfDay, addDays } from 'date-fns'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  site: Object,
})

const vacancyStore = useVacancyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const publication = computed(() =>
  vacancyStore.publications.find((p) => p.siteId === props.site.id),
)

const isSavedOnServer = computed(
  () => typeof publication.value?.id !== 'undefined',
)

const makeNewPublication = ({
  siteId,
  externalEndDate = null,
  externalStartDate = null,
  tags = {},
}) => {
  return {
    scope: 'external',
    externalApplicationUrl: '',
    externalEndDate,
    externalStartDate,
    internalApplicationUrl: '',
    internalEndDate: null,
    internalStartDate: null,
    regionalApplicationUrl: '',
    regionalEndDate: null,
    regionalStartDate: null,
    siteId,
    tags,
  }
}

const onToggle = () => {
  if (typeof publication.value === 'undefined') {
    const newPublication = makeNewPublication({
      siteId: props.site.id,
      externalStartDate: startOfDay(new Date()),
      externalEndDate: addDays(endOfDay(new Date()), 30),
      // initialize new publication tags using site tag groups
      tags: props.site.tags.reduce((tags, tag) => {
        tags[tag.id] = []
        return tags
      }, {}),
    })
    vacancyStore.publications.push(newPublication)
  } else {
    vacancyStore.publications = vacancyStore.publications.filter(
      (p) => p.siteId !== props.site.id,
    )
  }
}

const endNow = () => {
  const publication = vacancyStore.publications.find(
    (p) => p.siteId === props.site.id,
  )
  publication.externalEndDate = new Date()
}
</script>
