export default {
  Drenthe: 'DR',
  Flevoland: 'FL',
  Friesland: 'FR',
  Gelderland: 'GE',
  Groningen: 'GR',
  Limburg: 'LI',
  'Noord-Brabant': 'NB',
  'Noord-Holland': 'NH',
  Overijssel: 'OV',
  Utrecht: 'UT',
  Zeeland: 'ZE',
  'Zuid-Holland': 'ZH',
}
