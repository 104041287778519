<template>
  <div class="grid grid-cols-1 gap-6 lg:grid-cols-6">
    <div class="col-span-3">
      <FormInput
        id="contactName"
        type="text"
        :value="vacancyStore.vacancy.contactPerson.name || ''"
        :label="t('name')"
        :onInput="updateContactName"
        :rules="{}"
      />
    </div>
    <div class="col-span-3">
      <FormInput
        id="contactCompany"
        type="text"
        :value="vacancyStore.vacancy.contactPerson.company || ''"
        :label="t('companyLabel')"
        :onInput="updateContactCompany"
        :rules="{}"
      />
    </div>
    <div class="col-span-3">
      <FormInput
        id="contactEmail"
        type="email"
        :value="vacancyStore.vacancy.contactPerson.email || ''"
        :label="t('vacancy.contact.email')"
        :onInput="updateContactEmail"
        :rules="contactEmailRules"
      />
    </div>
    <div class="col-span-3">
      <FormInput
        id="contactPhone"
        type="tel"
        :value="vacancyStore.vacancy.contactPerson.phone || ''"
        :label="t('vacancy.contact.phone')"
        :onInput="updateContactPhone"
        :rules="{}"
      />
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { helpers, email } from '@vuelidate/validators'
import { useVacancyStore } from '../../store/vacancyStore'
import FormInput from '../FormInput.vue'

const vacancyStore = useVacancyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const updateContactName = (name) => {
  vacancyStore.vacancy.contactPerson.name = name
}
const updateContactCompany = (company) => {
  vacancyStore.vacancy.contactPerson.company = company
}
const updateContactPhone = (phone) => {
  vacancyStore.vacancy.contactPerson.phone = phone
}
const updateContactEmail = (email) => {
  vacancyStore.vacancy.contactPerson.email = email
}

watch(
  () => vacancyStore.company?.name,
  (newName) => {
    if (!vacancyStore.vacancy.contactPerson.company) {
      updateContactCompany(newName)
    }
  },
)

const contactEmailRules = {
  email: helpers.withMessage(t('validations.contactPerson.email'), email),
}
</script>
