<template>
  <div class="grid grid-cols-10 gap-4 bg-white px-4 py-5 sm:p-6">
    <div class="col-span-10 lg:col-span-4">
      <FormInput
        id="street"
        type="text"
        :value="store[storeName].location.street"
        :label="t('vacancy.location.street')"
        :onInput="updateStreet"
        :rules="{}"
      />
    </div>
    <div class="col-span-10 lg:col-span-2">
      <FormInput
        id="houseNumber"
        type="text"
        :value="store[storeName].location.houseNumber"
        :label="t('vacancy.location.houseNumber')"
        :onInput="updateHouseNumber"
        :rules="{}"
      />
    </div>
    <div class="col-span-10 lg:col-span-4">
      <FormInput
        id="city"
        type="text"
        :value="store[storeName].location.city"
        :label="t('vacancy.location.city')"
        :onInput="updateCity"
        :rules="{}"
      />
    </div>
    <div class="col-span-10 lg:col-span-5 xl:col-span-2">
      <FormInput
        id="zipCode"
        type="text"
        :value="store[storeName].location.zipCode"
        :label="t('vacancy.location.postalcode')"
        :onInput="updateZipCode"
        :rules="{}"
      />
    </div>
    <div class="col-span-10 lg:col-span-5 xl:col-span-4">
      <ComboboxInput
        :list="countryList"
        :selectedValue="selectedCountry"
        :label="t('vacancy.location.country')"
        @update-selected="updateCountryCode"
      />
    </div>

    <div class="col-span-10 lg:col-span-5 xl:col-span-2">
      <FormInput
        id="geoLat"
        type="text"
        :value="store[storeName].location.geo.lat"
        label="Lat"
        :onInput="updateLat"
        :rules="latitudeRules"
      />
    </div>
    <div class="col-span-10 lg:col-span-5 xl:col-span-2">
      <FormInput
        id="geoLng"
        type="text"
        :value="store[storeName].location.geo.lng"
        label="Lng"
        :onInput="updateLng"
        :rules="longitudeRules"
      />
    </div>
  </div>
</template>

<script setup>
import FormInput from './FormInput.vue'
import countries from 'i18n-iso-countries'
import { useVacancyStore } from '../store/vacancyStore'
import { useCompanyStore } from '../store/companyStore'
import { useSettingsStore } from '../store/settingsStore'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { helpers, decimal, requiredIf } from '@vuelidate/validators'
import ComboboxInput from './ComboboxInput.vue'

const props = defineProps({
  storeName: {
    type: String,
    required: true,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
let store
if (props.storeName === 'vacancy') {
  store = useVacancyStore()
} else if (props.storeName === 'company') {
  store = useCompanyStore()
}

const countriesObj = countries.getNames(useSettingsStore().locale, {
  select: 'official',
})
const countryList = []
for (const [key, value] of Object.entries(countriesObj)) {
  countryList.push({ code: key, name: value })
}

// We create a new Location obj on every update so the map
// is notified of the change
const updateLocation = (fieldName, newValue) => {
  const newLocation = { ...store[props.storeName].location }
  if (['lat', 'lng'].includes(fieldName)) {
    newLocation.geo[fieldName] = newValue
  } else {
    newLocation[fieldName] = newValue
  }
  store[props.storeName].location = newLocation
}
const updateStreet = (newValue) => {
  updateLocation('street', newValue)
}
const updateHouseNumber = (newValue) => {
  updateLocation('houseNumber', newValue)
}
const updateCity = (newValue) => {
  updateLocation('city', newValue)
}
const updateZipCode = (newValue) => {
  updateLocation('zipCode', newValue)
}
const updateCountryCode = (newValue) => {
  updateLocation('countryCode', newValue.code)
}
const updateLat = (newValue) => {
  updateLocation('lat', newValue.replace(',', '.'))
}
const updateLng = (newValue) => {
  updateLocation('lng', newValue.replace(',', '.'))
}

const selectedCountry = computed(() => {
  return countryList.find(
    (country) => country.code === store[props.storeName].location.countryCode,
  )
})

const latitudeRules = {
  decimal: helpers.withMessage(t('validations.geo.decimal'), decimal),
  required: helpers.withMessage(
    t('validations.geo.required'),
    requiredIf(() => !!store[props.storeName].location?.geo?.lng),
  ),
}
const longitudeRules = {
  decimal: helpers.withMessage(t('validations.geo.decimal'), decimal),
  required: helpers.withMessage(
    t('validations.geo.required'),
    requiredIf(() => !!store[props.storeName].location?.geo?.lat),
  ),
}
</script>
