<template>
  <label
    v-if="label"
    :for="id"
    class="block text-sm font-medium text-gray-700"
    >{{ label }}</label
  >
  <Multiselect
    :id="id"
    :modelValue="modelValue"
    :options="
      async function () {
        return await getAvailableFonts()
      }
    "
    :filterResults="true"
    :minChars="0"
    :delay="0"
    :limit="20"
    searchable
    valueProp="family"
    trackBy="family"
    label="family"
    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    mode="single"
    v-bind="$attrs"
    @change="(e) => $emit('update:modelValue', e)"
  />
</template>

<script setup>
import Multiselect from '@vueform/multiselect'

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:modelValue'])

const GOOGLE_FONTS_API_KEY = 'AIzaSyDLXt5v5VLi_zfUZATZA6UzXznL8XgcfIw'

const availableFonts = []
const fontsOutsideGoogleFonts = [
  { family: 'Proxima Nova' },
  { family: 'Greycliff CF' },
]

const getAvailableFonts = async () => {
  if (availableFonts.length === 0) {
    const response = await fetch(
      `https://www.googleapis.com/webfonts/v1/webfonts?sort=ALPHA&fields=items(family)&key=${GOOGLE_FONTS_API_KEY}`,
    )
    const data = await response.json()
    // We have to manually add the last fonts because they are used in some sites and they're not on Google Fonts
    availableFonts.push(...data.items, ...fontsOutsideGoogleFonts)
  }

  return availableFonts
}
</script>
