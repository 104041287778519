<template>
  <div class="col-span-6">
    <FormInput
      id="clientId"
      type="text"
      :value="config.clientId"
      :label="t('company.importer.solutionsFactory.clientId')"
      :onInput="(value) => (config.clientId = value)"
      :rules="requiredRule"
    />
  </div>
  <div class="col-span-6">
    <FormInput
      id="clientSecret"
      type="password"
      :value="config.clientSecret"
      :label="t('company.importer.solutionsFactory.clientSecret')"
      :onInput="(value) => (config.clientSecret = value)"
      :rules="requiredRule"
    />
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { required, helpers } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'

const props = defineProps({ modelValue: Array })
const emit = defineEmits(['update:modelValue'])

const config = reactive({
  clientId:
    props.modelValue?.find((prop) => prop.name === 'clientId')?.value || '',
  clientSecret:
    props.modelValue?.find((prop) => prop.name === 'clientSecret')?.value || '',
})

watch(config, (value) => {
  emit('update:modelValue', [
    {
      name: 'clientId',
      value: value.clientId,
      secret: false,
    },
    {
      name: 'clientSecret',
      value: value.clientSecret,
      secret: true,
    },
  ])
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const requiredRule = {
  required: helpers.withMessage(t('validations.emptyField'), required),
  $autoDirty: true,
}
</script>
