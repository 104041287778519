<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="mb-4 text-2xl font-semibold text-gray-900">
        {{ t('menu.settings') }}
      </h1>
      <div class="mt-10">
        <SettingsGeneral class="md:grid md:grid-cols-3 md:gap-6" />
      </div>
    </div>
  </div>
</template>

<script setup>
import SettingsGeneral from '../components/SettingsGeneral.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
</script>
