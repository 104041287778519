<template>
  <div class="p-6">
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="portalDomain"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.portalDomain"
          :label="`${t('vacancy.flows.atsFields.portalDomain')}*`"
          :onInput="updatePortalDomain"
          :rules="rules"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="companyDomain"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.companyDomain"
          :label="`${t('vacancy.flows.atsFields.companyDomain')}*`"
          :onInput="updateCompanyDomain"
          :rules="rules"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="clientId"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.clientId"
          :label="`${t('vacancy.flows.atsFields.clientId')}*`"
          :onInput="updateClientId"
          :rules="rules"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="username"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.username"
          :label="`${t('vacancy.flows.atsFields.username')}*`"
          :onInput="updateUsername"
          :rules="rules"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="jobId"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.jobId"
          :label="`${t('vacancy.flows.atsFields.jobId')}*`"
          :onInput="updateJobId"
          :rules="rules"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 mb-3 mt-8 text-base font-medium">
        {{ t('vacancy.flows.atsFields.privateFields') }}
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="clientSecret"
          type="password"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.secretParams.clientSecret"
          :label="`${t('vacancy.flows.atsFields.clientSecret')}*`"
          :onInput="updateClientSecret"
          :rules="rules"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="password"
          type="password"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.secretParams.password"
          :label="`${t('vacancy.flows.atsFields.password')}*`"
          :onInput="updatePassword"
          :rules="rules"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { required, helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { useFlowStore } from '../../store/flowStore'
import FormInput from '../FormInput.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const props = defineProps({
  isPreview: {
    type: Boolean,
    default: false,
  },
})

const rules = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}

const updatePortalDomain = (value) => {
  flowStore.activeAction.config.params.portalDomain = value
}
const updateCompanyDomain = (value) => {
  flowStore.activeAction.config.params.companyDomain = value
}
const updateClientId = (value) => {
  flowStore.activeAction.config.params.clientId = value
}
const updateUsername = (value) => {
  flowStore.activeAction.config.params.username = value
}
const updateJobId = (value) => {
  flowStore.activeAction.config.params.jobId = value
}
const updateClientSecret = (value) => {
  flowStore.activeAction.config.secretParams.clientSecret = value
}
const updatePassword = (value) => {
  flowStore.activeAction.config.secretParams.password = value
}
const flowStore = useFlowStore()
</script>
