import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settingsStore', {
  state: () => ({
    locale: 'en',
    activePublisher: {
      id: null,
      name: '',
    },
    publisherList: [],
    userRoleChanged: false,
    tooltipKeyboardNavigation: false,
    preferredLocationSearch: 'search',
  }),
  persist: true,
})
