<template>
  <div class="bg-white px-4 py-5 sm:p-6">
    <div class="grid grid-cols-12 items-end gap-6">
      <div class="col-span-12 lg:col-span-6 xl:col-span-4">
        <ComboboxInput
          :label="t('vacancy.location.country')"
          :list="countryList"
          :selectedValue="countryList[0]"
        />
      </div>
      <div class="col-span-12 lg:col-span-6 xl:col-span-3">
        <FormInput
          id="zipCode"
          type="text"
          :value="store[storeName].location.zipCode"
          :label="t('vacancy.location.postalcode')"
          :onInput="updateZipCode"
          :rules="{}"
        />
      </div>
      <div class="col-span-12 lg:col-span-6 xl:col-span-3">
        <FormInput
          id="houseNumber"
          type="text"
          :value="store[storeName].location.houseNumber"
          :label="t('vacancy.location.houseNumber')"
          :onInput="updateHouseNumber"
          :rules="{}"
        />
      </div>
      <div class="col-span-12 lg:col-span-6 xl:col-span-1">
        <button
          type="button"
          class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2.5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click="searchLocationByZip"
        >
          <span>{{ t('vacancy.location.search') }}</span>
          <LoadingSpinner v-if="isSearching" class="ml-3 h-4 w-4 text-white" />
        </button>
      </div>
    </div>
    <ErrorMessage v-if="errorMsg" :error="errorMsg" class="mb-0 mt-4" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import FormInput from './FormInput.vue'
import ComboboxInput from './ComboboxInput.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import ErrorMessage from './ErrorMessage.vue'
import { useCompanyStore } from '../store/companyStore'
import { useVacancyStore } from '../store/vacancyStore'
import { useSettingsStore } from '../store/settingsStore'
import countries from 'i18n-iso-countries'

const props = defineProps({
  storeName: {
    type: String,
    required: true,
  },
})

let store
if (props.storeName === 'vacancy') {
  store = useVacancyStore()
} else if (props.storeName === 'company') {
  store = useCompanyStore()
}
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const countryList = computed(() => {
  const netherlands = countries.getName('NL', useSettingsStore().locale, {
    select: 'official',
  })
  return [{ code: 'NL', name: netherlands }]
})

const updateHouseNumber = (newValue) => {
  store[props.storeName].location.houseNumber = newValue
}
const updateZipCode = (newValue) => {
  store[props.storeName].location.zipCode = newValue
}
const errorMsg = ref('')
const isSearching = ref(false)

// We create a new Location obj on every update so the map
// is notified of the change
const updateLocation = (data) => {
  store[props.storeName].location = {
    zipCode: data.postcode,
    city: data.city,
    street: data.street,
    houseNumber: data.number,
    countryCode: 'NL',
    geo: {
      lat: data.geo.lat,
      lng: data.geo.lon,
    },
  }
}
const searchLocationByZip = async () => {
  const postCodeApi = 'https://postcode.tech/api/v1/postcode/full'
  const apiKey = '1100c048-f279-4fcb-94ea-068bec1cbff6'
  isSearching.value = true
  errorMsg.value = ''
  const response = await fetch(
    `${postCodeApi}?postcode=${
      store[props.storeName].location.zipCode
    }&number=${store[props.storeName].location.houseNumber}`,
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    },
  )
  const data = await response.json()
  isSearching.value = false
  if (response.status === 200) {
    updateLocation(data)
  } else {
    errorMsg.value = data.message
  }
}
</script>
