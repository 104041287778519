import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'
import { postImage } from '../api/common'
import router from '../router'

const emptyCompany = {
  id: null,
  location: {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    countryCode: '',
    geo: {
      lng: null,
      lat: null,
    },
  },
  boxPhotoUrl: null,
  coverPhotoUrl: null,
  carouselImagesUrls: [],
  description: '',
  logoUrl: null,
  name: '',
  phone: '',
  videoUrls: [],
  shortDescription: '',
  showOnHomepage: false,
  socialLinks: [],
  slug: '',
  websiteUrl: null,
}

export const useCompanyStore = defineStore('companyStore', {
  state: () => ({
    isLoading: false,
    isSaving: false,
    errors: [],
    isSavedSuccessfully: false,
    company: { ...emptyCompany }, // Simplified initialization
    sites: [],
    publishers: [],
    boxPhotoImage: null,
    coverPhotoImage: null,
    logoImage: null,
  }),
  actions: {
    async load(companyId) {
      this.isLoading = true
      this.errors = []
      this.isSavedSuccessfully = false
      try {
        if (companyId === 'new') {
          this.company = { ...emptyCompany, id: 'new' }
        } else {
          const [companyRes, sitesRes, publishersRes] = await Promise.all([
            tooManagement.getCompany(companyId, true),
            tooManagement.getSitesForCompany(companyId, true),
            tooManagement.getPublishersForCompany(companyId),
          ])
          this.company = companyRes.data
          this.sites = sitesRes.data
          this.publishers = publishersRes.data
        }
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
    async save() {
      this.isSaving = true
      this.errors = []
      this.isSavedSuccessfully = false
      try {
        await this.uploadImages() // Upload images concurrently
        const company = { ...this.company }
        if (
          company.location &&
          (!company.location.geo?.lat || !company.location.geo?.lng)
        ) {
          delete company.location.geo
        }
        let newRoute = ''
        if (company.id === 'new') {
          delete company.id // Delete the id for new company
          const savedCompany = await tooManagement.postCompany(company)
          this.company = savedCompany.data
          newRoute = `/companies/${savedCompany.data.id}/company`
        } else {
          const savedCompany = await tooManagement.putCompany(company)
          this.company = savedCompany.data
        }
        await Promise.all([
          tooManagement.putSitesForCompany(this.company.id, this.sites),
          tooManagement.putPublishersForCompany(
            this.company.id,
            this.publishers,
          ),
        ])
        if (newRoute) {
          router.replace(newRoute)
        }
        this.isSavedSuccessfully = true
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    async uploadImages() {
      const uploadPromises = []
      if (this.boxPhotoImage) {
        uploadPromises.push(this.saveImage('boxPhotoUrl', this.boxPhotoImage))
      }
      if (this.coverPhotoImage) {
        uploadPromises.push(
          this.saveImage('coverPhotoUrl', this.coverPhotoImage),
        )
      }
      if (this.logoImage) {
        uploadPromises.push(this.saveImage('logoUrl', this.logoImage))
      }
      if (this.company.carouselImagesUrls) {
        this.company.carouselImagesUrls.forEach((image, index) => {
          if (typeof image !== 'string') {
            uploadPromises.push(
              this.saveImage('carouselImagesUrls', image, index),
            )
          }
        })
      }
      await Promise.all(uploadPromises)
    },
    async saveImage(field, imageFile, index) {
      try {
        const imageUrl = await postImage('management/v1/images', imageFile)
        if (index !== undefined) {
          this.company[field][index] = imageUrl.data.url
        } else {
          this.company[field] = imageUrl.data.url
        }
      } catch (error) {
        this.errors.push(error)
      }
    },
    updateSocialNetwork(type, url) {
      const network = this.company.socialLinks.find(
        (social) => social.type === type,
      )
      if (!network && url) {
        this.company.socialLinks.push({ type, url })
      } else if (network && url) {
        network.url = url
      } else if (network && !url) {
        this.company.socialLinks = this.company.socialLinks.filter(
          (network) => network.type !== type,
        )
      }
    },
    handleError(error) {
      this.errors.push(error)
      this.isSaving = false
    },
  },
})
