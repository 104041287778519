import { get, post, put, patch, del, getCustomHeaders } from './common'

const CANDIDATE_GATEWAY_KEY = 'candidateGateway'

const getCandidates = async (parameters) =>
  get(`candidate-store/v1/candidates`, parameters, {}, CANDIDATE_GATEWAY_KEY)

const getCandidate = async (candidateId) => {
  return get(
    `candidate-store/v1/candidates/${candidateId}`,
    {},
    {},
    CANDIDATE_GATEWAY_KEY,
  )
}

const toggleTestCandidate = async (candidateId, value) => {
  return put(
    `candidate-gateway/management/v1/candidates/${candidateId}/test`,
    { value: value },
    {},
    CANDIDATE_GATEWAY_KEY,
  )
}

const updateCandidateStatus = async (candidates) => {
  return patch(
    'candidate-gateway/management/v1/candidates',
    candidates,
    {},
    CANDIDATE_GATEWAY_KEY,
  )
}

const toggleDuplicateCandidate = async (candidateId, value) => {
  return put(
    `candidate-gateway/management/v1/candidates/${candidateId}/duplicate`,
    { value: value },
    {},
    CANDIDATE_GATEWAY_KEY,
  )
}

const replayCandidates = async (candidateIds) => {
  return post(
    'candidate-gateway/management/v1/replays',
    { candidateIds },
    {},
    CANDIDATE_GATEWAY_KEY,
  )
}

const getCandidatesStatistics = async (parameters) =>
  get('candidate-gateway/statistics/v1', parameters, {}, CANDIDATE_GATEWAY_KEY)

const getCandidateFields = async (parameters) =>
  get(
    `candidate-gateway/management/v1/available-fields`,
    parameters,
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const getFlows = async (parameters) =>
  get(
    `candidate-gateway/management/v1/flows`,
    parameters,
    getCustomHeaders(),
    CANDIDATE_GATEWAY_KEY,
  )

const getFlow = async (flowId, parameters) =>
  get(
    `candidate-gateway/management/v1/flows/${flowId}`,
    parameters,
    getCustomHeaders(),
    CANDIDATE_GATEWAY_KEY,
  )

const postFlow = async (flow, parameters) => {
  const params = new URLSearchParams(parameters)
  return post(
    `candidate-gateway/management/v1/flows?${params.toString()}`,
    flow,
    parameters,
    CANDIDATE_GATEWAY_KEY,
  )
}

const putFlow = async (flow, parameters) => {
  const params = new URLSearchParams(parameters)
  return put(
    `candidate-gateway/management/v1/flows/${flow.id}?${params.toString()}`,
    flow,
    parameters,
    CANDIDATE_GATEWAY_KEY,
  )
}

const getFlowActions = async (flowId) =>
  get(
    `candidate-gateway/management/v1/flows/${flowId}/actions`,
    {},
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const postFlowAction = async (flowId, action) =>
  post(
    `candidate-gateway/management/v1/flows/${flowId}/actions`,
    action,
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const putVacancyFlows = async (vacancyId, flows) =>
  put(
    `candidate-gateway/management/v1/vacancies/${vacancyId}/flows`,
    flows,
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const deleteVacancyFlow = async (vacancyId, flowTypeSlugs) =>
  del(
    `candidate-gateway/management/v1/vacancies/${vacancyId}/flows?flowTypeSlugs=${flowTypeSlugs}`,
    null,
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const putFlowAction = async (action) =>
  put(
    `candidate-gateway/management/v1/flows/${action.flowId}/actions/${action.id}`,
    action,
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const deleteFlowAction = async (flowId, actionId) =>
  del(
    `candidate-gateway/management/v1/flows/${flowId}/actions/${actionId}`,
    null,
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const getCompaniesWithDefaultFlow = async (flowId) =>
  get(
    `candidate-gateway/management/v1/flows/${flowId}/companies`,
    {},
    {},
    CANDIDATE_GATEWAY_KEY,
  )

const putDefaultFlowForCompanies = async (flowId, companies) => {
  const companyIds = companies.map((company) => ({
    value: company.id,
  }))
  return put(
    `candidate-gateway/management/v1/flows/${flowId}/companies`,
    companyIds,
    {},
    CANDIDATE_GATEWAY_KEY,
  )
}

export default {
  getCandidates,
  getCandidate,
  getFlow,
  getFlows,
  postFlow,
  putFlow,
  postFlowAction,
  putFlowAction,
  putVacancyFlows,
  deleteVacancyFlow,
  deleteFlowAction,
  getFlowActions,
  getCandidateFields,
  getCandidatesStatistics,
  toggleTestCandidate,
  toggleDuplicateCandidate,
  replayCandidates,
  getCompaniesWithDefaultFlow,
  putDefaultFlowForCompanies,
  updateCandidateStatus,
}
