<template>
  <div v-if="sortOptions.length" class="flex justify-end">
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-gray-800"
        >
          <BarsArrowDownIcon class="h-5 w-5 text-gray-600" aria-hidden="true" />
        </MenuButton>
      </div>
      <transition
        enterActiveClass="transition ease-out duration-100"
        enterFromClass="transform opacity-0 scale-95"
        enterToClass="transform opacity-100 scale-100"
        leaveActiveClass="transition ease-in duration-75"
        leaveFromClass="transform opacity-100 scale-100"
        leaveToClass="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 z-10 mt-2 w-56 origin-top-right cursor-pointer rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <div v-for="(sortOption, index) in sortOptions" :key="index">
              <MenuItem
                v-slot="{ active }"
                @click="sort(sortOption.value, 'desc')"
              >
                <span
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center px-4 py-2 text-sm',
                  ]"
                  >{{ sortOption.name }}<ArrowSmallDownIcon class="h-4 w-4"
                /></span>
              </MenuItem>
              <MenuItem
                v-slot="{ active }"
                @click="sort(sortOption.value, 'asc')"
              >
                <span
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center px-4 py-2 text-sm',
                  ]"
                  >{{ sortOption.name }}<ArrowSmallUpIcon class="h-4 w-4"
                /></span>
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  BarsArrowDownIcon,
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from '@heroicons/vue/20/solid'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  sortOptions: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['sort'])

const sort = (sortValue, order) => {
  emit('sort', { value: sortValue, order: order })
}
</script>
