import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'

export const useSalaryScaleGroupListStore = defineStore(
  'salaryScaleGroupListStore',
  {
    state: () => ({
      isLoading: false,
      errors: [],
      isSaving: false,
      isSavedSuccessfully: false,
      salaryScaleGroups: [],
    }),
    actions: {
      async load() {
        this.errors = []
        this.isLoading = true

        try {
          const salaryScaleGroups = await tooManagement.getSalaryScaleGroups()
          this.salaryScaleGroups = salaryScaleGroups.data
        } catch (error) {
          this.salaryScaleGroups = []
          this.errors.push(error)
        } finally {
          this.isLoading = false
        }
      },
    },
  }
)
