<template>
  <FormInput
    :id="`video${index}`"
    type="url"
    :value="video"
    :onInput="updateVideoLink"
    :rules="websiteUrlRules"
  />
</template>

<script setup>
import FormInput from './FormInput.vue'
import { helpers, url, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  video: {
    type: String,
    required: true,
  },
})
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const emit = defineEmits(['videoLinkUpdate'])

const updateVideoLink = (value) => {
  emit('videoLinkUpdate', { value, index: props.index })
}

const websiteUrlRules = {
  url: helpers.withMessage(t('validations.invalidUrl'), url),
  required: helpers.withMessage(t('validations.emptyField'), required),
  $autoDirty: true,
}
</script>
