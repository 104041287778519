import { get, post, put, del, getCustomHeaders } from './common'

const getCompanies = async (searchParams) =>
  get('publishers/v1/companies', searchParams, getCustomHeaders())

const getCompany = async (companyId) =>
  get(`publishers/v1/companies/${companyId}`, {}, getCustomHeaders())

const getVacancies = async (searchParams) =>
  get('publishers/v1/vacancies', searchParams, getCustomHeaders())

const getVacancy = async (vacancyId) =>
  get(`publishers/v1/vacancies/${vacancyId}`, {}, getCustomHeaders())

const getPublications = async (vacancyId) =>
  get(
    `publishers/v1/vacancies/${vacancyId}/publications`,
    {},
    getCustomHeaders(),
  )

const postPublication = async (publication, vacancyId) =>
  post(
    `publishers/v1/vacancies/${vacancyId}/publications`,
    publication,
    getCustomHeaders(),
  )

const putPublication = async (publication) =>
  put(
    `publishers/v1/vacancies/${publication.vacancyId}/publications/${publication.id}`,
    publication,
    getCustomHeaders(),
  )

const postVacancy = async (vacancy) =>
  post('publishers/v1/vacancies', vacancy, getCustomHeaders())

const putVacancy = async (vacancy) =>
  put(`publishers/v1/vacancies/${vacancy.id}`, vacancy, getCustomHeaders())

const getPromotions = async (vacancyId) =>
  get(`publishers/v1/vacancies/${vacancyId}/promotions`, {}, getCustomHeaders())

const putSocialCampaignAds = async (vacancyId) =>
  put(
    `publishers/v1/vacancies/${vacancyId}/promotions/social-recruitment-ads`,
    {},
    getCustomHeaders(),
  )

const putJobmarketingAdvice = async (vacancyId) =>
  put(
    `publishers/v1/vacancies/${vacancyId}/promotions/recruitment-marketing-advice`,
    {},
    getCustomHeaders(),
  )

const deleteSocialCampaignAds = async (vacancyId) =>
  del(
    `publishers/v1/vacancies/${vacancyId}/promotions/social-recruitment-ads`,
    null,
    getCustomHeaders(),
  )

const deleteJobmarketingAdvice = async (vacancyId) =>
  del(
    `publishers/v1/vacancies/${vacancyId}/promotions/recruitment-marketing-advice`,
    null,
    getCustomHeaders(),
  )

const getSitesForCompany = async (companyId) =>
  get(`publishers/v1/companies/${companyId}/sites`, {}, getCustomHeaders())

const getSalaryScales = async () =>
  get('publishers/v1/salary-scales', {}, getCustomHeaders())

const getTagsForSite = async (siteId) =>
  get(`publishers/v1/sites/${siteId}/tags`, {}, getCustomHeaders())

const getUserInfo = async () => get('publishers/v1/me', {}, getCustomHeaders())

const linkUserAccount = async () =>
  post('publishers/v1/me/link/account', {}, getCustomHeaders())

export default {
  postVacancy,
  putVacancy,
  postPublication,
  putPublication,
  getCompanies,
  getCompany,
  getSitesForCompany,
  getVacancies,
  getVacancy,
  getPublications,
  getSalaryScales,
  getTagsForSite,
  getUserInfo,
  linkUserAccount,
  getPromotions,
  putJobmarketingAdvice,
  putSocialCampaignAds,
  deleteJobmarketingAdvice,
  deleteSocialCampaignAds,
}
