import { Auth0Client } from '@auth0/auth0-spa-js'

export default new Auth0Client({
  domain: import.meta.env.VITE_AUTH_DOMAIN,
  clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
  useFormData: false,
  useRefreshTokens: true,
  // cacheLocation: 'localstorage',
  // useRefreshTokensFallback: true,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH_AUDIENCE,
    redirect_uri: `${window.location.origin}/auth/callback`,
  },
})
