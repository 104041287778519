<template>
  <div v-if="flow" class="flow-actions flex flex-wrap items-center px-6 py-6">
    <InputActionButton />
    <FlowAction
      v-for="(action, actionIndex) in flow.actions"
      :key="actionIndex"
      :action="action"
      :flow="flow"
      :actionIndex="actionIndex"
      :isPreview="true"
    />
  </div>
  <EmailActionContent v-if="isActiveAction('send-email')" :isPreview="true" />
  <AfasAtsAction
    v-if="isActiveAction('submit-candidate-to-ats-afas')"
    :isPreview="true"
  />
  <ConnexysAtsAction
    v-if="isActiveAction('submit-candidate-to-ats-connexys')"
    :isPreview="true"
  />
  <MysolutionAtsAction
    v-if="isActiveAction('submit-candidate-to-ats-mysolution')"
    :isPreview="true"
  />
  <RecruiteeAtsAction
    v-if="isActiveAction('submit-candidate-to-ats-recruitee')"
    :isPreview="true"
  />
</template>

<script setup>
import { computed, onUpdated } from 'vue'
import { useFlowStore } from '../../store/flowStore'
import FlowAction from './FlowAction.vue'
import InputActionButton from './InputActionButton.vue'
import AfasAtsAction from './AfasAtsAction.vue'
import ConnexysAtsAction from './ConnexysAtsAction.vue'
import MysolutionAtsAction from './MysolutionAtsAction.vue'
import RecruiteeAtsAction from './RecruiteeAtsAction.vue'
import EmailActionContent from './EmailActionContent.vue'

const flowStore = useFlowStore()
const props = defineProps({
  flowId: {
    type: Number,
    required: true,
  },
})

onUpdated(() => {
  flowStore.loadFlowPreviews(props.flowId)
})

flowStore.loadFlowPreviews(props.flowId)
const flow = computed(() => {
  return flowStore.flowPreviews.find((flow) => flow.id === props.flowId) || null
})

const isActiveAction = (slug) => {
  return (
    flowStore.activeAction.flowActionTypeSlug === slug &&
    flowStore.activeAction.flowId === props.flowId
  )
}
</script>
