<template>
  <div v-if="authStore.isAdmin">
    <CandidatesStatistics />
    <CandidatesFilter />

    <List
      v-slot="{ entity: candidate }"
      :entities="candidateListStore.candidates"
      :page="candidateListStore.page"
      :updatePageNumber="updatePageNumber"
      :updatePageSize="updatePageSize"
      :hasMultiselect="true"
      :multiselectActions="multiselectActions"
      @selected-action="onSelectedAction"
    >
      <router-link
        :to="{
          name: 'candidateDetail',
          params: { id: route.params.id, candidateId: candidate.id },
          query: { ...route.query },
        }"
        class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
      >
        <div class="px-4 py-4 sm:px-6">
          <div class="flex">
            <div class="flex grow justify-between sm:w-1/2">
              <div class="flex">
                <p
                  v-if="!candidate.anonymizedDate"
                  :class="{
                    'blur-sm': candidateName(candidate).startsWith('*'),
                  }"
                  class="inline-flex flex-col-reverse gap-1 truncate text-sm font-medium text-indigo-600 lg:flex-row lg:items-center"
                >
                  {{ candidateName(candidate) }}
                </p>
                <span
                  v-else
                  class="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800"
                >
                  {{ t('candidates.anonymized') }}
                </span>
                <span
                  v-if="candidate.isTest"
                  class="inline-block w-fit rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 lg:ml-2"
                  >{{ t('candidates.status.test') }}</span
                >
                <span
                  v-if="candidate.isDuplicate"
                  class="inline-block w-fit rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800 lg:ml-2"
                  >{{ t('candidates.status.duplicate') }}</span
                >
              </div>

              <span
                class="mr-1 hidden items-center rounded-full bg-sky-50 px-2.5 py-0.5 text-xs font-medium text-sky-800 sm:inline-flex"
                >{{ t(`candidates.flowType.${candidate.flowTypeSlug}`) }}</span
              >
            </div>

            <div class="flex justify-between sm:w-1/2">
              <div
                class="hidden sm:flex"
                :class="{
                  'animate-pulse':
                    candidate?.latestFlowExecution?.state === 'Pending',
                }"
              >
                <component
                  :is="statusToDisplay(candidate).icon"
                  v-if="candidate.latestFlowExecution"
                  class="w-6 fill-white"
                  :class="statusToDisplay(candidate).color"
                />
              </div>

              <div class="ml-2 flex shrink-0 gap-2">
                <span
                  class="inline-flex h-fit rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800"
                >
                  {{ timeAgo.format(new Date(candidate.applicationDate)) }}
                </span>
              </div>
            </div>
          </div>

          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <p
                v-if="!candidate.anonymizedDate"
                class="flex items-center text-xs text-gray-500"
              >
                {{ candidate.email ? candidate.email : candidate.phoneNumber }}
              </p>
              <p v-else class="flex items-center text-xs text-gray-500">
                {{ toLocalDateTime(new Date(candidate.anonymizedDate)) }}
              </p>
            </div>
            <div
              class="mt-2 flex items-center justify-between text-xs text-gray-500 sm:mt-0"
            >
              <p>{{ toLocalDateTime(new Date(candidate.applicationDate)) }}</p>

              <div class="flex sm:hidden">
                <span
                  class="mr-1 inline-flex items-center rounded-full bg-sky-50 px-2.5 py-0.5 text-xs font-medium text-sky-800"
                  >{{
                    t(`candidates.flowType.${candidate.flowTypeSlug}`)
                  }}</span
                >
                <component
                  :is="statusToDisplay(candidate).icon"
                  v-if="candidate.latestFlowExecution"
                  class="w-6 fill-white"
                  :class="statusToDisplay(candidate).color"
                />
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </List>
  </div>
</template>

<script setup>
import { useCandidateListStore } from '../../../store/candidateListStore'
import { useAuthStore } from '../../../store/authStore'
import { useRoute } from 'vue-router'
import { onUnmounted } from 'vue'
import { possibleStates } from '../../../utils/flowActionStates.js'
import { useI18n } from 'vue-i18n'
import TimeAgo from 'javascript-time-ago'
import List from '../../List.vue'
import CandidatesStatistics from './CandidatesStatistics.vue'
import CandidatesFilter from './CandidatesFilter.vue'
import { PlayIcon } from '@heroicons/vue/24/outline'
import { format } from 'date-fns'
import { campaignManagerActions } from '../../../utils/campaignManagerActions'

const candidateListStore = useCandidateListStore()
const authStore = useAuthStore()
const route = useRoute()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
onUnmounted(() => {
  candidateListStore.$reset()
})

const timeAgo = new TimeAgo()

const updatePageNumber = (number) => {
  candidateListStore.page.number = number
  candidateListStore.loadCandidates(
    route.params.id,
    candidateListStore.flowTypeFilter,
    candidateListStore.executionStateFilter.label,
    candidateListStore.dateRangeFilter,
  )
}

const toLocalDateTime = (applicationDate) => {
  return format(applicationDate, 'dd MMM yyyy, HH:mm')
}

const candidateName = (candidate) => {
  if (candidate.fullName) {
    return candidate.fullName
  } else if (candidate?.firstName || candidate?.lastName) {
    return `${candidate?.firstName || ''} ${candidate?.lastName || ''}`
  } else {
    return 'Anonymous candidate'
  }
}
const updatePageSize = (pageSize) => {
  candidateListStore.page.size = pageSize
  candidateListStore.loadCandidates(
    route.params.id,
    candidateListStore.flowTypeFilter,
    candidateListStore.executionStateFilter.label,
    candidateListStore.dateRangeFilter,
  )
}

const adminActions = [
  {
    label: t('candidates.actions.replay'),
    icon: PlayIcon,
    value: 'replayCandidates',
  },
]

const multiselectActions = authStore.isCampaignManager
  ? [...adminActions, ...campaignManagerActions]
  : adminActions

const onSelectedAction = ({ actionName, selected }) => {
  if (actionName === 'replayCandidates') {
    candidateListStore.replayCandidates(route.params.id, selected.value)
  }
  if (actionName === 'markAsTest') {
    candidateListStore.updateCandidateStatus(selected.value, 'isTest', true)
  }
  if (actionName === 'markAsDuplicate') {
    candidateListStore.updateCandidateStatus(
      selected.value,
      'isDuplicate',
      true,
    )
  }
  if (actionName === 'removeTest') {
    candidateListStore.updateCandidateStatus(selected.value, 'isTest', false)
  }
  if (actionName === 'removeDuplicate') {
    candidateListStore.updateCandidateStatus(
      selected.value,
      'isDuplicate',
      false,
    )
  }
}

const statusToDisplay = (candidate) => {
  return possibleStates.find(
    (state) => state.label === candidate.latestFlowExecution.state,
  )
}
</script>
