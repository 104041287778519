import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/vue/24/outline'

export const possibleStates = [
  {
    code: 'SUCCESSFUL',
    icon: CheckCircleIcon,
    color: 'text-green-500',
  },
  {
    code: 'FAILED',
    icon: ExclamationCircleIcon,
    color: 'text-red-500',
  },
  {
    code: 'RUNNING',
    icon: EllipsisHorizontalCircleIcon,
    color: 'text-blue-500',
  },
]
