<template>
  <div class="my-5 bg-white shadow sm:rounded-md">
    <div class="px-5 py-5">
      <div class="text-lg font-medium leading-6 text-gray-900">
        {{ t('socialCampaigns.jobmarketingTitle') }}
      </div>
      <div class="mt-1 text-sm font-normal text-gray-900">
        {{ t('socialCampaigns.jobmarketingDescription') }}
      </div>
      <div class="mt-5 flex items-center justify-between rounded p-5">
        <div class="flex items-center">
          <img
            class="w-24"
            src="/free_advice.png"
            alt="Logo Jobsrepublic Publisher"
          />
          <span class="-ml-5 mt-8 text-xl uppercase text-gray-600">{{
            t('socialCampaigns.freeAdvice')
          }}</span>
        </div>

        <Toggle
          srLabel="Job marketing"
          :checked="vacancyStore.hasRecruitmentMarketingAdvice"
          :onToggle="onToggle"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Toggle from '../Toggle.vue'
import { useI18n } from 'vue-i18n'
import { useVacancyStore } from '../../store/vacancyStore'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const vacancyStore = useVacancyStore()

const onToggle = () => {
  vacancyStore.hasRecruitmentMarketingAdvice =
    !vacancyStore.hasRecruitmentMarketingAdvice
}
</script>
