<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.jobAlerts')"
        :isLoading="jobAlertListStore.isLoading"
        :enableSearch="true"
        :searchPlaceholder="t('jobAlerts.search')"
        :searchTerm="jobAlertListStore.filter"
        @update:search-term="updateFilter"
      >
      </ListHeader>

      <ErrorMessage
        v-if="jobAlertListStore.error"
        :error="jobAlertListStore.error"
      />

      <List
        v-slot="{ entity: jobAlert }"
        :entities="jobAlertListStore.jobAlerts"
        :page="jobAlertListStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
        :hasMultiselect="true"
        :multiselectActions="multiselectActions"
        :processedSuccessfully="jobAlertListStore.isDeletedSuccessfully"
        :isLoading="jobAlertListStore.isLoading"
        @selected-action="onSelectedAction"
      >
        <router-link
          :to="`/job-alerts/${jobAlert.id}`"
          class="block text-gray-900 hover:bg-gray-50 focus:underline focus:outline-none"
        >
          <div class="px-4 py-4 sm:px-6">
            <div
              class="flex flex-col items-start justify-between lg:flex-row lg:items-center"
            >
              <div class="flex-1 truncate text-sm font-medium text-indigo-600">
                {{ jobAlert.email }}
              </div>
              <div class="flex-1 truncate text-center text-sm">
                {{ jobAlert.site.name }}
              </div>
              <div class="hidden flex-1 text-center text-sm md:block">
                {{ t(`jobAlerts.frequency.${jobAlert.frequency}`) }}
              </div>
              <div
                class="ml-auto flex flex-1 items-center justify-end gap-x-2 text-xs"
              >
                <span
                  v-show="jobAlert.active"
                  class="hidden items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:inline-flex"
                >
                  {{ t('active') }}
                </span>
                <span class="flex gap-x-1"
                  ><CalendarIcon class="h-4 w-4" />{{
                    format(jobAlert.createdDate, 'dd/MM/yyyy')
                  }}</span
                >
              </div>
            </div>
          </div>
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import { CalendarIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { useJobAlertListStore } from '../store/jobAlertListStore'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const jobAlertListStore = useJobAlertListStore()
jobAlertListStore.load()

const updateFilter = (filter) => {
  jobAlertListStore.filter = filter
  jobAlertListStore.page.number = 1
  jobAlertListStore.load()
}
const updatePageNumber = (number) => {
  jobAlertListStore.page.number = number
  jobAlertListStore.load()
}
const updatePageSize = (pageSize) => {
  jobAlertListStore.page.size = pageSize
  jobAlertListStore.load()
}

const multiselectActions = [
  {
    label: t('jobAlerts.removeAlert'),
    icon: TrashIcon,
    value: 'removeJobAlert',
  },
]

const onSelectedAction = ({ actionName, selected }) => {
  if (actionName === 'removeJobAlert') {
    jobAlertListStore.removeJobAlerts(selected.value)
  }
}
</script>
