<template>
  <div class="flex flex-col gap-y-6">
    <div
      v-for="page in vacancyStore.storyblokPages"
      :key="page.id"
      class="overflow-hidden bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6"
    >
      <div class="flex flex-col justify-between gap-4 lg:flex-row">
        <!-- Storyblok Logo -->
        <svg
          class="mt-0.5 h-6 w-6 self-start"
          viewBox="0 0 87 103"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-label="Storyblok logo"
        >
          <g id="storyblok-logo" fill-rule="nonzero">
            <g fill="#09B3AF">
              <path
                d="M51.8,49 L31,49 L31,59 L51.3,59 C52.5,59 53.6,58.5 54.5,57.7 C55.3,56.9 55.8,55.7 55.8,54.2 C55.8290035,52.9130708 55.4433501,51.6509323 54.7,50.6 C53.9,49.6 53,49 51.8,49 Z M52.5,36.9 C53.4,36.3 53.8,35 53.8,33.3 C53.8,31.8 53.4,30.7 52.7,30 C52,29.4 51.1,29 50.1,29 L31,29 L31,38 L49.7,38 C50.7,38 51.7,37.5 52.5,36.9 Z"
              />
              <path
                d="M83,0 L4.5,0 C2,0 0,2 0,4.4 L0,83 C0,85.4 2,86.9 4.4,86.9 L16,86.9 L16,102.6 L30.4,87 L83,87 C85.4,87 86.9,85.5 86.9,83 L86.9,4.5 C86.9,2.1 85.4,0.1 82.9,0.1 L83,0 Z M69.8,63.7 C68.8,65.5 67.3,67 65.5,68.1 C63.6,69.3 61.5,70.4 59.1,70.9 C56.7,71.5 54.1,72 51.4,72 L16,72 L16,16 L56.2,16 C58.2,16 59.9,16.4 61.5,17.3 C63,18.1 64.4,19.2 65.5,20.5 C67.7403434,23.2320077 68.9444137,26.6671496 68.9,30.2 C68.9,32.8 68.2,35.3 66.9,37.7 C65.5522265,40.1140117 63.4421536,42.0130773 60.9,43.1 C64.1,44 66.6,45.6 68.5,47.9 C70.3,50.3 71.2,53.4 71.2,57.3 C71.2,59.8 70.7,61.9 69.7,63.7 L69.8,63.7 Z"
              />
            </g>
          </g>
        </svg>

        <div class="grow">
          <h3
            class="mb-1 items-center text-lg font-medium leading-6 text-gray-900 lg:flex"
          >
            {{ page.name }}
            <span
              v-if="page.published"
              class="mx-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
            >
              {{ t('campaignPages.published') }}
            </span>

            <span
              v-if="page.published"
              class="inline-flex rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
            >
              {{ timeAgo.format(new Date(page.published_at)) }}
            </span>

            <span
              v-else
              class="mx-2 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800"
            >
              {{ t('campaignPages.draft') }}
            </span>
          </h3>

          <p class="text-sm text-gray-500">{{ page.full_slug }}</p>
        </div>

        <a
          class="inline-flex min-w-fit items-center self-end rounded-md bg-[#09b3af] px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-[#09b3af] focus:ring-offset-2"
          :href="generateLink(page)"
          target="_blank"
        >
          {{ t('campaignPages.editStoryblok') }}

          <ArrowTopRightOnSquareIcon class="ml-2 h-5 w-5" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useVacancyStore } from '../../store/vacancyStore'
import { useI18n } from 'vue-i18n'
import TimeAgo from 'javascript-time-ago'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const vacancyStore = useVacancyStore()
const timeAgo = new TimeAgo()

const generateLink = (page) => {
  return `https://app.storyblok.com/#!/me/spaces/${
    import.meta.env.VITE_STORYBLOK_SPACE
  }/stories/0/0/${page.id}`
}
</script>
