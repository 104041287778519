<template>
  <div class="flex items-center justify-between border-t border-gray-200 py-3">
    <div class="flex flex-1 justify-between sm:hidden">
      <button
        :disabled="!hasPrevious"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        @click="goPrevious"
      >
        {{ t('pagination.previous') }}
      </button>
      <button
        :disabled="!hasNext"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        @click="goNext"
      >
        {{ t('pagination.next') }}
      </button>
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          <span v-if="page.totalElements > 0">
            {{ ' ' }}
            <span class="font-medium">{{ showStart }}</span>
            {{ ' ' }}
            {{ t('pagination.to') }}
            {{ ' ' }}
            <span class="font-medium">{{ showEnd }}</span>
            {{ ' ' }}
            {{ t('pagination.of') }}
            {{ ' ' }}
          </span>
          <span class="font-medium">{{ page.totalElements }}</span>
          {{ ' ' }}
          {{ t('pagination.results') }}
        </p>
      </div>
      <div class="flex items-center">
        <span class="mr-4 hidden gap-x-3 text-sm text-gray-700 lg:flex"
          >{{ t('itemsPerPage') }}:
          <span
            :class="{ 'font-bold underline': page.size === 10 }"
            class="cursor-pointer hover:underline"
            @click="updatePageSize(10)"
            >10</span
          >
          <span
            :class="{ 'font-bold underline': page.size === 20 }"
            class="cursor-pointer hover:underline"
            @click="updatePageSize(20)"
            >20</span
          >
          <span
            :class="{ 'font-bold underline': page.size === 50 }"
            class="cursor-pointer hover:underline"
            @click="updatePageSize(50)"
            >50</span
          >
          <span
            :class="{ 'font-bold underline': page.size === 100 }"
            class="cursor-pointer hover:underline"
            @click="updatePageSize(100)"
            >100</span
          >
        </span>
        <nav
          class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            :disabled="!hasPrevious"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            @click="updatePageNumber(1)"
          >
            <span class="sr-only">{{ t('pagination.first') }}</span>
            <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            :disabled="!hasPrevious"
            class="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            @click="goPrevious"
          >
            <span class="sr-only">{{ t('pagination.previous') }}</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <PaginationButton
            v-for="(pageButton, index) in pageButtons"
            :key="index"
            v-bind="pageButton"
          />
          <button
            :disabled="!hasNext"
            class="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            @click="goNext"
          >
            <span class="sr-only">{{ t('pagination.next') }}</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            :disabled="!hasNext"
            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            @click="updatePageNumber(page.totalPages)"
          >
            <span class="sr-only">{{ t('pagination.last') }}</span>
            <ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/20/solid'
import PaginationButton from './PaginationButton.vue'
import { computed, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const props = defineProps({
  page: {
    type: Object,
    required: true,
  },
  updatePageNumber: {
    type: Function,
    required: true,
  },
  updatePageSize: {
    type: Function,
    default: () => {},
  },
})

const emit = defineEmits(['pageUpdated'])

const showStart = computed(() => (props.page.number - 1) * props.page.size + 1)
const showEnd = computed(() =>
  Math.min(props.page.number * props.page.size, props.page.totalElements)
)

const updatePageSize = (size) => {
  emit('pageUpdated')
  props.updatePageSize(size)
  props.updatePageNumber(1)
}
const updatePageNumber = (number) => {
  emit('pageUpdated')
  props.updatePageNumber(number)
}

// Returns a list of pagination buttons to be shown
const pageButtons = computed(() => {
  const maxNumberOfButtons = 9

  // Calculate the first and last button number to be shown
  const firstButton = Math.min(
    props.page.totalPages - maxNumberOfButtons,
    Math.max(props.page.number - Math.floor(maxNumberOfButtons / 2), 0)
  )
  const lastButton = firstButton + maxNumberOfButtons - 1

  // Generate an array of all possible button numbers
  return (
    [...Array(props.page.totalPages).keys()]
      // Filter out the buttons before the first and after the last
      .filter((n) => n >= firstButton && n <= lastButton)
      // Map to an object to be passed to be iterated over by the button component
      .map((n) => ({
        number: n + 1,
        current: props.page.number === n + 1,
        onClick: () => updatePageNumber(n + 1),
      }))
  )
})

const goPrevious = async (event) => {
  updatePageNumber(props.page.number - 1)
  await nextTick()
  // By default, the current page's button gets the focus. In this case, we want to keep the focus on the 'previous' button
  event.currentTarget.focus()
}
const goNext = async (event) => {
  updatePageNumber(props.page.number + 1)
  await nextTick()
  // By default, the current page's button gets the focus. In this case, we want to keep the focus on the 'next' button
  event.currentTarget.focus()
}

const hasPrevious = computed(() => props.page.number > 1)
const hasNext = computed(() => props.page.number < props.page.totalPages)
</script>
