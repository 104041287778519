import { defineStore } from 'pinia'
import candidateGateway from '../api/candidateGateway'
import { useSettingsStore } from './settingsStore'
import tooPublishers from '../api/tooPublishers'
import { flowTypes } from '../utils/flowTypes'

export const useFlowListStore = defineStore('flowListStore', {
  state: () => ({
    flowTypes: flowTypes,
    isLoading: false,
    error: null,
    flows: [],
    filter: '',
    includeArchived: false,
    flowTypeSlug: '',
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load() {
      this.error = null
      this.isLoading = true

      try {
        const settingsStore = useSettingsStore()
        const params = {
          page: this.page.number,
          size: this.page.size,
          publisherId: settingsStore.activePublisher.id,
          sortBy: 'id',
          sortOrder: 'desc',
          flowTypeSlug: this.flowTypeSlug,
          filter: this.filter,
          includeArchived: this.includeArchived,
        }

        const flows = await candidateGateway.getFlows(params)
        if (flows.meta.page.number === this.page.number) {
          this.flows = flows.data
          this.page = flows.meta.page
          await this.loadFlowsVacancies()
        }
        this.isLoading = false
      } catch (error) {
        this.flows = []
        this.isLoading = false
        this.error = error
      }
    },
    async loadFlowsVacancies() {
      const promises = []
      this.flows.forEach((flow) => {
        if (flow.vacancies.length > 0) {
          promises.push(tooPublishers.getVacancy(flow.vacancies[0]))
        } else {
          promises.push(null)
        }
      })
      const result = await Promise.allSettled(promises)
      this.mapVacanciesToFlows(result)
    },
    mapVacanciesToFlows(promiseData) {
      promiseData.forEach((result, index) => {
        if (result.status === 'fulfilled' && result.value) {
          this.flows[index].firstFlowVacancy = {
            title: result.value.data.title,
            company: result.value.data.companyName,
            id: result.value.data.id,
          }
        }
      })
    },
  },
})
