import { get, post, put } from './common'

const VACANCY_IMPORTER_KEY = 'vacancyImporter'

const getVacancyImportConfiguration = async (companyId) =>
  get('management/v1/configurations', { companyId }, {}, VACANCY_IMPORTER_KEY)

const postVacancyImportConfiguration = async (config) =>
  post('management/v1/configurations', config, {}, VACANCY_IMPORTER_KEY)

const putVacancyImportConfiguration = async (config) =>
  put(
    `management/v1/configurations/${config.id}`,
    config,
    {},
    VACANCY_IMPORTER_KEY
  )

const getImports = async (searchParams) =>
  get('management/v1/imports', searchParams, {}, VACANCY_IMPORTER_KEY)

const getImport = async (importId) =>
  get(`management/v1/imports/${importId}`, {}, {}, VACANCY_IMPORTER_KEY)

const postImport = async (config) =>
  post('management/v1/imports', config, {}, VACANCY_IMPORTER_KEY)

const postDryImport = async (config) =>
  post('management/v1/dry-imports', config, {}, VACANCY_IMPORTER_KEY)

const getImportSecret = async (id, name) =>
  get(
    `management/v1/configurations/${id}/secrets/${name}`,
    {},
    {},
    VACANCY_IMPORTER_KEY
  )

export default {
  getVacancyImportConfiguration,
  postVacancyImportConfiguration,
  putVacancyImportConfiguration,
  getImports,
  getImport,
  postImport,
  postDryImport,
  getImportSecret,
}
