import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'

export const useSiteListStore = defineStore('siteListStore', {
  state: () => ({
    isLoading: false,
    error: null,
    sites: [],
    filter: '',
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load() {
      this.error = null
      this.isLoading = true

      try {
        const sites = await tooManagement.getSites({
          filter: this.filter,
          page: this.page.number,
          size: this.page.size,
          sortBy: 'id',
          sortOrder: 'desc',
        })
        // Check if this is the page we requested.
        // Useful if a user requests the next page before the previous page was loaded.
        if (sites.meta.page.number === this.page.number) {
          this.sites = sites.data
          this.page = sites.meta.page
        }
      } catch (error) {
        this.sites = []
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
  },
})
