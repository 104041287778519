<template>
  <div class="md:grid md:grid-cols-3 md:gap-6">
    <div class="md:col-span-1">
      <div class="px-4 sm:px-0">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ t('settings.settings') }}
        </h3>
      </div>
    </div>
    <div class="mt-5 md:col-span-2 md:mt-0">
      <div
        class="overflow-hidden bg-white px-4 py-5 shadow sm:rounded-md sm:p-6"
      >
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label
              for="locale"
              class="block text-sm font-medium text-gray-700"
              >{{ t('settings.language') }}</label
            >
            <select
              id="locale"
              name="locale"
              class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              :value="settingsStore.locale"
              @change="(e) => updateLocale(e.target.value)"
            >
              <option value="en">English</option>
              <option value="nl">Nederlands</option>
            </select>
          </div>
        </div>
      </div>

      <div
        class="mt-2 overflow-hidden bg-white px-4 py-5 shadow sm:rounded-md sm:p-6"
      >
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label
              for="locale"
              class="block text-sm font-medium text-gray-700"
              >{{ t('settings.preferedLocationSearch') }}</label
            >
            <select
              id="locale"
              name="locale"
              class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              :value="settingsStore.preferredLocationSearch"
              @change="(e) => updatePreferredLocationSearch(e.target.value)"
            >
              <option value="search">
                {{ t('vacancy.location.search') }}
              </option>
              <option value="postalCode">
                {{ t('vacancy.location.postalcode') }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div
        class="mt-2 overflow-hidden bg-white px-4 py-5 shadow sm:rounded-md sm:p-6"
      >
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 flex justify-between pr-2">
            <label
              for="locale"
              class="block text-sm font-medium text-gray-700"
              >{{ t('settings.tooltipKeyboardNavigation') }}</label
            >
            <Toggle
              srLabel="Site"
              :checked="settingsStore.tooltipKeyboardNavigation"
              :onToggle="onToggle"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Toggle from './Toggle.vue'
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from '../store/settingsStore'

const settingsStore = useSettingsStore()

const { t, locale } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const updateLocale = (newLocale) => {
  locale.value = newLocale
  settingsStore.locale = newLocale
}

const updatePreferredLocationSearch = (preferred) => {
  settingsStore.preferredLocationSearch = preferred
}

const onToggle = () => {
  settingsStore.tooltipKeyboardNavigation =
    !settingsStore.tooltipKeyboardNavigation
}
</script>
