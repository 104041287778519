import { defineStore } from 'pinia'
import { useSettingsStore } from './settingsStore.js'
import { useAuthStore } from './authStore.js'
import router from '../router.js'
import { parseJSON, isFuture, isPast } from 'date-fns'
import tooPublishers from '../api/tooPublishers'
import tooManagement from '../api/tooManagement.js'
import candidateGateway from '../api/candidateGateway.js'
import uuidv4 from '../utils/uuidv4.js'
import { addressIsNotEmpty, getRegion } from '../utils/location.js'
import Storyblok from '../storyblok'
import { postImage } from '../api/common'

const emptyVacancy = {
  id: null,
  title: '',
  reference: '',
  isTopItem: false,
  isTopItemInitial: false,
  language: 'nl',
  slug: undefined,
  description: '',
  archived: false,
  companyId: null,
  imageUrls: [],
  videoUrls: [],
  location: {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    region: '',
    countryCode: '',
    geo: {
      lng: null,
      lat: null,
    },
  },
  employmentHours: {
    exact: null,
    min: null,
    max: null,
  },
  salary: {
    min: null,
    max: null,
  },
  salaryPeriod: 'BPM',
  contactPerson: {
    name: '',
    company: '',
    email: '',
    phone: '',
  },
}

const parsePublicationDatesAsUTC = (publication) => ({
  ...publication,
  externalStartDate:
    publication.externalStartDate && parseJSON(publication.externalStartDate),
  externalEndDate:
    publication.externalEndDate && parseJSON(publication.externalEndDate),
  internalStartDate:
    publication.internalStartDate && parseJSON(publication.internalStartDate),
  internalEndDate:
    publication.internalEndDate && parseJSON(publication.internalEndDate),
  regionalStartDate:
    publication.regionalStartDate && parseJSON(publication.regionalStartDate),
  regionalEndDate:
    publication.regionalEndDate && parseJSON(publication.regionalEndDate),
})

const addPublicationScope = (publication) => {
  let scope = 'external'
  if (!publication.externalStartDate) {
    if (publication.regionalStartDate) {
      scope = 'regional'
    } else if (publication.internalStartDate) {
      scope = 'internal'
    }
  }

  publication.scope = scope
  return publication
}

const addPublicationStatus = (publication) => {
  const startDate = publication[`${publication.scope}StartDate`]
  const endDate = publication[`${publication.scope}EndDate`]
  let status
  if (isFuture(startDate)) {
    status = 'scheduled'
  } else if (isPast(startDate) && isFuture(endDate)) {
    status = 'published'
  } else if (isPast(startDate) && isPast(endDate)) {
    status = 'closed'
  }
  publication.status = status
  return publication
}

const initPublicationTags = (publication) => {
  publication.tags = publication.tags || {}
  return publication
}

const hasId = (p) => typeof p.id !== 'undefined'

export const useVacancyStore = defineStore('vacancyStore', {
  state: () => ({
    isLoading: false,
    isSaving: false,
    errors: [],
    isSavedSuccessfully: false,
    vacancy: JSON.parse(JSON.stringify(emptyVacancy)),
    vacancyFlows: [],
    vacancyFlowsInitial: [],
    flowsToRemove: [],
    publications: [],
    storyblokPages: [],
    company: null,
    sites: [],
    salaryScales: [],
    hasSocialCampaignAds: false,
    hasSocialCampaignAdsInitial: false,
    hasRecruitmentMarketingAdvice: false,
    hasRecruitmentMarketingAdviceInitial: false,
  }),
  actions: {
    async load(vacancyId) {
      this.isLoading = true
      this.errors = []
      this.isSavedSuccessfully = false
      this.company = null
      this.sites = []
      this.publications = []
      this.storyblokPages = []

      if (vacancyId === 'new') {
        this.initializeNewVacancy()
        return
      }

      try {
        await this.loadVacancyData(vacancyId)
        await this.loadCompanySitesAndTags()
        this.isLoading = false
      } catch (error) {
        this.handleLoadError(error)
      }
    },
    async initializeNewVacancy() {
      this.vacancy = { ...emptyVacancy, reference: uuidv4(), id: 'new' }
      this.isLoading = false
    },
    async loadVacancyData(vacancyId) {
      const authStore = useAuthStore()
      const settingsStore = useSettingsStore()
      const vacancy = await (authStore.isAdmin
        ? tooManagement.getVacancy(vacancyId)
        : tooPublishers.getVacancy(vacancyId))

      // Logic to handle publisher change
      if (
        authStore.isAdmin &&
        (!settingsStore.activePublisher.id ||
          settingsStore.activePublisher.id !== vacancy.data.publisherId)
      ) {
        const publisher = settingsStore.publisherList.find(
          (publisher) => publisher.id === vacancy.data.publisherId,
        )
        settingsStore.activePublisher = publisher
      }

      const [socialCampaignsData, publications] = await Promise.all([
        tooPublishers.getPromotions(vacancyId),
        tooPublishers.getPublications(vacancyId),
      ])

      this.handleVacancyData(vacancy, socialCampaignsData, publications)
    },
    handleVacancyData(vacancy, socialCampaignsData, publications) {
      this.vacancy = vacancy.data
      this.vacancy.isTopItemInitial = vacancy.data.isTopItem

      this.hasSocialCampaignAds = socialCampaignsData.data.hasSocialCampaignAds
      this.hasSocialCampaignAdsInitial =
        socialCampaignsData.data.hasSocialCampaignAds

      this.hasRecruitmentMarketingAdvice =
        socialCampaignsData.data.hasRecruitmentMarketingAdvice
      this.hasRecruitmentMarketingAdviceInitial =
        socialCampaignsData.data.hasRecruitmentMarketingAdvice

      this.publications = publications.data
        .map(parsePublicationDatesAsUTC)
        .map(addPublicationScope)
        .map(addPublicationStatus)
        .map(initPublicationTags)
    },
    async loadCompanySitesAndTags() {
      const company = await tooPublishers.getCompany(this.vacancy.companyId)
      this.company = company.data

      const sites = await tooPublishers.getSitesForCompany(
        this.vacancy.companyId,
      )
      this.sites = sites.data

      await this.loadSiteTags()
    },
    async loadSiteTags() {
      const tagRequests = this.sites.map((site) =>
        tooPublishers.getTagsForSite(site.id),
      )
      const tagResponses = await Promise.allSettled(tagRequests)
      tagResponses.forEach((response, index) => {
        if (response.status === 'fulfilled') {
          this.sites[index].tags = response.value.data
        } else if (response.status === 'rejected') {
          this.errors.push(response.reason)
        }
      })
    },
    handleLoadError(error) {
      this.vacancy = JSON.parse(JSON.stringify(emptyVacancy))
      this.sites = []
      this.publications = []
      this.storyblokPages = []
      this.isLoading = false
      this.errors.push(error)
    },
    async loadSalaryScales() {
      try {
        const salaryScales = await tooPublishers.getSalaryScales()
        // Use natural sorting, so 'CAO Gemeenten 13' goes after 'CAO Gemeenten 2'
        this.salaryScales = salaryScales.data.sort((firstScale, secondScale) =>
          firstScale.name.localeCompare(secondScale.name, undefined, {
            numeric: true,
          }),
        )
      } catch (error) {
        this.errors.push(error)
      }
    },
    async loadVacancyFlows(vacancyId) {
      if (vacancyId === 'new') {
        return
      }
      this.isLoading = true
      this.vacancyFlows = []
      this.errors = []
      try {
        const settingsStore = useSettingsStore()
        const flowsData = await candidateGateway.getFlows({
          vacancyId,
          publisherId: settingsStore.activePublisher.id,
        })
        this.vacancyFlows = flowsData.data
        this.vacancyFlowsInitial = flowsData.data
        this.isLoading = false
      } catch (error) {
        this.handleFlowLoadError(error)
      }
    },
    handleFlowLoadError(error) {
      this.errors.push(error)
      this.isLoading = false
    },
    async loadStoryblokPages(vacancyId) {
      this.isLoading = true
      this.errors = []
      this.storyblokPages = []

      try {
        const [draftResponse, publishedResponse] = await Promise.all([
          Storyblok.get('cdn/stories', {
            version: 'draft',
            filter_query: {
              dataVacancyId: { in: vacancyId },
            },
          }),
          Storyblok.get('cdn/stories', {
            version: 'published',
            filter_query: {
              dataVacancyId: { in: vacancyId },
            },
          }),
        ])

        // Draft response includes published pages also
        this.storyblokPages = draftResponse.data.stories
        publishedResponse.data.stories.forEach((story) => {
          const page = this.storyblokPages.find((item) => item.id === story.id)
          if (page) {
            page.published = true
          }
        })

        this.isLoading = false
      } catch (error) {
        this.handleStoryblokLoadError(error)
      }
    },
    handleStoryblokLoadError(error) {
      this.storyblokPages = []
      this.isLoading = false
      this.errors.push(error)
    },
    addVacancyFlow(newFlow) {
      const existingFlow = this.vacancyFlowsInitial.find(
        (flow) => flow.flowTypeSlug === newFlow.flowTypeSlug,
      )
      if (existingFlow && !this.flowsToRemove.includes(newFlow.flowTypeSlug)) {
        //to assign new flow, we have to first delete previous one of some flowTypeSlug
        this.flowsToRemove.push(newFlow.flowTypeSlug)
      }
      //if the new assigned flow is same as old one, we don't need to remove
      if (existingFlow?.id === newFlow.id) {
        const index = this.flowsToRemove.indexOf(newFlow.flowTypeSlug)
        this.flowsToRemove.splice(index, 1)
      }
      this.vacancyFlows = this.vacancyFlows.filter(
        (flow) => flow.flowTypeSlug !== newFlow.flowTypeSlug,
      )
      this.vacancyFlows.push(newFlow)
    },
    removeVacancyFlow(flowTypeSlug) {
      this.vacancyFlows = this.vacancyFlows.filter(
        (flow) => flow.flowTypeSlug !== flowTypeSlug,
      )
      const existingFlow = this.vacancyFlowsInitial.find(
        (flow) => flow.flowTypeSlug === flowTypeSlug,
      )
      if (existingFlow && !this.flowsToRemove.includes(flowTypeSlug)) {
        this.flowsToRemove.push(flowTypeSlug)
      }
    },
    async saveVacancyFlows() {
      this.isSaving = true
      this.errors = []
      this.isSavedSuccessfully = false
      try {
        await this.deleteVacancyFlows()
        const newVacancyFlows = this.vacancyFlows.map(({ id, ...flow }) => ({
          ...flow,
          flowId: id,
        }))
        await candidateGateway.putVacancyFlows(this.vacancy.id, {
          vacancyFlows: newVacancyFlows,
        })
        this.isSavedSuccessfully = true
        this.isSaving = false
        this.flowsToRemove = []
      } catch (error) {
        this.handleFlowSaveError(error)
      }
    },
    async deleteVacancyFlows() {
      if (!this.flowsToRemove.length) {
        return
      }
      const flowTypeSlugs = this.flowsToRemove.join(',')
      await candidateGateway.deleteVacancyFlow(this.vacancy.id, flowTypeSlugs)
    },
    async savePublications() {
      this.isSaving = true
      this.errors = []
      this.isSavedSuccessfully = false

      try {
        const savePublicationPromises = this.publications.map(
          async (publication, index) => {
            // We remove the extra fields that were added to the publication
            const dataToSend = {
              ...publication,
              status: undefined,
              scope: undefined,
            }
            const { data } = await (hasId(publication)
              ? tooPublishers.putPublication(dataToSend)
              : tooPublishers.postPublication(dataToSend, this.vacancy.id))

            const savedPublication = parsePublicationDatesAsUTC(data)
            addPublicationScope(savedPublication)
            addPublicationStatus(savedPublication)
            initPublicationTags(savedPublication)
            this.publications[index] = savedPublication
          },
        )

        const savePublicationsRes = await Promise.allSettled([
          ...savePublicationPromises,
          this.toggleJobmarketingAdvice(),
          this.toggleSocialCampaignAds(),
        ])

        savePublicationsRes.forEach((publicationRes) => {
          if (publicationRes.status === 'rejected') {
            this.errors.push(publicationRes.reason)
          }
        })

        if (!this.errors.length) {
          this.isSavedSuccessfully = true
        }
      } catch (error) {
        this.handlePublicationSaveError(error)
      } finally {
        this.isSaving = false
      }
    },

    async saveVacancy() {
      this.isSaving = true
      this.errors = []
      this.isSavedSuccessfully = false

      try {
        await this.saveVacancyImages()

        const vacancy = { ...this.vacancy }

        // Prevent sending geo coordinates unless they are both present
        if (
          vacancy.location &&
          (!vacancy.location.geo?.lat || !vacancy.location.geo?.lng)
        ) {
          delete vacancy.location.geo
        }

        let savedVacancy
        let newRoute = ''
        if (vacancy.id === 'new') {
          // Delete the id="new" value
          delete vacancy.id
          savedVacancy = await tooPublishers.postVacancy(vacancy)
          newRoute = { name: 'vacancy', params: { id: savedVacancy.data.id } }
        } else {
          savedVacancy = await tooPublishers.putVacancy(vacancy)
        }

        const updatedVacancy = await this.saveTopItem(savedVacancy.data.id)

        this.isSavedSuccessfully = true
        this.vacancy = updatedVacancy ? updatedVacancy.data : savedVacancy.data

        if (newRoute) {
          await router.replace(newRoute)
        }
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isSaving = false
      }
    },
    async saveTopItem(vacancyId) {
      if (this.vacancy.isTopItemInitial === this.vacancy.isTopItem) {
        return
      }

      if (this.vacancy.isTopItem) {
        return await tooManagement.enableTopItem(vacancyId)
      } else {
        return await tooManagement.disableTopItem(vacancyId)
      }
    },
    async saveVacancyImages() {
      const uploadPromises = []
      const settingsStore = useSettingsStore()
      if (this.vacancy.imageUrls) {
        this.vacancy.imageUrls.forEach((image, index) => {
          if (typeof image !== 'string') {
            uploadPromises.push(
              this.saveCarouselImage({
                index,
                imageFile: image,
                activePublisherId: settingsStore.activePublisher.id,
              }),
            )
          }
        })
      }

      if (uploadPromises.length > 0) {
        await Promise.all(uploadPromises)
      }
    },
    async saveCarouselImage({ index, imageFile, activePublisherId }) {
      try {
        const imageUrl = await postImage(
          'publishers/v1/images/vacancies',
          imageFile,
          {
            'X-Publisher': activePublisherId,
          },
        )
        this.vacancy.imageUrls[index] = imageUrl.data.url
      } catch (error) {
        this.errors.push(error)
      }
    },
    async loadCompanyAndSites() {
      this.company = null
      this.sites = []
      this.isLoading = true

      try {
        await this.loadCompanySitesAndTags()

        // By default, sets the location of new vacancies to be the same as the company's
        if (
          this.vacancy.id === 'new' &&
          addressIsNotEmpty(this.company.location)
        ) {
          await this.copyLocationFromCompany()
        }
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isLoading = false
      }
    },
    async copyLocationFromCompany() {
      const newLocation = { ...this.company.location }

      if (newLocation.geo?.lat && newLocation.geo?.lng) {
        newLocation.region = await getRegion({
          latitude: newLocation.geo.lat,
          longitude: newLocation.geo.lng,
        })
      }

      this.vacancy.location = JSON.parse(JSON.stringify(emptyVacancy.location))
      Object.assign(this.vacancy.location, newLocation)
    },
    async toggleSocialCampaignAds() {
      if (this.hasSocialCampaignAds === this.hasSocialCampaignAdsInitial) {
        return
      }
      try {
        if (this.hasSocialCampaignAds) {
          await tooPublishers.putSocialCampaignAds(this.vacancy.id)
        } else {
          await tooPublishers.deleteSocialCampaignAds(this.vacancy.id)
        }
      } catch (error) {
        this.errors.push(error)
      }
    },
    async toggleJobmarketingAdvice() {
      if (
        this.hasRecruitmentMarketingAdvice ===
        this.hasRecruitmentMarketingAdviceInitial
      ) {
        return
      }
      try {
        if (this.hasRecruitmentMarketingAdvice) {
          await tooPublishers.putJobmarketingAdvice(this.vacancy.id)
        } else {
          await tooPublishers.deleteJobmarketingAdvice(this.vacancy.id)
        }
      } catch (error) {
        this.errors.push(error)
      }
    },
  },
})
