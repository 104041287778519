<template>
  <div class="col-span-6">
    <div class="flex items-center gap-x-0.5">
      <label for="cutOffDate" class="block text-sm font-medium text-gray-700">{{
        t('company.importer.importFrom')
      }}</label
      ><HelpPopover :text="t('company.importer.cutOffDateHelper')" />
    </div>
    <date-picker
      id="cutOffDate"
      :value="
        importerStore.activeConfig.cutoffDate
          ? new Date(importerStore.activeConfig.cutoffDate)
          : null
      "
      type="date"
      format="D MMM YYYY"
      :disabledDate="disabledCutOffDate"
      :clearable="true"
      :editable="false"
      class="mt-1"
      @change="updateCutoffDate"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useImporterStore } from '../../store/importerStore'
import { isAfter } from 'date-fns'
import HelpPopover from '../HelpPopover.vue'
import DatePicker from 'vue-datepicker-next'

const importerStore = useImporterStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const updateCutoffDate = (date) => {
  importerStore.activeConfig.cutoffDate = date
}

const disabledCutOffDate = (date) => isAfter(date, new Date())
</script>
