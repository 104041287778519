import { defineStore } from 'pinia'
import router from '../router.js'
import tooManagement from '../api/tooManagement'
import { useSalaryScaleGroupListStore } from './salaryScaleGroupListStore'

export const useSalaryScaleGroupStore = defineStore('salaryScaleGroupStore', {
  state: () => ({
    isLoading: false,
    isSaving: false,
    errors: [],
    isSavedSuccessfully: false,
    scales: [],
    editedScalesIds: [],
    tempSalaryScaleGroupName: 'Salary Scale Group - NEW',
  }),
  actions: {
    async load(salaryScaleGroupId) {
      this.error = null
      this.isLoading = true

      try {
        if (salaryScaleGroupId === 'new') {
          this.isLoading = false
          return
        }
        const salaryScaleGroup = await tooManagement.getSalaryScales(
          salaryScaleGroupId
        )
        this.scales = salaryScaleGroup.data
        this.scales.sort((a, b) => a.id - b.id || a.name.localeCompare(b.name))

        this.isLoading = false
      } catch (error) {
        this.scales = []
        this.errors.push(error)
      } finally {
        this.isLoading = false
      }
    },
    async save({ salaryScaleGroupId, name }) {
      try {
        this.isSaving = true
        this.isSavedSuccessfully = false
        this.errors = []
        const promises = []

        //first we save scale group the get salaryScaleGroupId
        const salaryScaleGroup = await this.saveSalaryScaleGroup(
          salaryScaleGroupId,
          name
        )

        //filter the newly created scales
        const newScales = this.scales
          .filter((group) => typeof group.id == 'string')
          .map((scale) => {
            return {
              ...scale,
              salaryScaleGroupId: salaryScaleGroup?.data?.id,
            }
          })

        //filter out only edit scales, so we don't have to resave all
        const editedScales = this.scales.filter((group) =>
          this.editedScalesIds.includes(group.id)
        )
        const scales = [...newScales, ...editedScales]
        scales.forEach((group) => {
          if (group.id === 'new') {
            delete group.id
            promises.push(tooManagement.postSalaryScale(group))
          } else {
            promises.push(tooManagement.putSalaryScale(group.id, group))
          }
        })
        const savedScalesRes = await Promise.allSettled(promises)
        savedScalesRes.forEach((scaleRes) => {
          if (scaleRes.status === 'rejected') {
            this.errors.push(scaleRes.reason)
          }
        })

        if (!this.errors.length) {
          this.isSavedSuccessfully = true
          //reload scales to show the saved ID
          await this.load(salaryScaleGroup?.data?.id)
        }
        this.isSaving = false
      } catch (error) {
        this.errors.push(error)
        this.isSaving = false
        this.isSavedSuccessfully = false
      }
    },
    async saveSalaryScaleGroup(salaryScaleGroupId, name) {
      let newRoute = ''
      let result
      if (salaryScaleGroupId === 'new') {
        const salaryScaleGroupListStore = useSalaryScaleGroupListStore()
        result = await tooManagement.postSalaryScaleGroup({ name })
        //push the new scaleGroup to the list so we don't have to reload
        salaryScaleGroupListStore.salaryScaleGroups.push(result.data)
        newRoute = `/salary-scales/${result?.data?.id}`
      } else {
        result = await tooManagement.putSalaryScaleGroup(salaryScaleGroupId, {
          name,
        })
      }
      if (newRoute) {
        router.replace(newRoute)
      }
      return result
    },
  },
})
