<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <PageHeader
        :title="companyStore.company.name"
        :subtitle="t('company.editCompany')"
        :isLoading="companyStore.isLoading"
      />

      <TabNavigation storeName="company" :tabs="tabs" class="mb-5" />

      <div v-if="activeTab === 'company'">
        <TwoColumnForm title="">
          <ErrorMessages
            v-if="companyStore.errors.length"
            :errors="companyStore.errors"
          />
        </TwoColumnForm>
        <TwoColumnForm :title="t('company.title')">
          <TwoColumnFormCard><Meta /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('company.images')">
          <TwoColumnFormCard><Images /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm
          :title="t('company.videos')"
          :helpPopoverText="t('vacancy.videoPopover')"
        >
          <TwoColumnFormCard>
            <FormVideos
              :videoUrls="companyStore.company?.videoUrls || []"
              @update-videos="updateVideos"
            />
          </TwoColumnFormCard>
        </TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('company.shortDescription')"
          ><ShortDescription id="companyShortDescription"
        /></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('company.description')"
          ><Description id="companyDescription"
        /></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('company.socialLinks')"
          ><SocialLinks id="socialLinks"
        /></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('company.location.title')"
          ><Location storeName="company"
        /></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('company.sites')"
          ><TwoColumnFormCard><Sites /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('company.publishers')"
          ><TwoColumnFormCard><Publishers /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm title="">
          <FormSave
            :validationErrors="v$.$errors"
            :validationTouch="v$.$touch"
            storeName="company"
            storeAction="save"
        /></TwoColumnForm>
      </div>

      <ImporterConfig v-else-if="activeTab === 'importer'" />

      <ImporterEvents v-else-if="activeTab === 'events'" />

      <EventDetail v-else-if="activeTab === 'eventDetail'" />

      <Subscription
        v-else-if="activeTab === 'companySubscription'"
        principalType="COMPANY"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { useCompanyStore } from '../store/companyStore'
import FormSave from '../components/FormSave.vue'
import TwoColumnForm from '../components/TwoColumnForm.vue'
import TwoColumnFormCard from '../components/TwoColumnFormCard.vue'
import TwoColumnFormDivider from '../components/TwoColumnFormDivider.vue'
import ErrorMessages from '../components/ErrorMessages.vue'
import Meta from '../components/company/Meta.vue'
import SocialLinks from '../components/SocialLinks.vue'
import Sites from '../components/company/Sites.vue'
import Publishers from '../components/company/Publishers.vue'
import Images from '../components/company/Images.vue'
import ImporterConfig from '../components/company/ImporterConfig.vue'
import ImporterEvents from '../components/company/ImporterEvents.vue'
import Subscription from '../components/subscription/Subscription.vue'
import EventDetail from '../components/company/EventDetail.vue'
import PageHeader from '../components/PageHeader.vue'
import Location from '../components/Location.vue'
import FormVideos from '../components/FormVideos.vue'
import TabNavigation from '../components/TabNavigation.vue'

// These two components are async so the ckeditor library is not included in the main bundle
const ShortDescription = defineAsyncComponent(
  () => import('../components/company/ShortDescription.vue'),
)
const Description = defineAsyncComponent(
  () => import('../components/company/Description.vue'),
)

const companyStore = useCompanyStore()
const { company } = storeToRefs(companyStore)

const route = useRoute()

const v$ = useVuelidate()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const updateVideos = (videos) => {
  companyStore.company.videoUrls = videos
}

companyStore.load(route.params.id)

const tabs = computed(() => {
  if (company.value?.id !== 'new') {
    return ['company', 'importer', 'events', 'companySubscription']
  } else {
    return ['company']
  }
})

const activeTab = computed(() => {
  return route.name
})
</script>
