export const theme = {
  '--heading': '"Roboto"',
  '--subheading': '"Roboto"',
  '--content': '"Open Sans"',
  '--primary-100': '#ccdbf2',
  '--primary-200': '#99b8e5',
  '--primary-300': '#6694d9',
  '--primary-400': '#3371cc',
  '--primary-500': '#004dbf',
  '--primary-600': '#003e99',
  '--primary-700': '#002e73',
  '--primary-800': '#001f4c',
  '--primary-900': '#000f26',
  '--secondary-100': '#ffe0cc',
  '--secondary-200': '#ffc299',
  '--secondary-300': '#ffa366',
  '--secondary-400': '#ff8533',
  '--secondary-500': '#ff6600',
  '--secondary-600': '#cc5200',
  '--secondary-700': '#993d00',
  '--secondary-800': '#662900',
  '--secondary-900': '#331400',
  '--tertiary-100': '#e2f1d0',
  '--tertiary-200': '#c5e2a1',
  '--tertiary-300': '#a7d471',
  '--tertiary-400': '#8ac542',
  '--tertiary-500': '#6db713',
  '--tertiary-600': '#57920f',
  '--tertiary-700': '#416e0b',
  '--tertiary-800': '#2c4908',
  '--tertiary-900': '#162504',
  '--fourth-100': '#f0cfd6',
  '--fourth-200': '#e09eac',
  '--fourth-300': '#d16e83',
  '--fourth-400': '#c13d59',
  '--fourth-500': '#b20d30',
  '--fourth-600': '#8e0a26',
  '--fourth-700': '#6b081d',
  '--fourth-800': '#470513',
  '--fourth-900': '#24030a',
  '--gray-100': '#efefef',
  '--gray-200': '#dfdfdf',
  '--gray-300': '#d0d0d0',
  '--gray-400': '#c0c0c0',
  '--gray-500': '#b0b0b0',
  '--gray-600': '#8d8d8d',
  '--gray-700': '#6a6a6a',
  '--gray-800': '#464646',
  '--gray-900': '#232323',
}
