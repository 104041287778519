<template>
  <div class="col-span-6">
    <FormInput
      id="recruiteeCompanyId"
      type="text"
      :value="config.recruiteeCompanyId"
      :label="t('company.importer.recruitee.companyId')"
      :onInput="(value) => (config.recruiteeCompanyId = value)"
      :rules="requiredRule"
    />
  </div>
  <div class="col-span-6">
    <FormInput
      id="token"
      :secretPreviewEnabled="true"
      :type="showToken ? 'text' : 'password'"
      :value="config.token"
      :label="t('company.importer.recruitee.token')"
      :onInput="updateToken"
      :rules="requiredRule"
      @show-secret="toggleShowToken"
    />
  </div>
</template>

<script setup>
import { reactive, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { required, helpers } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'
import { useImporterStore } from '../../store/importerStore'
import vacancyImporter from '../../api/vacancyImporter'

const props = defineProps({ modelValue: Array })
const emit = defineEmits(['update:modelValue'])
const importerStore = useImporterStore()

const config = reactive({
  recruiteeCompanyId:
    props.modelValue?.find((prop) => prop.name === 'recruiteeCompanyId')
      ?.value || '',
  token: props.modelValue?.find((prop) => prop.name === 'token')?.value || '',
})

const showToken = ref(false)
//once the token value is changed, don't call endpoint to retrieve secret
const tokenInitialValue = ref(true)
const updateToken = (value) => {
  if (tokenInitialValue.value) {
    tokenInitialValue.value = false
  }
  config.token = value
}

watch(config, (value) => {
  emit('update:modelValue', [
    {
      name: 'recruiteeCompanyId',
      value: value.recruiteeCompanyId,
      secret: false,
    },
    {
      name: 'token',
      value: value.token,
      secret: true,
    },
  ])
})

const toggleShowToken = async ({ inputName }) => {
  if (
    !showToken.value &&
    importerStore.activeConfig.id &&
    tokenInitialValue.value
  ) {
    const response = await vacancyImporter.getImportSecret(
      importerStore.activeConfig.id,
      inputName,
    )
    config.token = response?.data?.value || config.token
  }
  showToken.value = !showToken.value
}

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const requiredRule = {
  required: helpers.withMessage(t('validations.emptyField'), required),
  $autoDirty: true,
}
</script>
