import { defineStore } from 'pinia'
import router from '../router.js'
import tooManagement from '../api/tooManagement'
import uuidv4 from '../utils/uuidv4.js'

const emptyPublisher = {
  id: null,
  archived: false,
}

export const usePublisherStore = defineStore('publisherStore', {
  state: () => ({
    isLoading: false,
    isSaving: false,
    errors: [],
    isSavedSuccessfully: false,
    publisher: JSON.parse(JSON.stringify(emptyPublisher)),
    userErrors: [],
    userSuccesses: [],
    users: [],
    usersPage: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
    salaryScaleGroups: [],
  }),
  actions: {
    async load(publisherId) {
      this.resetState()
      this.isLoading = true

      try {
        if (publisherId === 'new') {
          await this.setupNewPublisher()
        } else {
          await this.loadExistingPublisher(publisherId)
        }
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isLoading = false
      }
    },
    async save() {
      this.isSaving = true
      this.errors = []

      try {
        if (this.publisher.id === 'new') {
          await this.saveNewPublisher()
        } else {
          await this.saveExistingPublisher()
        }

        this.isSavedSuccessfully = true
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    async loadUsers() {
      this.isLoading = true

      try {
        const users = await tooManagement.getUsersForPublisher(
          this.publisher.id,
          {
            page: this.usersPage.number,
            size: this.usersPage.size,
            sortBy: 'email',
            sortOrder: 'asc',
          },
        )
        this.users = users.data
        this.usersPage = users.meta.page
      } catch (error) {
        // Loading failed, so clear all data
        this.users = []
        this.userErrors.push(error)
      } finally {
        this.isLoading = false
      }
    },
    async inviteUser(user) {
      this.isLoading = true
      this.userErrors = []
      this.userSuccesses = []

      try {
        await tooManagement.inviteUser(this.publisher.id, {
          ...user,
          url: `${window.location.origin}/`,
        })
        this.userSuccesses.push(`Invitation sent to '${user.email}'`)
        await this.loadUsers()
      } catch (error) {
        this.userErrors.push(error)
      } finally {
        this.isLoading = false
      }
    },
    async deleteUser(user) {
      this.isLoading = true
      this.userErrors = []
      this.userSuccesses = []

      try {
        await tooManagement.deleteUser(this.publisher.id, user.id)
        await this.updateUsersPageAfterDelete()
        await this.loadUsers()
      } catch (error) {
        this.userErrors.push(error)
      } finally {
        this.isLoading = false
      }
    },
    resetState() {
      this.errors = []
      this.isSavedSuccessfully = false
      this.userErrors = []
      this.userSuccesses = []
      this.users = []
      this.usersPage = {
        number: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      }
      this.salaryScaleGroups = []
    },
    async setupNewPublisher() {
      this.publisher = {
        ...JSON.parse(JSON.stringify(emptyPublisher)),
        token: uuidv4(),
      }
      this.publisher.id = 'new'
    },
    async loadExistingPublisher(publisherId) {
      const publisher = await tooManagement.getPublisher(publisherId)
      this.publisher = publisher.data

      await this.loadUsers()

      const salaryScaleGroups =
        await tooManagement.getPublisherSalaryScaleGroups(this.publisher.id)
      this.salaryScaleGroups = salaryScaleGroups.data
    },
    async saveNewPublisher() {
      const newPublisher = { ...this.publisher }
      delete newPublisher.id

      const savedPublisher = await tooManagement.postPublisher(newPublisher)
      this.publisher = savedPublisher.data

      const savedSalaryScaleGroups =
        await tooManagement.putPublisherSalaryScaleGroups(
          this.publisher.id,
          this.salaryScaleGroups,
        )
      this.salaryScaleGroups = savedSalaryScaleGroups.data

      router.replace(`/publishers/${savedPublisher.data.id}/general`)
    },
    async saveExistingPublisher() {
      const savedPublisher = await tooManagement.putPublisher(this.publisher)
      this.publisher = savedPublisher.data

      const savedSalaryScaleGroups =
        await tooManagement.putPublisherSalaryScaleGroups(
          this.publisher.id,
          this.salaryScaleGroups,
        )
      this.salaryScaleGroups = savedSalaryScaleGroups.data
    },
    async updateUsersPageAfterDelete() {
      if (this.usersPage.number === this.usersPage.totalPages) {
        const elementsInLastPage =
          this.usersPage.totalElements % this.usersPage.size
        if (elementsInLastPage === 1) {
          this.usersPage.number = Math.max(1, this.usersPage.number - 1)
        }
      }
    },
    handleError(error) {
      this.errors.push(error)
    },
  },
})
