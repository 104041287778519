import { defineStore } from 'pinia'
import candidateGateway from '../api/candidateGateway'

const emptyCandidate = {
  id: null,
  vacancyId: 0,
  applicationDate: '',
  firstName: '',
  lastName: '',
  fullName: '',
  phoneNumber: '',
  email: '',
  linkedInProfileUrl: '',
  isTest: false,
  isDuplicate: false,
  customFields: [],
  metadata: {
    referrer: '',
    page: {
      title: '',
      url: '',
    },
    firstTouchUtm: {
      campaign: '',
      source: '',
      medium: '',
      term: '',
      content: '',
    },
    lastTouchUtm: {
      campaign: '',
      source: '',
      medium: '',
      term: '',
      content: '',
    },
  },
}

export const useCandidateStore = defineStore('candidateStore', {
  state: () => ({
    isLoading: false,
    error: null,
    candidate: { ...emptyCandidate },
  }),
  actions: {
    async loadCandidate(candidateId) {
      this.isLoading = true
      try {
        const candidateResponse =
          await candidateGateway.getCandidate(candidateId)
        this.candidate = candidateResponse.data
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async toggleTestCandidate() {
      if (!this.candidate.id) return
      this.isLoading = true
      try {
        const result = await candidateGateway.toggleTestCandidate(
          this.candidate.id,
          !this.candidate.isTest,
        )
        this.candidate.isTest = result.data.isTest
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async toggleDuplicateCandidate() {
      if (!this.candidate.id) return
      this.isLoading = true
      try {
        const result = await candidateGateway.toggleDuplicateCandidate(
          this.candidate.id,
          !this.candidate.isDuplicate,
        )
        this.candidate.isDuplicate = result.data.isDuplicate
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
  },
})
