<template>
  <div class="mt-5 overflow-hidden bg-white shadow sm:rounded-lg">
    <!-- SUBSCRIPTION FEATURE HEADER-->
    <div class="flex flex-col flex-wrap gap-y-2 px-4 py-5 lg:flex-row">
      <Listbox
        v-slot="{ open }"
        v-model="featureType"
        class="w-full"
        :disabled="disabled"
      >
        <div class="relative mt-2">
          <ListboxLabel class="block text-sm font-medium text-gray-700">
            {{ t('company.subscription.featureType') }}
          </ListboxLabel>
          <ListboxButton
            class="relative mt-1 min-h-[2.4rem] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:bg-gray-100 sm:text-sm"
          >
            <span class="block truncate">{{ featureType.label }}</span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronDownIcon
                class="mr-1 h-5 w-5 text-gray-400"
                :class="open ? 'rotate-180' : 'rotate-0'"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leaveActiveClass="transition duration-100 ease-in"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <ListboxOptions
              class="absolute z-[1] max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption value="" />
              <ListboxOption
                v-for="type in subscriptionStore.featureTypes"
                v-slot="{ active, selected }"
                :key="type.value"
                :value="type"
                as="template"
              >
                <li
                  :class="[
                    active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900',
                    'relative cursor-default select-none px-4 py-2',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ type.label }}</span
                  >
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox
        v-slot="{ open }"
        v-model="selectedSite"
        class="my-5 w-full"
        :disabled="disabled"
      >
        <div class="relative mt-2">
          <ListboxLabel class="block text-sm font-medium text-gray-700"
            >{{ t('company.subscription.site') }}
          </ListboxLabel>
          <ListboxButton
            class="relative mt-1 min-h-[2.4rem] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:bg-gray-100 sm:text-sm"
          >
            <span class="block truncate">
              {{ selectedSite.label }}
            </span>
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronDownIcon
                class="mr-1 h-5 w-5 text-gray-400"
                :class="open ? 'rotate-180' : 'rotate-0'"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leaveActiveClass="transition duration-100 ease-in"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <ListboxOptions
              class="absolute z-[1] max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption value="" />
              <ListboxOption
                v-for="site in subscriptionStore.availableSites"
                v-slot="{ active, selected }"
                :key="site.value"
                :value="site"
                as="template"
              >
                <li
                  :class="[
                    active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900',
                    'relative cursor-default select-none px-4 py-2',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ site.label }}</span
                  >
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <!-- First column-->
      <div class="text text-gray-900 sm:w-[34rem]">
        <div>
          {{ t(`company.subscription.${subscriptionFeature.slug}`) }}:
          <span class="font-semibold text-indigo-600">{{
            subscriptionFeature.resources[0].name
          }}</span>
        </div>
        <div class="mt-4 flex gap-x-4 text-xs">
          <div class="flex items-center">
            <CurrencyDollarIcon class="h-5 w-5 text-gray-600" />
            <label for="pay-as-you-go" class="ml-1 mr-2 block text-gray-600">{{
              t('company.subscription.payAsYouGo')
            }}</label>

            <Toggle
              srLabel="pay-as-you-go"
              :checked="subscriptionFeature.payAsYouGo"
              :onToggle="togglePayAsYouGo"
              :disabled="disabled"
              @click.stop
            />
          </div>
          <div class="flex items-center">
            <ArrowUturnLeftIcon class="h-4 w-4 text-gray-600" />
            <label for="reversible" class="ml-1 mr-2 block text-gray-600">{{
              t('company.subscription.reversible')
            }}</label>
            <Toggle
              srLabel="reversible"
              :checked="subscriptionFeature.reversible"
              :onToggle="toggleReversible"
              :disabled="disabled"
              @click.stop
            />
          </div>
        </div>
        <div class="mt-4 flex items-center gap-x-4 text-gray-600">
          <div class="flex items-center gap-x-1">
            <WalletIcon class="h-6 w-6" />
            <label class="text-sm" for="credits">{{
              t('company.subscription.credits')
            }}</label>
          </div>
          <div class="w-[13rem]" @click.stop>
            <FormInput
              id="credits"
              type="text"
              :value="subscriptionFeature.planCredits"
              :onInput="updateFeatureCredits"
              :rules="minValueRule"
              :disabled="disabled"
              inputClass="disabled:bg-gray-100"
            />
          </div>
        </div>
      </div>
      <!-- Secound column-->
      <div class="flex-col sm:pl-6">
        <div class="flex items-center gap-x-1 text-gray-600">
          <WalletIcon class="h-5 w-5" />
          <span>{{ t('company.subscription.creditUsage') }}</span>
        </div>
        <div class="ml-1 mt-4 flex flex-col gap-y-2 text-sm text-gray-500">
          <div>
            {{ t('company.subscription.creditUsed') }}:
            <span class="font-bold">{{
              subscriptionFeature.usedCredits || 0
            }}</span>
          </div>
          <div>
            {{ t('company.subscription.creditRemaining') }}:
            <span class="font-bold">{{
              subscriptionFeature.usedCredits
                ? subscriptionFeature.planCredits -
                  subscriptionFeature.usedCredits
                : subscriptionFeature.planCredits
            }}</span>
          </div>
          <div>
            {{ t('company.subscription.creditExceeded') }}:
            <span class="font-bold">{{
              subscriptionFeature.usedCredits > subscriptionFeature.planCredits
                ? subscriptionFeature.usedCredits -
                  subscriptionFeature.planCredits
                : 0
            }}</span>
          </div>
        </div>
      </div>

      <!-- Last column (collapse button)-->
      <div class="ml-auto flex items-center gap-4">
        <button
          v-if="
            !disabled &&
            subscriptionFeature.id !== 'new' &&
            !subscriptionStore.isLoading &&
            !subscriptionFeature?.usages?.length
          "
          :disabled="
            subscription.state !== 'ACTIVATED' || subscriptionStore.isSaving
          "
          class="focus-visible:ring-indigo l-auto sm:mt-0:ml-auto ml-auto mt-2 flex items-center gap-x-2 rounded-md bg-indigo-50 p-4 font-medium text-indigo-500 focus:outline-none focus-visible:ring-2 disabled:bg-gray-100 disabled:text-gray-500"
          @click="
            subscriptionStore.initializeSubscriptionFeaturesUsages(
              subscriptionFeature,
            )
          "
        >
          {{ t('company.subscription.initializeUsages') }}
          <HelpPopover
            v-if="subscription.state !== 'ACTIVATED'"
            :text="t('company.subscription.initializeUsagesDisabled')"
            placement="left"
          />
          <LoadingSpinner v-if="subscriptionStore.isSaving" class="h-4 w-4" />
        </button>
        <button
          v-show="subscriptionFeature?.usages?.length"
          ref="collapseButton"
          class="focus-visible:ring-indigo l-auto sm:mt-0:ml-auto ml-auto mt-2 flex items-center gap-x-2 text-indigo-500 focus:outline-none focus-visible:ring-2"
          @click.stop="
            () => {
              collapseUsagesPanel = !collapseUsagesPanel
              collapseSnapshotsPanel = true
            }
          "
        >
          <span class="font-medium">{{
            collapseUsagesPanel
              ? t('company.subscription.seeUsages')
              : t('company.subscription.hideUsages')
          }}</span>
          <ChevronDoubleUpIcon
            class="w-5 transition"
            :class="collapseUsagesPanel ? 'rotate-180' : ''"
          />
        </button>
        <button
          v-show="subscriptionFeature?.snapshots?.length"
          ref="collapseButton"
          class="focus-visible:ring-indigo l-auto sm:mt-0:ml-auto ml-auto mt-2 flex items-center gap-x-2 text-indigo-500 focus:outline-none focus-visible:ring-2"
          @click.stop="
            () => {
              collapseSnapshotsPanel = !collapseSnapshotsPanel
              collapseUsagesPanel = true
            }
          "
        >
          <span class="font-medium">{{
            collapseSnapshotsPanel
              ? t('company.subscription.showConfigLog')
              : t('company.subscription.hideConfigLog')
          }}</span>
          <ChevronDoubleUpIcon
            class="w-5 transition"
            :class="collapseSnapshotsPanel ? 'rotate-180' : ''"
          />
        </button>
        <button
          v-if="subscriptionFeature.id === 'new'"
          class="ml-auto mt-2 text-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 sm:mt-0"
          @click.stop="removeSubscriptionFeature"
        >
          <TrashIcon class="w-5" />
        </button>
      </div>
    </div>
    <!-- SUBSCRIPTION_FATURE USAGES-->
    <div
      v-if="subscriptionFeature?.usages?.length"
      class="overflow-hidden border-t border-gray-200 transition-[max-height] duration-500"
      :class="!collapseUsagesPanel ? 'h-fit max-h-fit' : 'h-0 max-h-0'"
    >
      <div class="divide-y divide-gray-200">
        <div v-for="usage in subscriptionFeature.usages" :key="usage.id">
          <SubscriptionFeatureUsage
            :usage="usage"
            :reversible="!disabled && subscriptionFeature.reversible"
          />
        </div>
        <div class="px-5">
          <Pagination
            :page="subscriptionFeature.usagePage"
            :updatePageNumber="updatePageNumber"
            :updatePageSize="updatePageSize"
          />
        </div>
      </div>
    </div>
    <!-- SNAPSHOTS -->
    <div
      v-if="subscriptionFeature?.snapshots?.length"
      class="overflow-hidden border-t border-gray-200 transition-[max-height] duration-500"
      :class="!collapseSnapshotsPanel ? 'h-fit max-h-fit' : 'h-0 max-h-0'"
    >
      <div class="divide-y divide-gray-200">
        <div
          v-for="snapshot in subscriptionFeature.snapshots"
          :key="snapshot.id"
        >
          <SubscriptionFeatureSnapshot :snapshot="snapshot" />
        </div>
        <div class="px-5">
          <Pagination :page="subscriptionFeature.snapshotsPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Toggle from '../Toggle.vue'
import FormInput from '../FormInput.vue'
import Pagination from '../Pagination.vue'
import HelpPopover from '../HelpPopover.vue'
import SubscriptionFeatureUsage from './SubscriptionFeatureUsage.vue'
import SubscriptionFeatureSnapshot from './SubscriptionFeatureSnapshot.vue'
import { useSubscriptionStore } from '../../store/subscriptionStore'
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import {
  ChevronDoubleUpIcon,
  CurrencyDollarIcon,
  ArrowUturnLeftIcon,
  WalletIcon,
  TrashIcon,
  ChevronDownIcon,
} from '@heroicons/vue/24/outline'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from '@headlessui/vue'
import { helpers, minValue } from '@vuelidate/validators'
import LoadingSpinner from '../LoadingSpinner.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const subscriptionStore = useSubscriptionStore()

const minValueRule = {
  min: helpers.withMessage(
    t('company.subscription.validations.minCredits'),
    minValue(1),
  ),
}

const props = defineProps({
  subscriptionFeature: {
    required: true,
    type: Object,
  },
  subscription: {
    required: true,
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  index: {
    required: true,
    type: Number,
  },
})

const togglePayAsYouGo = (value) => {
  subscriptionStore.selectedSubscription.features[props.index].payAsYouGo =
    value
}

const toggleReversible = (value) => {
  subscriptionStore.selectedSubscription.features[props.index].reversible =
    value
}

const updateFeatureCredits = (value) => {
  subscriptionStore.selectedSubscription.features[props.index].planCredits =
    value
}

const removeSubscriptionFeature = () => {
  subscriptionStore.selectedSubscription.features.splice(props.index, 1)
}

const updatePageNumber = (value) => {
  subscriptionStore.selectedSubscription.features[
    props.index
  ].usagePage.number = value
  subscriptionStore.getSubscriptionFeaturesUsages()
}

const updatePageSize = (value) => {
  subscriptionStore.selectedSubscription.features[props.index].usagePage.size =
    value
  subscriptionStore.getSubscriptionFeaturesUsages()
}

const collapseUsagesPanel = ref(true)
const collapseSnapshotsPanel = ref(true)

const featureType = computed({
  get() {
    return (
      subscriptionStore.featureTypes.find(
        (type) => type.value === props.subscriptionFeature.slug,
      ) || {}
    )
  },
  set(newValue) {
    console.log(newValue)
  },
})

const selectedSite = computed({
  get() {
    return (
      subscriptionStore.availableSites.find(
        (site) => site.value === props.subscriptionFeature.resources[0].id,
      ) || {}
    )
  },
  set(newValue) {
    subscriptionStore.selectedSubscription.features[
      props.index
    ].resources[0].id = newValue.value
    subscriptionStore.selectedSubscription.features[
      props.index
    ].resources[0].name = newValue.label
  },
})
</script>
