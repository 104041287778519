<template>
  <button
    ref="paginationButton"
    :aria-current="current ? 'page' : 'false'"
    :class="[
      current
        ? 'z-10 border-indigo-500 bg-indigo-50 text-indigo-600'
        : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50',
      'relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
    ]"
    @click="onClick"
  >
    {{ number }}
  </button>
</template>

<script setup>
import { ref, watch, toRefs } from 'vue'

const props = defineProps({
  number: Number,
  current: Boolean,
  onClick: Function,
})

const paginationButton = ref(null)

const { current, number } = toRefs(props)

// Focus/blur the button depending on wheter it represents the current page
watch(number, () => {
  if (current.value) {
    paginationButton.value.focus()
  } else {
    paginationButton.value.blur()
  }
})
</script>
