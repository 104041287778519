import { defineStore } from 'pinia'
import { useSettingsStore } from './settingsStore.js'
import router from '../router.js'
import candidateGatewayApi from '../api/candidateGateway'
import tooPublishers from '../api/tooPublishers'
import { flowActionsTypes } from '../utils/flowActionTypes.js'
import { flowActionTemplates } from '../utils/flowActionTemplates.js'

const makeEmptyFlow = (flowTypeSlug = 'vacancy-apply') => ({
  id: '',
  name: '',
  flowTypeSlug,
  archived: false,
})

const mergeObjects = (defaultConfig, actionConfig) => {
  const result = { ...defaultConfig }

  for (const key in actionConfig) {
    if (Object.prototype.hasOwnProperty.call(actionConfig, key)) {
      const value = actionConfig[key]
      // Retain the key even if the value is empty
      result[key] =
        value !== null && value !== undefined && value !== ''
          ? value
          : result[key]
    }
  }

  return result
}

const emptyAction = {
  id: null,
  flowActionTypeSlug: null,
  initialConfig: '',
  config: {},
  flowId: null,
  actionIndex: null,
}

const emptyEmailConfig = {
  to: '',
  from: '',
  fromName: '',
  replyTo: '',
  subject: '',
  content: '',
}

export const useFlowStore = defineStore('flowStore', {
  state: () => ({
    isLoading: false,
    isSaving: false,
    errors: [],
    isSavedSuccessfully: false,
    saveDisabled: false,
    flow: makeEmptyFlow(),
    flowPreviews: [],
    flowActions: [],
    filter: '',
    flowActionsTypes,
    activeAction: { ...emptyAction },
    emailActionConfig: { ...emptyEmailConfig },
    availableFields: null,
    lastActiveInput: null,
    actionsToDelete: [],
    flowCompanies: [],
  }),

  actions: {
    async load(flowId, flowTypeSlug) {
      this.isLoading = true
      this.flows = []
      this.errors = []
      if (flowId === 'new') {
        this.flow = makeEmptyFlow(flowTypeSlug)
        this.flow.id = 'new'
        this.isLoading = false
        return
      }
      try {
        const settingsStore = useSettingsStore()
        const flow = await candidateGatewayApi.getFlow(flowId, {
          publisherId: settingsStore.activePublisher.id,
        })
        if (flow.data) {
          this.flow = flow.data
        }
        await this.loadFlowActions()
        await this.loadFlowCompanies()

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.errors.push(error)
      }
    },

    async loadFlowPreviews(flowId) {
      if (!this.flowPreviews.some((flow) => flow.id === flowId)) {
        try {
          this.isLoading = true
          const flowActions = await candidateGatewayApi.getFlowActions(flowId)
          if (flowActions.data) {
            this.flowPreviews.push({ id: flowId, actions: flowActions.data })
          }
        } catch (error) {
          this.isLoading = false
          this.errors.push(error)
        }
      }
    },

    async loadFlowActions() {
      try {
        const flowActions = await candidateGatewayApi.getFlowActions(
          this.flow.id,
        )
        if (flowActions.data) {
          this.flowActions = flowActions.data.map((action) => ({
            ...action,
            initialConfig: JSON.stringify(action.config),
          }))
        }
      } catch (error) {
        this.isLoading = false
        this.errors.push(error)
      }
    },

    async loadFlowCompanies() {
      try {
        const flowCompanies =
          await candidateGatewayApi.getCompaniesWithDefaultFlow(this.flow.id)
        if (flowCompanies.data) {
          const companyDetails = await Promise.allSettled(
            flowCompanies.data.map((flowCompany) =>
              tooPublishers.getCompany(flowCompany.companyId),
            ),
          )
          this.flowCompanies = companyDetails
            .filter((company) => company.status === 'fulfilled')
            .map((company) => company.value.data)
        }
      } catch (error) {
        this.isLoading = false
        this.errors.push(error)
      }
    },

    async loadAvailableFields(flowId, vacancies) {
      const parameters = {}
      if (flowId !== 'new') {
        parameters.flowId = flowId
      }
      if (vacancies?.length) {
        parameters.vacancyIds = vacancies.join(',')
      }

      try {
        this.errors = []
        this.isLoading = true
        const fields = await candidateGatewayApi.getCandidateFields(parameters)
        if (fields.data) {
          this.availableFields = fields.data
        }
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isLoading = false
      }
    },

    addFlowAction(action) {
      this.flowActions.push(action)
      this.setActiveAction(action, this.flowActions.length - 1)
      this.loadAvailableFields(this.flow.id, this.flow?.vacancies)
    },

    deleteFlowAction(flow, action, index) {
      if (index === this.activeAction.actionIndex) {
        this.resetActiveAction()
      }
      this.flowActions.splice(index, 1)
      if (action.id) {
        this.actionsToDelete.push({ flowId: flow.id, actionId: action.id })
      }
    },

    duplicateFlow() {
      this.flow = {
        ...this.flow,
        name: this.flow.name + ' - DUPLICATE',
        id: 'new',
      }
      this.flowActions = this.flowActions.map((action) => ({
        ...action,
        id: null,
        flowId: null,
      }))
    },

    async saveAll() {
      this.isSaving = true
      this.errors = []
      this.isSavedSuccessfully = false

      const flow = { ...this.flow }
      try {
        const settingsStore = useSettingsStore()
        let newRoute = ''

        if (flow.id === 'new') {
          delete flow.id
          const savedFlow = await candidateGatewayApi.postFlow(flow, {
            publisherId: settingsStore.activePublisher.id,
          })
          this.flow = savedFlow.data
          newRoute = `/flows/${savedFlow.data.id}`
        } else {
          await candidateGatewayApi.putFlow(flow, {
            publisherId: settingsStore.activePublisher.id,
          })
        }

        await this.deleteActions()
        await this.saveActions()
        await this.updateDefaultFlowForCompanies()

        if (newRoute) {
          await router.replace(newRoute)
        }

        this.isSavedSuccessfully = true
      } catch (error) {
        this.errors.push(error)
      } finally {
        this.isSaving = false
      }
    },

    async updateDefaultFlowForCompanies() {
      await candidateGatewayApi.putDefaultFlowForCompanies(
        this.flow.id,
        this.flowCompanies,
      )
    },

    async deleteActions() {
      this.isSaving = true
      this.errors = []

      const deleteActionsRes = await Promise.allSettled(
        this.actionsToDelete.map(({ flowId, actionId }) =>
          candidateGatewayApi.deleteFlowAction(flowId, actionId),
        ),
      )
      deleteActionsRes.forEach((flowRes) => {
        if (flowRes.status === 'rejected') {
          this.errors.push(flowRes.reason)
        }
      })
      this.isSaving = false
      this.actionsToDelete = []
    },

    async saveActions() {
      this.isSaving = true
      this.errors = []

      const saveActionPromises = this.flowActions.map((action, actionIndex) => {
        if (JSON.stringify(action.config) === action.initialConfig) {
          return null
        }
        return action.id
          ? candidateGatewayApi.putFlowAction(action).then((result) => {
              this.flowActions[actionIndex].initialConfig = JSON.stringify(
                result.data.config,
              )
            })
          : candidateGatewayApi
              .postFlowAction(this.flow.id, action)
              .then((result) => {
                this.flowActions[actionIndex] = {
                  ...result.data,
                  initialConfig: JSON.stringify(result.data.config),
                }
              })
      })

      await Promise.allSettled(saveActionPromises).then((results) => {
        results.forEach((res) => {
          if (res.status === 'rejected') {
            this.errors.push(res.reason)
          }
        })
      })

      this.isSaving = false
    },

    setEmailActionConfig(actionType, flowType) {
      const templateConfig = flowActionTemplates[flowType]?.[actionType] || null
      this.emailActionConfig = templateConfig
        ? { ...templateConfig }
        : { ...emptyEmailConfig }
    },

    setActiveAction(action, actionIndex) {
      const actionConfig = flowActionsTypes[this.flow.flowTypeSlug]?.find(
        (config) => config.value === action.value,
      )
      const defaultConfig =
        (actionConfig && flowActionTemplates[actionConfig.value]) || {}

      action.config = mergeObjects(defaultConfig, action.config)

      this.activeAction = {
        ...action,
        actionIndex,
      }
    },

    resetActiveAction() {
      this.activeAction = { ...emptyAction }
    },

    resetEmailConfig() {
      this.emailActionConfig = { ...emptyEmailConfig }
    },
  },
})
