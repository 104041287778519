<template>
  <div class="">
    <List
      v-slot="{ entity: user }"
      :entities="publisherStore.users"
      :page="publisherStore.usersPage"
      :updatePageNumber="updatePageNumber"
    >
      <div class="px-4 py-4 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="truncate text-sm">
            {{ user.email }}
          </p>
          <div class="ml-2 flex shrink-0">
            <p
              v-if="!user.reference"
              class="truncate pr-3 text-sm text-gray-400"
            >
              ({{ t('publisher.user.invited') }})
            </p>
            <button
              class="rounded-sm focus:border-red-400 focus:outline-none focus:ring-2 focus:ring-red-400"
              :aria-label="t('publisher.user.deleteTitle')"
              @click="confirmUserDelete(user)"
            >
              <TrashIcon class="h-5 w-5 text-gray-400 hover:text-red-400" />
            </button>
          </div>
        </div>
      </div>
    </List>
  </div>

  <TransitionRoot as="template" :show="confirmationModalOpen">
    <Dialog
      as="div"
      class="fixed inset-0 z-10 overflow-y-auto"
      @close="confirmationModalOpen = false"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <ExclamationTriangleIcon
                  class="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  {{ t('publisher.user.deleteTitle') }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="mb-2 text-sm text-gray-500">
                    {{ t('publisher.user.deleteConfirmation') }}
                  </p>
                  <p class="text-center font-medium">
                    {{ selectedUser.email }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                @click="deleteUser()"
              >
                {{ t('publisher.user.delete') }}
                <LoadingSpinner
                  v-if="publisherStore.isLoading"
                  class="ml-3 h-4 w-4 text-white"
                />
              </button>
              <button
                ref="cancelButtonRef"
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                @click="confirmationModalOpen = false"
              >
                {{ t('publisher.user.cancelDelete') }}
              </button>
            </div>
            <ErrorMessages
              v-if="publisherStore.userErrors.length"
              :errors="publisherStore.userErrors"
              class="mt-4"
            />
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, watch } from 'vue'
import { usePublisherStore } from '../../store/publisherStore'
import { useI18n } from 'vue-i18n'
import { TrashIcon } from '@heroicons/vue/20/solid'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import List from '../List.vue'
import ErrorMessages from '../ErrorMessages.vue'
import LoadingSpinner from '../LoadingSpinner.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const publisherStore = usePublisherStore()

const updatePageNumber = (number) => {
  publisherStore.usersPage.number = number
  publisherStore.loadUsers()
}

const confirmationModalOpen = ref(false)
const selectedUser = ref(null)
const confirmUserDelete = (user) => {
  selectedUser.value = user
  confirmationModalOpen.value = true
}
const deleteUser = async () => {
  await publisherStore.deleteUser(selectedUser.value)
  if (publisherStore.userErrors.length === 0) {
    confirmationModalOpen.value = false
    selectedUser.value = null
  }
}

// Clear error messages when the modal is opened/closed
watch(confirmationModalOpen, () => {
  publisherStore.userErrors = []
})
</script>
