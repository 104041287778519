<template>
  <div class="px-4 pt-4 text-lg">
    {{ t(`candidates.flowType.${flowTypeSlug}`) }}
  </div>
  <div class="grid grid-cols-12 items-center gap-2 border-b px-4 pt-4 pb-6">
    <div class="col-span-12 flex">
      <label
        :for="flowTypeSlug"
        class="col-span-12 ml-0.5 block text-sm font-medium text-gray-700"
        >{{ t('vacancy.flows.flow') }}</label
      >
    </div>

    <Multiselect
      :id="flowTypeSlug"
      ref="selectObject"
      class="col-span-12 text-sm shadow-sm xl:col-span-6"
      :modelValue="selectedFlow"
      :placeholder="t('vacancy.flows.selectFlow')"
      :filterResults="false"
      :resolveOnLoad="true"
      :delay="500"
      :searchable="true"
      :options="searchFlow"
      :object="true"
      :disabled="false"
      valueProp="id"
      trackBy="name"
      label="name"
      :noOptionsText="t('vacancy.flows.noFlows')"
      inputType="search"
      autocomplete="off"
      @change="handleChange"
    />
    <div class="col-span-12 xl:col-span-2">
      <button
        v-if="selectedFlow"
        type="button"
        class="mt-1 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        @click="editFlow"
      >
        {{ t('vacancy.flows.editFlow') }}
        <ArrowTopRightOnSquareIcon class="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import candidateGateway from '../../api/candidateGateway'
import Multiselect from '@vueform/multiselect'
import { useSettingsStore } from '../../store/settingsStore'
import { useVacancyStore } from '../../store/vacancyStore'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'

const vacancyStore = useVacancyStore()
const settingsStore = useSettingsStore()
const props = defineProps({
  flowTypeSlug: {
    type: String,
    required: true,
  },
})
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const router = useRouter()

const editFlow = () => {
  router.push(`/flows/${selectedFlow.value.id}`)
}

const selectedFlow = computed(() => {
  const flow = vacancyStore.vacancyFlows.find(
    (flow) => flow.flowTypeSlug === props.flowTypeSlug
  )
  return flow
})

const handleChange = (selectedFlow) => {
  if (selectedFlow && selectedFlow.id) {
    vacancyStore.addVacancyFlow(selectedFlow)
  } else {
    vacancyStore.removeVacancyFlow(props.flowTypeSlug)
  }
}

const searchFlow = async (filter = '') => {
  const queryRes = await candidateGateway.getFlows({
    filter: filter || '',
    size: 15,
    flowTypeSlug: props.flowTypeSlug,
    publisherId: settingsStore.activePublisher.id,
  })
  return queryRes.data
}
</script>
