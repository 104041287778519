<template>
  <label class="block text-sm font-medium text-gray-700">
    {{ label }}
  </label>
  <div class="mt-1">
    <div
      class="flex items-center justify-center rounded-md border-2 border-dashed border-gray-300 px-3 py-3"
      :class="{
        'h-44': isCarouselImage,
        'h-64': !isCarouselImage,
      }"
    >
      <div class="space-y-2 text-center" :class="{ 'mt-2': !imageDisplayed }">
        <div
          :class="{
            'mx-auto flex items-center justify-center': imageDisplayed,
            'h-auto': imageDisplayed,
          }"
        >
          <a
            class="flex h-full w-full cursor-zoom-in items-center justify-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            :href="fullSizeUrl"
            target="_blank"
            :title="t('site.fileUploadPreview')"
          >
            <img
              v-if="imageDisplayed"
              :src="selectedImageUrl || imageUrl"
              :class="{
                'max-h-40': isCarouselImage,
                'max-h-56': !isCarouselImage,
              }"
              alt=""
            />
          </a>
          <div v-if="imageDisplayed" class="relative self-start">
            <button
              type="button"
              class="absolute -right-3 -top-3 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              @click="discardImage"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <svg
          v-if="!imageDisplayed"
          class="mx-auto h-16 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div
          v-show="!imageDisplayed"
          class="flex items-center justify-center text-sm text-gray-600"
        >
          <label
            :for="id"
            class="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
          >
            <span>{{
              multiple ? t('site.filesUpload') : t('site.fileUpload')
            }}</span>
            <input
              :id="id"
              ref="imageUploadInput"
              :name="id"
              :multiple="multiple"
              type="file"
              class="sr-only"
              accept="image/*"
              @change="onImageChange"
            />
          </label>
        </div>
        <p
          v-if="recommendation && !imageDisplayed"
          class="text-xs text-gray-500"
        >
          {{ recommendation }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  rules: {
    type: Object,
    default: () => {},
  },
  value: {
    type: String,
    default: '',
  },
  imageUrl: {
    type: String,
    default: '',
  },
  recommendation: {
    type: String,
    default: '',
  },
  isCarouselImage: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['imagechange', 'discardImage'])

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const selectedImageUrl = ref(null)
const imageUploadInput = ref(null)
const imageDisplayed = computed(() => selectedImageUrl.value || props.imageUrl)

const onImageChange = (e) => {
  const selected = props.multiple ? e.srcElement.files : e.srcElement.files[0]

  if (!props.multiple) {
    selectedImageUrl.value = null
    selectedImageUrl.value = URL.createObjectURL(selected)
  }
  emit('imagechange', selected)
}
const discardImage = () => {
  selectedImageUrl.value = null
  imageUploadInput.value.value = null
  emit('discardImage')
}

const rules = computed(() => ({
  [props.id]: props.rules,
}))

const v$ = useVuelidate(rules, {
  [props.id]: computed(() => props.value),
})

const fullSizeUrl = computed(() => {
  if (selectedImageUrl.value) {
    return selectedImageUrl.value
  } else if (props.imageUrl) {
    const url = new URL(props.imageUrl)
    return url.origin + url.pathname
  }
  return ''
})
</script>
