<template>
  <Listbox v-slot="{ open }" v-model="selectedTriggerType" class="col-span-3">
    <div class="relative mt-1">
      <ListboxLabel
        class="flex items-center gap-x-1 text-sm font-medium text-gray-700"
        >{{ t('company.importer.runMode')
        }}<HelpPopover :text="t('company.importer.triggerTypeHelp')"
      /></ListboxLabel>
      <ListboxButton
        class="relative mt-1 min-h-[2.4rem] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
      >
        <span class="block truncate">{{ selectedTriggerType?.label }}</span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronDownIcon
            class="mr-1 h-5 w-5 text-gray-400"
            :class="open ? 'rotate-180' : 'rotate-0'"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leaveActiveClass="transition duration-100 ease-in"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <ListboxOptions
          class="absolute z-[1] max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="mode in importerStore.availableTriggerTypes[
              importerStore.activeConfig.ats
            ]"
            v-slot="{ active, selected }"
            :key="mode.value"
            :value="mode"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900',
                'relative cursor-default select-none px-4 py-2',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
                >{{ mode.label }}</span
              >
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useImporterStore } from '../../store/importerStore'
import HelpPopover from '../HelpPopover.vue'

const importerStore = useImporterStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const selectedTriggerType = computed({
  get() {
    return (
      importerStore.availableTriggerTypes[importerStore.activeConfig.ats].find(
        (mode) => mode.value === importerStore.activeConfig.triggerType,
      ) || {}
    )
  },
  set(newValue) {
    importerStore.activeConfig.triggerType = newValue.value
  },
})
</script>
