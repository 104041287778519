<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <PageHeader
        :title="publisher.name"
        :isLoading="publisherStore.isLoading"
      />

      <TabNavigation storeName="publisher" :tabs="tabs" class="mb-5" />

      <div v-show="activeTab === 'general'">
        <TwoColumnForm title="">
          <ErrorMessages
            v-if="publisherStore.errors.length"
            :errors="publisherStore.errors"
          />
        </TwoColumnForm>
        <TwoColumnForm :title="t('publisher.title')">
          <TwoColumnFormCard><Meta /></TwoColumnFormCard>
        </TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('publisher.salaryScaleGroups')">
          <TwoColumnFormCard><SalaryScaleGroups /></TwoColumnFormCard>
        </TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm title="">
          <FormSave
            :validationErrors="v$.$errors"
            :validationTouch="v$.$touch"
            storeName="publisher"
            storeAction="save"
        /></TwoColumnForm>
      </div>

      <div v-show="activeTab === 'users'">
        <ErrorMessage
          v-if="publisher.id === 'new'"
          :error="t('publisher.saveRequired')"
        />
        <div v-else>
          <TwoColumnForm :title="t('publisher.users')">
            <Users />
          </TwoColumnForm>
          <TwoColumnFormDivider />
          <TwoColumnForm :title="t('publisher.inviteUser')">
            <TwoColumnFormCard><InviteUser /></TwoColumnFormCard>
          </TwoColumnForm>
        </div>
      </div>

      <Subscription
        v-if="activeTab === 'publisherSubscription'"
        principalType="PUBLISHER"
      />
    </div>
  </div>
</template>

<script setup>
import FormSave from '../components/FormSave.vue'
import TwoColumnForm from '../components/TwoColumnForm.vue'
import TwoColumnFormCard from '../components/TwoColumnFormCard.vue'
import TwoColumnFormDivider from '../components/TwoColumnFormDivider.vue'
import ErrorMessages from '../components/ErrorMessages.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import Meta from '../components/publisher/Meta.vue'
import Users from '../components/publisher/Users.vue'
import InviteUser from '../components/publisher/InviteUser.vue'
import Subscription from '../components/subscription/Subscription.vue'
import PageHeader from '../components/PageHeader.vue'
import TabNavigation from '../components/TabNavigation.vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { usePublisherStore } from '../store/publisherStore'
import { useVuelidate } from '@vuelidate/core'
import SalaryScaleGroups from '../components/publisher/SalaryScaleGroups.vue'
import { storeToRefs } from 'pinia'

const publisherStore = usePublisherStore()
const { publisher } = storeToRefs(publisherStore)

const route = useRoute()
const v$ = useVuelidate()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const tabs = computed(() => {
  if (publisher.value?.id !== 'new') {
    return ['general', 'users', 'publisherSubscription']
  } else {
    return ['general']
  }
})

const activeTab = computed(() => {
  return route.name
})
publisherStore.load(route.params.id)
</script>
