<template>
  <div class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6">
      <div class="mb-4">
        <label class="mb-1 block text-sm font-medium text-gray-700">
          {{ t('archived') }}
        </label>
        <Toggle
          :srLabel="t('archived')"
          :checked="publisherStore.publisher.archived"
          :onToggle="onToggle"
        />
      </div>
      <FormInput
        id="publisherName"
        type="text"
        :value="publisherStore.publisher.name"
        :label="t('publisher.name') + '*'"
        :onInput="updateName"
        :rules="nameRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="code"
        type="text"
        :value="publisherStore.publisher.code"
        :label="t('publisher.code') + '*'"
        :onInput="updateCode"
        :rules="codeRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="token"
        type="text"
        :value="publisherStore.publisher.token"
        :label="t('publisher.token') + '*'"
        :onInput="updateToken"
        :rules="tokenRules"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { usePublisherStore } from '../../store/publisherStore'
import { required, helpers } from '@vuelidate/validators'
import Toggle from '../Toggle.vue'
import FormInput from '../FormInput.vue'

const publisherStore = usePublisherStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const nameRules = {
  required: helpers.withMessage(
    t('validations.publisherName.required'),
    required,
  ),
  $autoDirty: true,
}
const updateName = (newName) => {
  publisherStore.publisher.name = newName
}

const codeRules = {
  required: helpers.withMessage(
    t('validations.publisherCode.required'),
    required,
  ),
  $autoDirty: true,
}
const updateCode = (value) => {
  publisherStore.publisher.code = value
}

const tokenRules = {
  required: helpers.withMessage(
    t('validations.publisherToken.required'),
    required,
  ),
  $autoDirty: true,
}
const updateToken = (value) => {
  publisherStore.publisher.token = value
}
const onToggle = () => {
  publisherStore.publisher.archived = !publisherStore.publisher.archived
}
</script>
