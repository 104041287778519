<template>
  <div class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6">
      <FormInput
        id="fromEmail"
        type="email"
        :value="siteStore.site.fromEmail"
        :label="t('site.fromEmail') + '*'"
        :onInput="updateFromEmail"
        :rules="fromEmailRules"
      />
    </div>
    <div class="col-span-6">
      <ColorSchemeInput
        :scheme="siteStore.site.colorScheme || ''"
        @update-scheme="updateColorScheme"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="sendgridTemplateIdActivateAccount"
        type="text"
        :value="siteStore.site.sendgridTemplateIdActivateAccount"
        :label="t('site.sendgridTemplateIdActivateAccount')"
        :onInput="updateSendgridTemplateIdActivateAccount"
        :rules="sendgridTemplateIdActivateAccountRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="sendgridTemplateIdJobalert"
        type="text"
        :value="siteStore.site.sendgridTemplateIdJobalert"
        :label="t('site.sendgridTemplateIdJobalert')"
        :onInput="updateSendgridTemplateIdJobalert"
        :rules="sendgridTemplateIdJobalertRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="sendgridTemplateIdJobalertConfirmation"
        type="text"
        :value="siteStore.site.sendgridTemplateIdJobalertConfirmation"
        :label="t('site.sendgridTemplateIdJobalertConfirmation')"
        :onInput="updateSendgridTemplateIdJobalertConfirmation"
        :rules="sendgridTemplateIdJobalertConfirmationRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="sendgridTemplateIdShareVacancy"
        type="text"
        :value="siteStore.site.sendgridTemplateIdShareVacancy"
        :label="t('site.sendgridTemplateIdShareVacancy')"
        :onInput="updateSendgridTemplateIdShareVacancy"
        :rules="sendgridTemplateIdShareVacancyRules"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useSiteStore } from '../../store/siteStore'
import { required, helpers, email } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'
import ColorSchemeInput from './ColorSchemeInput.vue'

const siteStore = useSiteStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const fromEmailRules = {
  required: helpers.withMessage(
    t('validations.siteFromEmail.required'),
    required,
  ),
  email: helpers.withMessage(t('validations.siteFromEmail.email'), email),
  $autoDirty: true,
}
const updateFromEmail = (newValue) => {
  siteStore.site.fromEmail = newValue
}

const sendgridTemplateIdActivateAccountRules = {
  $autoDirty: true,
}
const updateSendgridTemplateIdActivateAccount = (newValue) => {
  siteStore.site.sendgridTemplateIdActivateAccount = newValue
}

const sendgridTemplateIdJobalertRules = {
  $autoDirty: true,
}
const updateSendgridTemplateIdJobalert = (newValue) => {
  siteStore.site.sendgridTemplateIdJobalert = newValue
}

const sendgridTemplateIdJobalertConfirmationRules = {
  $autoDirty: true,
}
const updateSendgridTemplateIdJobalertConfirmation = (newValue) => {
  siteStore.site.sendgridTemplateIdJobalertConfirmation = newValue
}

const sendgridTemplateIdShareVacancyRules = {
  $autoDirty: true,
}
const updateSendgridTemplateIdShareVacancy = (newValue) => {
  siteStore.site.sendgridTemplateIdShareVacancy = newValue
}

const updateColorScheme = (value) => {
  siteStore.site.colorScheme = value
}
</script>
