<template>
  <div class="grid grid-cols-7 items-center gap-4">
    <div class="col-span-7 sm:col-span-3">
      <label for="startDate" class="block text-sm font-medium text-gray-700">{{
        t('vacancy.publication.start')
      }}</label>
      <date-picker
        id="startDate"
        :value="startDate"
        type="dateTime"
        format="D MMM YYYY HH:mm"
        :disabledDate="disabledStartDate"
        :clearable="false"
        :editable="false"
        class="mt-1"
        @change="updateStartDate"
      />
    </div>

    <div v-show="!expirationDateHidden" class="col-span-7 sm:col-span-3">
      <label for="endDate" class="block text-sm font-medium text-gray-700">{{
        t('vacancy.publication.end')
      }}</label>
      <date-picker
        id="endDate"
        :value="endDate"
        type="dateTime"
        format="D MMM YYYY HH:mm"
        :disabledDate="disabledEndDate"
        :clearable="false"
        :editable="false"
        class="mt-1"
        @change="updateEndDate"
      />
    </div>
    <div v-show="!expirationDateHidden" class="col-span-2 sm:col-span-1">
      <label for="days" class="block text-sm font-medium text-gray-700">{{
        t('vacancy.publication.days')
      }}</label>
      <input
        id="days"
        type="number"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        :value="days"
        :min="minDays"
        @change="(e) => updateDays(parseInt(e.target.value) - 1)"
      />
    </div>
    <div class="col-span-3">
      <div class="relative mt-5 flex items-start">
        <div class="flex h-6 items-center">
          <input
            id="no-expiration"
            aria-describedby="no-expiration-date"
            name="no-expiration"
            type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            :checked="expirationDateHidden"
            @change="toggleExpiration"
          />
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="no-expiration" class="font-normal text-gray-900">{{
            t('vacancy.publication.noExpirationDate')
          }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-6 grid grid-cols-6 gap-4">
    <PublicationTag
      v-for="tagGroup in siteTags"
      :key="tagGroup.id"
      :tagGroup="tagGroup"
      :selectedTags="publication.tags[tagGroup.id] || []"
      :siteId="publication.siteId"
    />
  </div>
  <PublicationApplicationUrl
    class="mt-6"
    :applicationUrl="publication[`${publication.scope}ApplicationUrl`]"
    :siteId="publication.siteId"
  />
</template>

<script setup>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import PublicationApplicationUrl from './PublicationApplicationUrl.vue'
import { useVacancyStore } from '../../store/vacancyStore'
import { useSettingsStore } from '../../store/settingsStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import PublicationTag from './PublicationTag.vue'

import {
  endOfDay,
  isBefore,
  addDays,
  startOfDay,
  addMonths,
  differenceInDays,
} from 'date-fns'

const props = defineProps({
  publication: {
    type: Object,
    required: true,
  },
  siteTags: {
    type: Array,
    required: true,
  },
})

const vacancyStore = useVacancyStore()
const settingsStore = useSettingsStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

DatePicker.locale(settingsStore.locale)

const startDate = computed(() => {
  const date = props.publication[`${props.publication.scope}StartDate`]
  return date || null
})

const endDate = computed(() => {
  const date = props.publication[`${props.publication.scope}EndDate`]
  return date || null
})

const expirationDateHidden = computed(() => {
  return endDate.value > new Date(2999, 11, 1)
})

const days = computed(() => {
  return differenceInDays(endDate.value, startDate.value) + 1
})
const minDays = computed(() => {
  return differenceInDays(Date.now(), startDate.value) + 1
})

const updateDateRange = (newStartDate, newEndDate) => {
  const publication = vacancyStore.publications.find(
    (p) => p.siteId === props.publication.siteId,
  )
  publication[`${publication.scope}StartDate`] = newStartDate
  publication[`${publication.scope}EndDate`] = newEndDate
}

const toggleExpiration = () => {
  //All  publications with 'magic date' >= 2999-12-01 will appear without endDate on our sites.
  //If you reading this comment in 2999, this will require new solution
  //When disabling no ending publication, reset endDate to 1 month from now.
  if (!expirationDateHidden.value) {
    updateDateRange(startDate.value, new Date(2999, 11, 30))
  } else {
    updateDateRange(startDate.value, addMonths(new Date(), 1))
  }
}

const updateEndDate = (newEndDate) => {
  if (newEndDate) {
    updateDateRange(startDate.value, endOfDay(newEndDate))
  }
}
const updateStartDate = (newStartDate) => {
  updateDateRange(
    newStartDate,
    isBefore(newStartDate, endDate.value)
      ? endDate.value
      : endOfDay(newStartDate),
  )
}

const updateDays = (newDays) => {
  updateDateRange(startDate.value, endOfDay(addDays(startDate.value, newDays)))
}

const disabledStartDate = (date) => isBefore(date, startOfDay(new Date()))

const disabledEndDate = (date) => isBefore(date, startDate.value)
</script>
<style>
.mx-datepicker {
  width: 100%;
}
.mx-datepicker .mx-input {
  height: 38px;
}
</style>
