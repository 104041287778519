import {
  CheckCircleIcon,
  XCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
  MinusCircleIcon,
  EyeSlashIcon,
} from '@heroicons/vue/24/outline'

export const possibleStates = [
  { label: '' },
  {
    label: 'Successful',
    icon: CheckCircleIcon,
    color: 'text-green-500',
  },
  {
    label: 'Failure',
    icon: ExclamationCircleIcon,
    color: 'text-red-500',
  },
  { label: 'Without flow', icon: EyeSlashIcon, color: 'text-sky-800' },
  {
    label: 'Pending',
    icon: EllipsisHorizontalCircleIcon,
    color: 'text-blue-500',
  },
  {
    label: 'Processing',
    icon: EllipsisHorizontalCircleIcon,
    color: 'text-blue-500',
  },
  {
    label: 'Skipped',
    icon: MinusCircleIcon,
    color: 'text-gray-500',
  },
  {
    label: 'Temporary failure',
    icon: XCircleIcon,
    color: 'text-red-500',
  },
]
