<template>
  <div class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6 xl:col-span-3">
      <ColorShadesInput
        id="primaryColors"
        :label="t('site.theme.primaryColor') + '*'"
        :modelValue="primaryColors"
        @update:model-value="(colors) => updateThemeColors(colors, 'primary')"
      />
    </div>
    <div class="col-span-6 xl:col-span-3">
      <ColorShadesInput
        id="secondaryColors"
        :label="t('site.theme.secondaryColor')"
        :modelValue="secondaryColors"
        @update:model-value="(colors) => updateThemeColors(colors, 'secondary')"
      />
    </div>
    <div class="col-span-6 xl:col-span-3">
      <ColorShadesInput
        id="tertiaryColors"
        :label="t('site.theme.tertiaryColor')"
        :modelValue="tertiaryColors"
        @update:model-value="(colors) => updateThemeColors(colors, 'tertiary')"
      />
    </div>
    <div class="col-span-6 xl:col-span-3">
      <ColorShadesInput
        id="fourthColors"
        :label="t('site.theme.fourthColor')"
        :modelValue="fourthColors"
        @update:model-value="(colors) => updateThemeColors(colors, 'fourth')"
      />
    </div>
    <div class="col-span-6 xl:col-span-3">
      <ColorShadesInput
        id="grayColors"
        :label="t('site.theme.grayColor') + '*'"
        :modelValue="grayColors"
        @update:model-value="(colors) => updateThemeColors(colors, 'gray')"
      />
    </div>
    <div class="col-span-6">
      <FontInput
        id="headingFont"
        :label="t('site.theme.headingFont')"
        :modelValue="headingFont"
        :canClear="false"
        @update:model-value="(newFont) => updateFont(newFont, 'heading')"
      />
    </div>
    <div class="col-span-6">
      <label
        for="headingFontWeight"
        class="block text-sm font-medium text-gray-700"
        >{{ t('site.theme.headingFontWeight') }}</label
      >
      <select
        id="headingFontWeight"
        :value="headingFontWeight"
        name="headingFontWeight"
        class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        @change="(event) => updateFontWeight(event.target.value)"
      >
        <option value=""></option>
        <option v-for="index in 9" :key="index" :value="index * 100">
          {{ index * 100 }}
        </option>
      </select>
    </div>
    <div class="col-span-6">
      <FontInput
        id="subHeadingFont"
        :label="t('site.theme.subHeadingFont')"
        :modelValue="subHeadingFont"
        :canClear="false"
        @update:model-value="(newFont) => updateFont(newFont, 'subheading')"
      />
    </div>
    <div class="col-span-6">
      <FontInput
        id="contentFont"
        :label="t('site.theme.contentFont')"
        :modelValue="contentFont"
        :canClear="false"
        @update:model-value="(newFont) => updateFont(newFont, 'content')"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useSiteStore } from '../../store/siteStore'
import { computed } from 'vue'
import FontInput from '../FontInput.vue'
import ColorShadesInput from './ColorShadesInput.vue'

const siteStore = useSiteStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const primaryColors = computed(() =>
  extractColorsFromTheme(siteStore.site.theme, 'primary'),
)
const secondaryColors = computed(() =>
  extractColorsFromTheme(siteStore.site.theme, 'secondary'),
)
const tertiaryColors = computed(() =>
  extractColorsFromTheme(siteStore.site.theme, 'tertiary'),
)
const fourthColors = computed(() =>
  extractColorsFromTheme(siteStore.site.theme, 'fourth'),
)
const grayColors = computed(() =>
  extractColorsFromTheme(siteStore.site.theme, 'gray'),
)

const extractColorsFromTheme = (themeObject, colorPrefix) => {
  const colors = []

  if (themeObject) {
    for (let i = 0; i < 9; i++) {
      const propertyName = `--${colorPrefix}-${100 * (i + 1)}`
      colors.push(themeObject[propertyName] || '')
    }
  }

  return colors
}

const updateThemeColors = (colors, colorPrefix) => {
  const themeCopy = { ...siteStore.site.theme }
  for (let i = 0; i < 9; i++) {
    const propertyName = `--${colorPrefix}-${100 * (i + 1)}`
    themeCopy[propertyName] = colors[i]
  }
  updateTheme(themeCopy)
}

// Remove the enclosing "" before showing the fonts
const headingFont = computed(() =>
  siteStore.site.theme['--heading'].slice(1, -1),
)
const subHeadingFont = computed(() =>
  siteStore.site.theme['--subheading'].slice(1, -1),
)
const contentFont = computed(() =>
  siteStore.site.theme['--content'].slice(1, -1),
)
const headingFontWeight = computed(() =>
  siteStore.site.theme ? siteStore.site.theme['--heading-weight'] : null,
)

const updateFont = (newValue, fontPropertyName) => {
  const themeCopy = { ...siteStore.site.theme }
  // Add enclosing "" when saving to the store
  themeCopy[`--${fontPropertyName}`] = `"${newValue}"`
  updateTheme(themeCopy)
}
const updateFontWeight = (newValue) => {
  const themeCopy = { ...siteStore.site.theme }
  themeCopy['--heading-weight'] = newValue
  updateTheme(themeCopy)
}

const updateTheme = (value) => {
  siteStore.site.theme = value
}
</script>
