<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.subscriptions')"
        :isLoading="subscriptionListStore.isLoading"
        :enableSearch="false"
      />

      <ErrorMessage
        v-if="subscriptionListStore.error"
        :error="subscriptionListStore.error"
      />

      <SubscriptionsFilter />

      <List
        v-slot="{ entity: subscription }"
        :entities="subscriptionListStore.subscriptions"
        :page="subscriptionListStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
      >
        <router-link
          :to="`/${subscription.principalType === 'COMPANY' ? 'companies' : 'publishers'}/${subscription.principalId}/subscription?subscriptionId=${subscription.id}`"
          class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
        >
          <div
            class="flex flex-col px-4 py-4 sm:px-6 lg:flex-row lg:items-center"
          >
            <div
              class="flex min-w-0 flex-1 flex-col lg:flex-row lg:items-center"
            >
              <div class="min-w-0 md:grid md:grid-cols-4 md:gap-4 lg:w-2/5">
                <div class="flex flex-col justify-center md:col-span-3">
                  <p class="truncate text-sm font-medium">
                    {{ subscription.principalName }}
                  </p>
                  <p class="text-xs capitalize text-gray-500">
                    {{ subscription.principalType.toLowerCase() }}
                  </p>
                </div>
              </div>
              <div class="text-gray-700 lg:w-3/5">
                <p
                  v-for="feature in subscription.features"
                  :key="feature.id"
                  class="pr-4 text-xs"
                >
                  {{ t(`subscriptions.${feature.slug}`) }}
                  {{
                    feature.resources
                      .map((resource) => resource.name)
                      .join(', ')
                  }}
                  -
                  <span class="font-bold">
                    {{ feature.usedCredits }}/{{
                      feature.planCredits || '∞'
                    }}</span
                  >&nbsp;
                  <span class="text-gray-500">
                    {{ t('subscriptions.creditsUsed') }}
                  </span>
                </p>
              </div>
            </div>
            <div
              class="ml-auto flex items-center justify-between lg:ml-0 lg:w-52"
            >
              <div class="flex flex-col">
                <div>
                  <span
                    class="mr-2 inline-flex items-center rounded-full border px-2.5 py-px text-xs font-medium capitalize text-gray-700"
                    :class="{
                      'border-2 border-dashed border-gray-300 bg-gray-100':
                        subscription.plan === 'NONE',
                      'border-gray-300 bg-gray-100':
                        subscription.plan === 'START',
                      'border-sky-300 bg-sky-100':
                        subscription.plan === 'BASIC',
                      'border-yellow-300 bg-yellow-100':
                        subscription.plan === 'PLUS',
                      'border-orange-300 bg-orange-100':
                        subscription.plan === 'PREMIUM',
                      'border-red-300 bg-red-100':
                        subscription.plan === 'TEST_TRIAL',
                    }"
                  >
                    {{ t(`subscriptions.${subscription.plan}`) }}
                  </span>
                  <span
                    class="text-xs font-medium"
                    :class="
                      subscription.state === 'ACTIVATED'
                        ? 'text-green-500'
                        : 'text-gray-400'
                    "
                    >{{ subscription.state.toLowerCase() }}</span
                  >
                </div>
                <span class="text-xs text-gray-500"
                  >{{ format(subscription.startDate, 'dd/MM/yyyy') }}-{{
                    format(subscription.endDate, 'dd/MM/yyyy')
                  }}</span
                >
              </div>

              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import { RouterLink } from 'vue-router'
import ErrorMessage from '../components/ErrorMessage.vue'
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { useSubscriptionListStore } from '../store/subscriptionListStore'
import SubscriptionsFilter from '../components/subscription/SubscriptionsFilter.vue'

const subscriptionListStore = useSubscriptionListStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const updatePageSize = (pageSize) => {
  subscriptionListStore.page.size = pageSize
  subscriptionListStore.load()
}
const updatePageNumber = (number) => {
  subscriptionListStore.page.number = number
  subscriptionListStore.load()
}
</script>
