import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'

export const useJobAlertListStore = defineStore('jobAlertListStore', {
  state: () => ({
    isLoading: false,
    isDeletedSuccessfully: false,
    error: null,
    jobAlerts: [],
    filter: '',
    sortBy: 'id',
    sortOrder: 'desc',
    includeArchived: false,
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load() {
      this.error = null
      this.isDeletedSuccessfully = false
      this.isLoading = true

      try {
        const params = {
          email: this.filter,
          page: this.page.number,
          size: this.page.size,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
        }
        const jobAlerts = await tooManagement.getJobAlerts(params)

        if (jobAlerts.meta.page.number === this.page.number) {
          this.jobAlerts = jobAlerts.data
          this.page = jobAlerts.meta.page
        }
      } catch (error) {
        this.jobAlerts = []
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async removeJobAlerts(selected) {
      this.isLoading = true
      this.isDeletedSuccessfully = false
      this.error = null
      const toRemove = []
      selected.forEach((value) => {
        toRemove.push(value)
      })
      try {
        await tooManagement.deleteJobAlerts({ value: toRemove })
        await this.load()
        this.isDeletedSuccessfully = true
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
  },
})
