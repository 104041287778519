<template>
  <div class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6 lg:col-span-3">
      <FormInput
        id="tagLabel"
        type="text"
        :value="tagLabel"
        :label="t('site.tags.tagLabel')"
        :onInput="updateTagLabel"
        :rules="nameRequired"
      />
    </div>
    <div class="col-span-6 lg:col-span-3">
      <FormInput
        id="tagKey"
        type="text"
        :value="tagKey"
        :label="t('site.tags.tagKey')"
        :onInput="updateTagKey"
        :rules="nameRequired"
      />
    </div>

    <div class="col-span-6 flex flex-row-reverse items-center">
      <button
        type="button"
        class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        :disabled="siteStore.isLoading"
        @click="addTag"
      >
        {{ t('site.tags.newTag') }}
        <LoadingSpinner
          v-if="siteStore.isLoading || siteStore.isSaving"
          class="ml-3 h-4 w-4 text-white"
        />
      </button>
      <SuccessLabel
        v-if="!siteStore.isLoading && siteStore.isSavedSuccessfully"
        :label="t('site.saved')"
        class="mx-4"
      />
    </div>
    <div v-if="siteStore.tagErrors.length" class="col-span-6">
      <ErrorMessages :errors="siteStore.tagErrors" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import ErrorMessages from '../ErrorMessages.vue'
import LoadingSpinner from '../LoadingSpinner.vue'
import FormInput from '../FormInput.vue'
import SuccessLabel from '../SuccessLabel.vue'
import { useSiteStore } from '../../store/siteStore'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const nameRequired = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const route = useRoute()
const siteStore = useSiteStore()

const tagLabel = ref('')
const tagKey = ref('')
const rules = {
  tagLabel: nameRequired,
  tagKey: nameRequired,
}
const v$ = useVuelidate(rules, { tagLabel, tagKey }, { $stopPropagation: true })
const updateTagLabel = (newName) => {
  tagLabel.value = newName
}
const updateTagKey = (newName) => {
  tagKey.value = newName
}

const addTag = async () => {
  v$.value.$touch()
  if (!v$.value.$error) {
    await siteStore.addTag(
      {
        label: tagLabel.value,
        key: tagKey.value,
      },
      route.params.tagGroupId
    )

    v$.value.$reset()
    tagLabel.value = ''
    tagKey.value = ''
  }
}
</script>
