import { defineStore } from 'pinia'
import candidateGateway from '../api/candidateGateway'

export const useCandidateListStore = defineStore('candidateListStore', {
  state: () => ({
    isLoading: false,
    error: null,
    candidates: [],
    filter: '',
    flowTypeFilter: '',
    executionStateFilter: { label: '' },
    dateRangeFilter: [null, null],
    statistics: {},
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async loadCandidates(
      vacancyId,
      flowType,
      executionState,
      dateRange = [null, null],
    ) {
      this.isLoading = true
      this.error = null
      try {
        if (vacancyId === 'new') {
          this.isLoading = false
          return
        }

        const queryParams = {
          page: this.page.number,
          size: this.page.size,
          sortBy: 'applicationDate',
          sortOrder: 'desc',
        }

        if (vacancyId) {
          queryParams.vacancyId = vacancyId
        }
        if (this.filter) {
          queryParams.filter = this.filter
        }
        if (flowType) {
          queryParams.flowTypeSlug = flowType
        }
        if (executionState) {
          if (executionState === 'Without flow') {
            queryParams.notAssignedToFlow = true
          } else {
            queryParams.latestFlowExecutionState = executionState
          }
        }
        if (dateRange[0] && dateRange[1]) {
          queryParams.startDate =
            new Date(dateRange[0]).toISOString().split('.')[0] + 'Z'
          queryParams.endDate =
            new Date(dateRange[1]).toISOString().split('.')[0] + 'Z'
        }

        const candidatesResponse =
          await candidateGateway.getCandidates(queryParams)
        this.candidates = candidatesResponse.data
        this.page = candidatesResponse.meta.page
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },

    async loadCandidatesStatistics(
      vacancyId,
      executionState,
      dateRange = [null, null],
      excludeTestCandidates = true,
      excludeDuplicateCandidates = true,
    ) {
      this.isLoading = true
      this.error = null
      try {
        if (vacancyId === 'new' || !vacancyId) {
          this.isLoading = false
          return
        }

        const queryParams = {
          vacancyId,
        }

        if (executionState) {
          queryParams.latestFlowExecutionState = executionState
        }
        if (excludeTestCandidates) {
          queryParams.excludeTestCandidates = excludeTestCandidates
        }
        if (excludeDuplicateCandidates) {
          queryParams.excludeDuplicateCandidates = excludeDuplicateCandidates
        }
        if (dateRange[0] && dateRange[1]) {
          queryParams.startDate =
            new Date(dateRange[0]).toISOString().split('.')[0] + 'Z'
          queryParams.endDate =
            new Date(dateRange[1]).toISOString().split('.')[0] + 'Z'
        }

        const statistics =
          await candidateGateway.getCandidatesStatistics(queryParams)
        this.statistics = statistics.data
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },

    async replayCandidates(vacancyId, candidatesIds) {
      this.isLoading = true
      this.error = null
      try {
        await candidateGateway.replayCandidates(Object.values(candidatesIds))
        await this.loadCandidates(
          vacancyId,
          this.flowTypeFilter,
          this.executionStateFilter.label,
          this.dateRangeFilter,
        )
      } catch (error) {
        this.isLoading = false
        this.error = error
      }
    },

    async updateCandidateStatus(candidates, status, value) {
      this.isLoading = true
      this.error = null
      const candidatesList = candidates.map((candidateId) => ({
        id: candidateId,
        [status]: value,
      }))
      try {
        const result =
          await candidateGateway.updateCandidateStatus(candidatesList)
        if (result.data) {
          result.data.forEach((updatedCandidate) => {
            const existingCandidateIndex = this.candidates.findIndex(
              (candidate) => candidate.id === updatedCandidate.id,
            )
            if (existingCandidateIndex !== -1) {
              this.candidates[existingCandidateIndex] = { ...updatedCandidate }
            }
          })
        }
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  },
})
