<template>
  <div class="grid grid-cols-6 items-start gap-6">
    <div class="col-span-6">
      <label class="mb-1 block text-sm font-medium text-gray-700">{{
        t('site.active')
      }}</label>
      <Toggle
        srLabel="Site"
        :checked="siteStore.site.active"
        :onToggle="onToggle"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="siteName"
        type="text"
        :value="siteStore.site.name"
        :label="t('site.name') + '*'"
        :onInput="updateName"
        :rules="nameRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="siteUrl"
        type="text"
        :value="siteStore.site.siteUrl"
        :label="t('site.siteUrl')"
        :onInput="updateSiteUrl"
        :rules="siteUrlRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="siteSlug"
        type="text"
        :value="siteStore.site.siteSlug"
        :label="t('site.siteSlug')"
        :onInput="updateSiteSlug"
        :rules="siteSlugRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="vacancyLinkFormat"
        type="text"
        :value="siteStore.site.vacancyLinkFormat"
        :label="t('site.vacancyLinkFormat')"
        :onInput="updateVacancyLinkFormat"
        :rules="vacancyLinkFormatRules"
        :helpPopoverText="`You can use field replacements like {{vacancy.title}}. {{vacancy.applicationUrl}} or {{vacancy.company.name}}. All vacancy and company fields are available, use snakeCase for the field names`"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="companyLinkFormat"
        type="text"
        :value="siteStore.site.companyLinkFormat"
        :label="t('site.companyLinkFormat')"
        :onInput="updateCompanyLinkFormat"
        :rules="companyLinkFormatRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="code"
        type="text"
        :value="siteStore.site.code"
        :label="t('site.code') + '*'"
        :onInput="updateCode"
        :rules="codeRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="token"
        type="text"
        :value="siteStore.site.token"
        :label="t('site.token') + '*'"
        :onInput="updateToken"
        :rules="tokenRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="gtmId"
        type="text"
        :value="siteStore.site.googleTagManagerId"
        :label="t('site.googleTagManagerId')"
        :helpPopoverText="t('site.googleTagManagerHelpText')"
        :onInput="updateGtmId"
        :rules="gtmIdRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="daysExpiredVacancyVisible"
        type="number"
        :value="siteStore.site.daysExpiredVacancyVisible"
        :label="t('site.daysExpiredVacancyVisible')"
        :onInput="updateDaysExpiredVacancyVisible"
        :rules="daysExpiredVacancyVisibleRules"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useSiteStore } from '../../store/siteStore'
import { required, helpers, url } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'
import Toggle from '../Toggle.vue'
import toKebabCase from 'lodash.kebabcase'

const siteStore = useSiteStore()

const trimProtocol = (url) => url.replace(/https?:\/\//, '')

const baseVacancyLinkFormat = '/{{vacancy.id}}/{{vacancy.slug}}'
const baseCompanyLinkFormat = '/{{company.slug}}'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const nameRules = {
  required: helpers.withMessage(t('validations.siteName.required'), required),
  $autoDirty: true,
}
const updateName = (newName) => {
  siteStore.site.name = newName
}

const onToggle = () => {
  siteStore.site.active = !siteStore.site.active
}

const siteSlugRules = {
  $autoDirty: true,
}

const updateSiteSlug = (newSlug) => {
  siteStore.site.siteSlug = newSlug
}

const siteUrlRules = {
  url: helpers.withMessage(t('validations.siteUrl.url'), url),
  $autoDirty: true,
}
const updateSiteUrl = (newUrl) => {
  siteStore.site.siteSlug = toKebabCase(trimProtocol(newUrl))
  siteStore.site.vacancyLinkFormat = `${newUrl}${baseVacancyLinkFormat}`
  siteStore.site.companyLinkFormat = `${newUrl}${baseCompanyLinkFormat}`
  siteStore.site.siteUrl = newUrl
}

const vacancyLinkFormatRules = {
  $autoDirty: true,
}

const updateVacancyLinkFormat = (value) => {
  siteStore.site.vacancyLinkFormat = value
}

const companyLinkFormatRules = {
  $autoDirty: true,
}

const updateCompanyLinkFormat = (value) => {
  siteStore.site.companyLinkFormat = value
}

const codeRules = {
  required: helpers.withMessage(t('validations.siteCode.required'), required),
  $autoDirty: true,
}

const gtmIdRules = {
  $autoDirty: true,
}

const updateCode = (value) => {
  siteStore.site.code = value
}

const updateGtmId = (value) => {
  siteStore.site.googleTagManagerId = value
}

const tokenRules = {
  required: helpers.withMessage(t('validations.siteToken.required'), required),
  $autoDirty: true,
}

const updateToken = (value) => {
  siteStore.site.token = value
}

const daysExpiredVacancyVisibleRules = {
  $autoDirty: true,
}

const updateDaysExpiredVacancyVisible = (value) => {
  siteStore.site.daysExpiredVacancyVisible = value
}
</script>
