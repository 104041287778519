<template>
  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6">
      <FormInput
        id="companyName"
        type="text"
        :value="companyStore.company.name"
        :label="t('company.name') + '*'"
        :onInput="updateName"
        :rules="nameRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="companySlug"
        type="text"
        :value="companyStore.company.slug"
        :label="t('company.slug')"
        :onInput="updateSlug"
        :rules="slugRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="companyWebsiteUrl"
        type="text"
        :value="companyStore.company.websiteUrl"
        :label="t('company.websiteUrl')"
        :onInput="updateWebsiteUrl"
        :rules="websiteUrlRules"
      />
    </div>
    <div class="col-span-6">
      <FormInput
        id="companyphone"
        type="text"
        :value="companyStore.company.phone"
        :label="t('company.phone')"
        :onInput="updatePhone"
        :rules="phoneRules"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useCompanyStore } from '../../store/companyStore'
import { required, helpers, url } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'
import toKebabCase from 'lodash.kebabcase'

const companyStore = useCompanyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const nameRules = {
  required: helpers.withMessage(
    t('validations.companyName.required'),
    required,
  ),
  $autoDirty: true,
}

const updateName = (newValue) => {
  companyStore.company.slug = toKebabCase(newValue)
  companyStore.company.name = newValue
}

const slugRules = {
  $autoDirty: true,
}

const updateSlug = (newValue) => {
  companyStore.company.slug = newValue
}

const websiteUrlRules = {
  url: helpers.withMessage(t('validations.companyWebsiteUrl.url'), url),
  $autoDirty: true,
}

const updateWebsiteUrl = (newValue) => {
  companyStore.company.websiteUrl = newValue
}

const phoneRules = {
  $autoDirty: true,
}

const updatePhone = (newValue) => {
  companyStore.company.phone = newValue
}
</script>
