<template>
  <div>
    <List
      v-slot="{ entity: event }"
      :entities="importerStore.events"
      :page="importerStore.page"
      :updatePageNumber="updatePageNumber"
      :updatePageSize="updatePageSize"
    >
      <router-link
        :to="{
          name: 'eventDetail',
          params: { id: route.params.id, eventId: event.id },
          query: { ...route.query },
        }"
        class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
      >
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center">
            <div class="flex w-44 flex-col gap-2">
              <span
                class="-ml-1 inline-flex h-fit w-fit rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800"
              >
                {{ timeAgo.format(new Date(event.dateCreated)) }}
              </span>

              <p class="flex items-center text-xs text-gray-500">
                {{ toLocalDateTime(new Date(event.dateCreated)) }}
              </p>
            </div>

            <div class="flex grow justify-between">
              <p class="font-base text-sm text-gray-800">
                <span v-if="event.error" class="text-red-700">
                  {{ event.error }}</span
                >
                <template v-else>
                  <span class="font-medium text-green-500">{{
                    event.vacancyEvents.created.length
                  }}</span>
                  {{
                    t(
                      'company.events.createdVacancies',
                      event.vacancyEvents.created.length,
                    )
                  }},
                  <span class="font-medium text-blue-500">{{
                    event.vacancyEvents.updated.length
                  }}</span>
                  {{
                    t(
                      'company.events.updatedVacancies',
                      event.vacancyEvents.updated.length,
                    )
                  }},
                  <span class="font-medium text-red-500">{{
                    event.vacancyEvents.archived.length
                  }}</span>
                  {{
                    t(
                      'company.events.archivedVacancies',
                      event.vacancyEvents.archived.length,
                    )
                  }}
                </template>
              </p>
            </div>

            <div class="flex justify-end">
              <span class="mr-2 rounded-full bg-indigo-100 px-2 py-1 text-xs">{{
                atsName(event)
              }}</span>
              <component
                :is="statusToDisplay(event).icon"
                class="w-6 fill-white"
                :class="statusToDisplay(event).color"
              />
            </div>
          </div>
        </div>
      </router-link>
    </List>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TimeAgo from 'javascript-time-ago'
import { format } from 'date-fns'
import List from '../List.vue'
import { useImporterStore } from '../../store/importerStore'
import { possibleStates } from '../../utils/vacancyImportStates'

const route = useRoute()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const importerStore = useImporterStore()
const timeAgo = new TimeAgo()

if (!importerStore.configs.length) {
  importerStore.loadConfig(route.params.id).then(() => {
    importerStore.loadEvents(route.params.id)
  })
} else {
  importerStore.loadEvents(route.params.id)
}

const toLocalDateTime = (applicationDate) => {
  return format(applicationDate, 'dd MMM yyyy, HH:mm')
}

const statusToDisplay = (event) => {
  return possibleStates.find((state) => state.code === event.status)
}

const atsName = (event) => {
  const configUsed = importerStore.configs.find(
    (config) => config.id === event.importConfigurationId,
  )
  if (configUsed) {
    return importerStore.availableATS.find(
      (item) => item.value === configUsed.ats,
    ).label
  }
}

const updatePageNumber = (number) => {
  importerStore.page.number = number
  importerStore.loadEvents(route.params.id)
}
const updatePageSize = (pageSize) => {
  importerStore.page.size = pageSize
  importerStore.loadEvents(route.params.id)
}
</script>
