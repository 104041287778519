<template>
  <div v-if="activeCandidate" class="mt-4 bg-white shadow sm:rounded-lg">
    <div class="flex flex-wrap justify-between gap-8 px-5 py-5">
      <div class="flex flex-col items-start gap-6 sm:flex-row">
        <span
          v-if="isCandidateInfoAvailable && candidateName"
          class="inline-flex h-20 w-20 items-center justify-center rounded-full bg-indigo-700"
        >
          <span class="text-4xl font-medium leading-none text-white">{{
            candidateInitials
          }}</span>
        </span>
        <span
          v-else
          :class="{
            'h-20 w-20': !activeCandidate.anonymizedDate,
            'h-16 w-16': activeCandidate.anonymizedDate,
          }"
          class="inline-block overflow-hidden rounded-full bg-gray-100"
        >
          <svg
            class="h-full w-full text-indigo-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </span>

        <div
          v-if="!activeCandidate.anonymizedDate"
          class="flex flex-col gap-y-0.5"
        >
          <div class="inline-flex items-center gap-x-1">
            <span
              class="text-lg font-semibold text-gray-900"
              :class="{ 'blur-sm': !isCandidateInfoAvailable }"
            >
              {{ isCandidateInfoAvailable ? candidateName : '******* ******' }}
            </span>

            <span
              v-if="activeCandidate.isTest"
              class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
              >{{ t('candidates.status.test') }}</span
            >
            <span
              v-if="activeCandidate.isDuplicate"
              class="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800"
              >{{ t('candidates.status.duplicate') }}</span
            >
          </div>
          <span
            v-if="activeCandidate.email"
            class="mt-2 flex items-center gap-2 text-sm text-gray-900"
          >
            <EnvelopeIcon class="w-6 text-gray-500" />
            <span>{{ activeCandidate.email }}</span>
          </span>
          <span
            v-if="activeCandidate.phoneNumber"
            class="flex items-center gap-2 text-sm text-gray-900"
          >
            <PhoneIcon class="w-6 text-gray-500" />
            <span>{{ activeCandidate.phoneNumber }}</span>
          </span>
          <span
            v-if="candidateLinkedInUrl"
            class="flex items-center gap-2 text-sm text-gray-900"
          >
            <svg viewBox="0 0 512 512" class="w-6 fill-gray-500">
              <path
                d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z"
              />
            </svg>
            <span>{{ candidateLinkedInUrl }}</span>
          </span>
          <span
            v-if="activeCandidate.residence"
            class="flex items-center gap-2 text-sm text-gray-900"
          >
            <MapPinIcon class="w-6 text-gray-500" />
            <span v-if="activeCandidate.residence.postalCode"
              >{{ activeCandidate.residence.postalCode }},
              {{ activeCandidate.residence?.city }}</span
            >
            <span v-else>{{ activeCandidate.residence?.city }}</span>
          </span>
        </div>
        <div v-if="activeCandidate.anonymizedDate">
          <div
            class="mr-1 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800"
          >
            {{ t('candidates.anonymized') }}
          </div>
          <span
            v-if="activeCandidate.isTest"
            class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
            >{{ t('candidates.status.test') }}</span
          >
          <span
            v-if="activeCandidate.isDuplicate"
            class="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800"
            >{{ t('candidates.status.duplicate') }}</span
          >

          <div class="mt-2 text-sm">
            {{ toLocalDateTime(new Date(activeCandidate.anonymizedDate)) }}
          </div>
        </div>
      </div>

      <div class="ml-2 flex flex-col gap-1 text-sm text-gray-900 lg:ml-auto">
        <div
          class="inline-flex items-center justify-start gap-2 text-xs text-gray-800 lg:justify-end"
        >
          <div class="text-xs text-gray-600">
            {{ toLocalDateTime(new Date(activeCandidate.applicationDate)) }}
          </div>
          <span class="rounded-full bg-gray-100 px-2 font-semibold leading-5">{{
            timeAgo.format(new Date(activeCandidate.applicationDate))
          }}</span>
        </div>
        <div
          v-if="daysToAnonymized >= 0 && !activeCandidate.anonymizedDate"
          class="mr-1 text-xs text-gray-600 lg:ml-auto"
        >
          <span v-if="daysToAnonymized > 0" class="font-bold"
            >{{ daysToAnonymized }}
            {{ t('candidates.anonymization.days') }}</span
          >
          <span v-else>{{
            t('candidates.anonymization.lessThan24Hours')
          }}</span>
          {{ t('candidates.anonymization.untilAnonymization') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCandidateStore } from '../../../store/candidateStore'
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from '@heroicons/vue/20/solid'
import TimeAgo from 'javascript-time-ago'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { format, differenceInDays } from 'date-fns'

const candidateStore = useCandidateStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const timeAgo = new TimeAgo()

const activeCandidate = computed(() => candidateStore.candidate)
const candidateName = computed(() => {
  if (activeCandidate.value.fullName) {
    return activeCandidate.value.fullName
  } else if (
    activeCandidate.value.firstName &&
    activeCandidate.value.lastName
  ) {
    return `${activeCandidate.value?.firstName} ${activeCandidate.value?.lastName}`
  } else {
    return ''
  }
})
const candidateLinkedInUrl = computed(() => {
  const customLinkedInQuestion = activeCandidate.value?.customFields?.find(
    (question) =>
      typeof question.answer === 'string' &&
      question.answer.toLowerCase().includes('linkedin'),
  )

  return (
    customLinkedInQuestion?.answer ||
    activeCandidate.value.linkedInProfileUrl ||
    ''
  )
})

const daysToAnonymized = computed(() => {
  return differenceInDays(
    new Date(activeCandidate.value.endConsentDate),
    new Date(),
  )
})
const isCandidateInfoAvailable = computed(() => candidateName.value[0] !== '*')
const candidateInitials = computed(() => {
  const nameParts = candidateName.value.split(' ')
  if (nameParts.length > 1) {
    return `${nameParts[0][0]}${nameParts[1][0]}`
  } else {
    return `${candidateName.value.substr(0, 2)}`
  }
})
const toLocalDateTime = (applicationDate) => {
  return applicationDate ? format(applicationDate, 'dd MMM yyyy, HH:mm') : ''
}
</script>
