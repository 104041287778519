<template>
  <div
    v-if="parsedErrors.length"
    class="mb-4 flex flex-col justify-center space-y-4 rounded-md bg-red-50 p-4"
  >
    <div v-for="error in parsedErrors">
      <div class="flex items-center space-x-2">
        <div class="shrink-0">
          <!-- Heroicon name: solid/x-circle -->
          <svg
            class="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div>
          <h3 class="text-sm font-medium text-red-800">
            <a v-if="error.inputId" class="underline" :href="error.inputId">
              {{ error.message }}
            </a>
            <span v-else>{{ error.message }}</span>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  errors: {
    type: Array,
    required: true,
  },
})

const parsedErrors = computed(() => {
  if (props.errors && props.errors.length) {
    return props.errors.map((error) => {
      if (typeof error === 'string') {
        return {
          message: error,
        }
      }
      if (error.$message) {
        // is vuelidate error
        return { message: error.$message, inputId: `#${error.$property}` }
      }
      return ''
    })
  }
  return []
})
</script>
