<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.companies')"
        :isLoading="companyListStore.isLoading"
        :enableSearch="true"
        :searchPlaceholder="t('companies.searchPlaceholder')"
        :searchTerm="companyListStore.filter"
        @update:searchTerm="updateFilter"
      >
        <router-link
          to="/companies/new/company"
          class="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {{ t('companies.createNew') }}
        </router-link>
      </ListHeader>

      <ErrorMessage
        v-if="companyListStore.error"
        :error="companyListStore.error"
      />

      <List
        v-slot="{ entity: company }"
        :entities="companyListStore.companies"
        :page="companyListStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
      >
        <router-link
          :to="`/companies/${company.id}/company`"
          class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
        >
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="flex min-w-0 flex-1 items-center">
              <div class="min-w-0 flex-1 md:grid md:grid-cols-4 md:gap-4">
                <div class="flex items-center md:col-span-3">
                  <p class="truncate text-sm font-medium">
                    {{ company.name }}
                  </p>
                </div>
                <div class="hidden md:col-span-1 md:block">
                  <div class="flex h-12 w-24 items-center">
                    <img
                      v-if="company.logoUrl"
                      :src="
                        getImgSrc({
                          imgUrl: company.logoUrl,
                          height: 48,
                          width: 96,
                          trim: 'color',
                          trimColor: 'fff',
                        })
                      "
                      :alt="`Logo ${company.name}`"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import ErrorMessage from '../components/ErrorMessage.vue'
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import { getImgSrc } from '../utils/imgSrc.js'
import { useCompanyListStore } from '../store/companyListStore'
import { useI18n } from 'vue-i18n'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'

const companyListStore = useCompanyListStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
companyListStore.load()

const updatePageSize = (pageSize) => {
  companyListStore.page.size = pageSize
  companyListStore.load()
}

const updateFilter = (filter) => {
  companyListStore.filter = filter
  companyListStore.page.number = 1
  companyListStore.load()
}
const updatePageNumber = (number) => {
  companyListStore.page.number = number
  companyListStore.load()
}
</script>
