<template>
  <ErrorMessage v-if="candidateStore.error" :error="candidateStore.error" />
  <div v-else>
    <div class="flex items-center justify-end gap-x-2">
      <router-link
        :to="{
          name: 'candidates',
          params: { id: route.params.id },
          query: { ...route.query },
        }"
        class="mr-auto inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500 hover:underline"
      >
        <ArrowSmallLeftIcon class="h-6 w-6" /> {{ t('candidates.goBack') }}
      </router-link>
      <LoadingSpinner v-if="candidateStore.isLoading" class="ml-3 h-4 w-4" />
      <Menu
        v-if="authStore.isCampaignManager"
        as="div"
        class="relative block text-left"
      >
        <div>
          <MenuButton
            class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          >
            {{ t('candidates.actions.title') }}
            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition
          enterActiveClass="transition ease-out duration-100"
          enterFromClass="transform opacity-0 scale-95"
          enterToClass="transform opacity-100 scale-100"
          leaveActiveClass="transition ease-in duration-75"
          leaveFromClass="transform opacity-100 scale-100"
          leaveToClass="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-auto min-w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }" @click="toggleTestCandidate">
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  >{{
                    candidateStore.candidate.isTest
                      ? t('candidates.actions.remove')
                      : t('candidates.actions.markAs')
                  }}
                  <span
                    class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                    >{{ t('candidates.status.test') }}</span
                  >
                  {{
                    candidateStore.candidate.isTest
                      ? t('candidates.actions.mark')
                      : ''
                  }}
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active }" @click="toggleDuplicateCandidate">
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  >{{
                    candidateStore.candidate.isDuplicate
                      ? t('candidates.actions.remove')
                      : t('candidates.actions.markAs')
                  }}
                  <span
                    class="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800"
                    >{{ t('candidates.status.duplicate') }}</span
                  >
                  {{
                    candidateStore.candidate.isDuplicate
                      ? t('candidates.actions.mark')
                      : ''
                  }}
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div v-if="candidateStore.candidate.id">
      <CandidateCard />
      <CandidateContactQuestion />
      <CandidateCustomQuestions />
      <CandidateMetadata />
      <CandidateFlows />
    </div>
  </div>
</template>

<script setup>
import { useCandidateStore } from '../../../store/candidateStore'
import { useAuthStore } from '../../../store/authStore'
import { ArrowSmallLeftIcon } from '@heroicons/vue/24/outline'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { onUnmounted } from 'vue'
import LoadingSpinner from '../../LoadingSpinner.vue'
import ErrorMessage from '../../ErrorMessage.vue'
import CandidateCustomQuestions from './CandidateCustomQuestions.vue'
import CandidateContactQuestion from './CandidateContactQuestion.vue'
import CandidateMetadata from './CandidateMetadata.vue'
import CandidateCard from './CandidateCard.vue'
import CandidateFlows from './CandidateFlows.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

const candidateStore = useCandidateStore()
const authStore = useAuthStore()
const route = useRoute()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
onUnmounted(() => {
  candidateStore.$reset()
})

candidateStore.loadCandidate(route.params.candidateId)

const toggleTestCandidate = () => {
  candidateStore.toggleTestCandidate()
}
const toggleDuplicateCandidate = () => {
  candidateStore.toggleDuplicateCandidate()
}
</script>
