<template>
  <div class="flex h-screen flex-col bg-white pb-32">
    <main
      class="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 sm:px-6 lg:px-8"
    >
      <div class="flex shrink-0 justify-center">
        <EnvelopeIcon
          v-if="isVerifyEmailError"
          class="h-24 w-24 rounded-full border-8 border-indigo-500 px-3 py-3 text-indigo-500 md:h-32 md:w-32"
        />

        <LockClosedIcon
          v-if="!isVerifyEmailError"
          class="h-24 w-24 rounded-full border-8 border-indigo-500 px-3 py-3 text-indigo-500 md:h-32 md:w-32"
        />
      </div>
      <div class="mt-8">
        <div class="flex flex-col items-center text-center">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">
            {{
              isVerifyEmailError
                ? t('auth.verifyEmailTitle')
                : t('auth.errorTitle')
            }}
          </h1>
          <p class="mt-8 max-w-lg text-base text-gray-500">
            {{
              isVerifyEmailError
                ? t('auth.verifyEmailMessage')
                : authStore.error
            }}
          </p>
          <div class="mt-8">
            <button
              class="mr-24 text-base font-medium text-gray-400 hover:text-gray-500 hover:underline"
              @click="logout"
            >
              {{ t('auth.logout') }}
            </button>
            <a
              href="/"
              class="text-base font-medium text-indigo-600 hover:text-indigo-500 hover:underline"
              >{{
                isVerifyEmailError
                  ? t('auth.confirmVerification')
                  : t('auth.returnHome')
              }}<span aria-hidden="true"> &rarr;</span></a
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { useAuthStore } from '../store/authStore'
import { useI18n } from 'vue-i18n'
import { EnvelopeIcon, LockClosedIcon } from '@heroicons/vue/20/solid'

const authStore = useAuthStore()

const isVerifyEmailError = authStore.error.includes('verify your email')
const logout = () => authStore.logout()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
</script>
