<template>
  <TwoColumnForm
    :title="formatedTagGroup?.name || 'Tag'"
    :labelText="t(`vacancy.publication.tagGroups.${formatedTagGroup.category}`)"
  >
    <List v-if="!isEmpty" v-slot="{ entity: tag }" :entities="siteStore.tags">
      <div
        v-if="tag.id === selectedTag?.id"
        class="grid grid-cols-6 items-start gap-6 px-4 py-4 sm:px-6"
      >
        <div class="col-span-6 lg:col-span-3">
          <FormInput
            id="tagName"
            type="text"
            :value="selectedTag.label"
            :label="t('site.tags.tagLabel')"
            :onInput="updateTagLabel"
            :rules="nameRequired"
          />
        </div>
        <div class="col-span-6 flex items-center gap-x-4 lg:col-span-3">
          <div class="grow">
            <FormInput
              id="tagKey"
              type="text"
              :value="selectedTag.key"
              :label="t('site.tags.tagKey')"
              :onInput="updateTagKey"
              :rules="nameRequired"
            />
          </div>
          <button
            class="mt-5 rounded-sm focus:border-red-400 focus:outline-none focus:ring-2 focus:ring-red-400"
            :aria-label="t('publisher.user.deleteTitle')"
            @click.prevent="editTag(tag)"
          >
            <CheckIcon class="h-5 w-5 text-gray-400 hover:text-red-400" />
          </button>
        </div>
      </div>
      <div v-else class="px-4 py-4 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="truncate text-sm">
            {{ tag.label }}
          </p>
          <div class="ml-2 flex shrink-0">
            <button
              class="rounded-sm focus:border-red-400 focus:outline-none focus:ring-2 focus:ring-red-400"
              :aria-label="t('publisher.user.deleteTitle')"
              @click.prevent="setActiveEditTag(tag)"
            >
              <PencilSquareIcon class="w5 h-5 text-gray-400 hover:text-red-400" />
            </button>
          </div>
        </div>
      </div>
    </List>
    <EmptyStateCard v-else :message="t('site.emptyTagList')" />
  </TwoColumnForm>

  <TwoColumnFormDivider />
  <TwoColumnForm :title="t('site.tags.createTag')">
    <CreateTag />
  </TwoColumnForm>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { PencilSquareIcon, CheckIcon } from '@heroicons/vue/20/solid'
import { useRoute } from 'vue-router'
import EmptyStateCard from '../EmptyStateCard.vue'
import List from '../List.vue'
import TwoColumnForm from '../TwoColumnForm.vue'
import TwoColumnFormDivider from '../TwoColumnFormDivider.vue'
import FormInput from '../FormInput.vue'
import { useSiteStore } from '../../store/siteStore'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import CreateTag from './CreateTag.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const v$ = useVuelidate()
const nameRequired = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const route = useRoute()
const siteStore = useSiteStore()

siteStore.loadTags(route.params.tagGroupId)
const formatedTagGroup = computed(() => {
  const tagGroup = siteStore.tagGroups.find(
    (tagGroup) => tagGroup.id == route.params.tagGroupId
  )
  const categoryPos = tagGroup.name.indexOf('VACANCY')
  return {
    name:
      categoryPos === -1
        ? tagGroup.name
        : tagGroup.name.slice(0, categoryPos - 1),
    category: tagGroup.tagCategory,
  }
})

const isEmpty = computed(
  () => !siteStore.isLoading && siteStore.tags.length === 0
)

const updateTagLabel = (newName) => {
  selectedTag.value.label = newName
}
const updateTagKey = (newName) => {
  selectedTag.value.key = newName
}

const selectedTag = ref(null)
const setActiveEditTag = (tag) => {
  selectedTag.value = tag
}
const editTag = () => {
  if (!selectedTag.value) {
    return
  }
  v$.value.$touch()
  if (!v$.value.$error) {
    siteStore.editTag(selectedTag.value, route.params.tagGroupId)
    selectedTag.value = null
  }
}
</script>
