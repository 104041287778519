import { defineStore } from 'pinia'
import auth from '../auth.js'
import router from '../router'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    isLoading: false,
    token: null,
    error: null,
    user: null,
  }),
  actions: {
    async checkTokenSilently() {
      try {
        this.token = await auth.getTokenSilently()
      } catch (error) {
        this.error = error.message
      }
    },
    async init() {
      this.isLoading = true

      try {
        this.token = await auth.getTokenSilently()
        this.user = await auth.getUser()
      } catch (error) {
        if (
          error.error === 'missing_refresh_token' ||
          error.error === 'login_required' ||
          error.error === 'consent_required'
        ) {
          const localUrl = auth.loginWithRedirect({
            appState: {
              initialLocation: window.location.href.substring(
                window.origin.length,
              ),
            },
          })
        } else {
          this.error = error.message
        }
      } finally {
        this.isLoading = false
      }
    },
    async logout() {
      this.isLoading = true

      try {
        await auth.logout({
          logoutParams: { returnTo: window.location.origin },
        })
        this.user = null
        this.token = null
      } catch (error) {
        this.error = error.message
      } finally {
        this.isLoading = false
      }
    },
    async signUp() {
      auth.loginWithRedirect({
        authorizationParams: { prompt: 'login', screen_hint: 'signup' },
      })
    },
    async handleRedirectCallback() {
      this.isLoading = true
      try {
        const { appState } = await auth.handleRedirectCallback()
        this.token = await auth.getTokenSilently()
        this.user = await auth.getUser()
        // Redirect to dashboard
        router.replace(appState.initialLocation || '/')
      } catch (error) {
        if (error.error === 'consent_required') {
          auth.loginWithRedirect()
        } else {
          this.error = error.message
          this.user = null
        }
      } finally {
        this.isLoading = false
      }
    },
  },
  getters: {
    roles(state) {
      if (state.user) {
        return Object.keys(state.user).find((key) => /roles/.test(key))
      }
      return false
    },
    isAdmin(state) {
      const rolesKey = this.roles
      if (rolesKey) {
        return !!state.user[rolesKey].find(
          (role) => role === 'jobsrepublic-admin',
        )
      }

      return false
    },
    isCampaignManager(state) {
      const rolesKey = this.roles
      if (rolesKey) {
        return !!state.user[rolesKey].find(
          (role) => role === 'jobsrepublic-campaign-manager',
        )
      }

      return false
    },
  },
})
