<template>
  <button
    type="button"
    class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
    :class="{ 'bg-gray-100': isActionActive }"
  >
    <ClipboardDocumentListIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
    Input
  </button>
</template>

<script setup>
import { ClipboardDocumentListIcon } from '@heroicons/vue/24/outline'

const isActionActive = false
</script>
