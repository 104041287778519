import { defineStore } from 'pinia'
import tooPublishers from '../api/tooPublishers'

export const usePublisherCompanyListStore = defineStore(
  'publisherCompanyListStore',
  {
    state: () => ({
      isLoading: false,
      error: null,
      companies: [],
      filter: '',
      page: {
        number: 1,
        size: 9999,
        totalElements: 0,
        totalPages: 0,
      },
    }),
    actions: {
      async load() {
        this.error = null
        this.isLoading = true

        try {
          const companies = await tooPublishers.getCompanies({
            filter: this.filter,
            page: this.page.number,
            size: this.page.size,
          })

          this.companies = companies.data

          this.isLoading = false
        } catch (error) {
          this.companies = []
          this.isLoading = false
          this.error = error
        }
      },
    },
  }
)
