<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <PageHeader
        :title="siteStore.site.name"
        :subtitle="t('site.editSite')"
        :isLoading="siteStore.isLoading"
      />
      <TabNavigation storeName="site" :tabs="tabs" class="mb-5" />
      <div v-if="activeTab === 'site'">
        <TwoColumnForm title="">
          <ErrorMessages
            v-if="siteStore.errors.length"
            :errors="siteStore.errors"
          />
        </TwoColumnForm>
        <TwoColumnForm :title="t('site.title')">
          <TwoColumnFormCard><Meta /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('site.images')">
          <TwoColumnFormCard><Images /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('site.sendgrid')">
          <TwoColumnFormCard><Sendgrid /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('site.customCode')">
          <TwoColumnFormCard><CustomHtml /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm :title="t('site.theme.title')">
          <TwoColumnFormCard><Theme /></TwoColumnFormCard
        ></TwoColumnForm>
        <TwoColumnFormDivider />
        <TwoColumnForm title=""
          ><FormSave
            :validationErrors="v$.$errors"
            :validationTouch="v$.$touch"
            storeName="site"
            storeAction="save"
          />
        </TwoColumnForm>
      </div>
      <div v-if="activeTab === 'tags'">
        <TagGroups />
      </div>
      <div v-if="activeTab === 'tagDetail'">
        <Tags />
      </div>
    </div>
  </div>
</template>

<script setup>
import FormSave from '../components/FormSave.vue'
import TabNavigation from '../components/TabNavigation.vue'
import TwoColumnForm from '../components/TwoColumnForm.vue'
import TwoColumnFormCard from '../components/TwoColumnFormCard.vue'
import TagGroups from '../components/site/TagGroups.vue'
import Tags from '../components/site/Tags.vue'
import TwoColumnFormDivider from '../components/TwoColumnFormDivider.vue'
import ErrorMessages from '../components/ErrorMessages.vue'
import Meta from '../components/site/Meta.vue'
import Sendgrid from '../components/site/Sendgrid.vue'
import CustomHtml from '../components/site/CustomHtml.vue'
import Theme from '../components/site/Theme.vue'
import Images from '../components/site/Images.vue'
import PageHeader from '../components/PageHeader.vue'
import { useI18n } from 'vue-i18n'
import { useSiteStore } from '../store/siteStore'
import { useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { computed } from 'vue'

const siteStore = useSiteStore()
const route = useRoute()

const v$ = useVuelidate()

const tabs = ['site', 'tags']
const activeTab = computed(() => {
  return route.name
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

siteStore.load(route.params.id)
</script>
