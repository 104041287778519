<template>
  <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
  <div class="inline-flex flex-col items-center">
    <ul :id="id" class="flex items-center space-x-0.5">
      <li
        v-for="(color, index) in modelValue"
        :key="index"
        class="inline-flex flex-col items-center"
      >
        <span class="text-xs font-medium text-gray-400">{{
          100 * (index + 1)
        }}</span>
        <ColorInput
          :class="{
            'h-7 w-7 lg:h-9 lg:w-9': index !== middleIndex,
            'h-10 w-10 lg:h-12 lg:w-12': index === middleIndex,
          }"
          :modelValue="color"
          @update:model-value="(newColor) => updateColorShade(newColor, index)"
        />
      </li>
    </ul>

    <div class="flex w-full justify-between">
      <span class="text-xs font-medium text-gray-400">{{
        t('site.theme.lightShade')
      }}</span>
      <span class="text-xs font-medium text-gray-400">{{
        t('site.theme.darkShade')
      }}</span>
    </div>

    <button
      type="button"
      class="rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      @click="generateColors"
    >
      {{ t('site.theme.generateShades') }}
    </button>
  </div>
</template>

<script setup>
import ColorInput from '../ColorInput.vue'
import { toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import Values from 'values.js'

const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array,
    default() {
      return []
    },
  },
})
const emit = defineEmits(['update:modelValue'])

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const middleIndex = Math.floor(9 / 2)
const { modelValue } = toRefs(props)

const updateColorShade = (color, index) => {
  modelValue.value[index] = color
  emit('update:modelValue', modelValue.value)
}

const generator = new Values()
const generateColors = () => {
  const baseColor = modelValue.value[middleIndex]
  generator.setColor(baseColor)

  // Code inspired on this: https://github.com/bourhaouta/vscode-tailwindshades/blob/f89796ce8a56eb3964592ad482387418108a9b8b/extension.js#L39
  const shades = generator
    .all(20)
    .filter(({ weight }) => weight !== 100) // Filter out the extremes: white and black
    .map((color) => color.hexString())

  modelValue.value.splice(0)
  modelValue.value.push(...shades)

  emit('update:modelValue', modelValue.value)
}
</script>
