<template>
  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6">
      <div class="col-span-6 flex">
        <label for="company" class="block text-sm font-medium text-gray-700"
          >{{ t('companyLabel') }}*</label
        >
      </div>
      <Multiselect
        id="companyModelValue"
        class="text-sm shadow-sm"
        :modelValue="companyModelValue"
        :placeholder="t('vacancy.company.placeholder')"
        :minChars="3"
        :filterResults="false"
        :resolveOnLoad="true"
        :delay="500"
        :searchable="true"
        :options="searchCompany"
        :object="true"
        :disabled="disabled"
        :noOptionsText="t('vacancy.company.noOptions')"
        inputType="search"
        autocomplete="off"
        @change="handleChange"
      >
        <template v-if="v$.companyModelValue.$error" #caret>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <ExclamationCircleIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        </template>
      </Multiselect>
      <div v-if="v$.companyModelValue.$errors">
        <p
          v-for="(error, index) in v$.companyModelValue.$errors"
          id="companyModelValue-error"
          :key="index"
          class="mt-2 text-sm text-red-600"
        >
          {{ error.$message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import Multiselect from '@vueform/multiselect'
import { useI18n } from 'vue-i18n'
import tooPublishers from '/src/api/tooPublishers'
import { computed } from 'vue'
import { useVacancyStore } from '../../store/vacancyStore'
import { required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

const vacancyStore = useVacancyStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const searchCompany = async (filter = '') => {
  const queryRes = await tooPublishers.getCompanies({
    filter: filter || '',
    size: 15,
  })
  return queryRes.data.map((company) => ({
    label: company.name,
    value: company.id,
  }))
}

const companyModelValue = computed(() => ({
  label: vacancyStore.company?.name,
  value: vacancyStore.company?.id,
}))

const disabled = computed(() => vacancyStore.vacancy.id !== 'new')

const rules = computed(() => ({
  companyModelValue: {
    required: helpers.withMessage(t('validations.company.required'), required),
    $autoDirty: true,
  },
}))
const v$ = useVuelidate(rules, {
  companyModelValue: computed(() => companyModelValue.value.value),
})

const handleChange = (selectedCompany) => {
  if (selectedCompany && selectedCompany.value) {
    if (vacancyStore.vacancy.companyId !== selectedCompany.value) {
      vacancyStore.vacancy.companyId = selectedCompany.value
      vacancyStore.loadCompanyAndSites()
    }
  } else {
    vacancyStore.company = null
    vacancyStore.vacancy.companyId = null
    vacancyStore.sites = []
  }
}
</script>
