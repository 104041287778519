<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <a href="#main-content" class="sr-only focus:not-sr-only">
    Skip to content
  </a>
  <div class="flex h-screen overflow-hidden bg-gray-100">
    <!-- MOBILE MENU -->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 z-40 flex md:hidden"
        :open="sidebarOpen"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div
            class="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div class="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="h-0 flex-1 overflow-y-auto pb-4 pt-5">
              <header role="banner">
                <div class="flex-column shrink-0 items-center px-4">
                  <img
                    class="h-8 w-auto"
                    src="/logo-jobsrepublic-publisher-v4.svg"
                    alt="Logo Jobsrepublic Publisher"
                  />
                </div>
              </header>
              <nav aria-label="Sidebar menu" class="mt-5 space-y-1 px-2">
                <router-link
                  v-for="item in navigationToDisplay"
                  :key="item.name"
                  v-slot="{ href, navigate, isActive }"
                  :to="item.to"
                  custom
                >
                  <a
                    :href="href"
                    :class="[
                      isActive
                        ? 'bg-indigo-800 text-white'
                        : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                      'group flex items-center rounded-md px-2 py-2 text-base font-medium focus:underline focus:outline-none',
                    ]"
                    @click="navigate"
                  >
                    <component
                      :is="item.icon"
                      class="mr-4 h-6 w-6 shrink-0 text-indigo-300"
                      aria-hidden="true"
                    />
                    {{ t(item.name) }}
                  </a>
                </router-link>
              </nav>
              <ActivePublisherSelect v-if="authStore.isAdmin" />
              <div v-if="authStore.user" class="ml-4 mt-4 flex items-center">
                <div>
                  <img
                    class="inline-block h-10 w-10 rounded-full"
                    :src="authStore.user.picture"
                    :alt="`Avatar ${authStore.user.name}`"
                  />
                </div>
                <div class="ml-3">
                  <p class="text-base font-medium text-white">
                    {{ authStore.user.name }}
                  </p>
                  <button
                    class="text-sm font-medium text-indigo-200 focus:underline focus:outline-none"
                    @click="logout"
                  >
                    {{ t('auth.logout') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
        <div class="w-14 shrink-0" aria-hidden="true">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-indigo-700 md:flex md:shrink-0">
      <div class="flex w-64 flex-col">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex h-0 flex-1 flex-col">
          <div class="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
            <header role="banner">
              <div class="flex-column shrink-0 items-center px-4">
                <img
                  class="h-8 w-auto"
                  src="/logo-jobsrepublic-publisher-v4.svg"
                  alt="Logo Jobsrepublic Publisher"
                />
              </div>
            </header>
            <nav aria-label="Sidebar menu" class="mt-5 flex-1 space-y-1 px-2">
              <router-link
                v-for="item in navigationToDisplay"
                :key="item.name"
                v-slot="{ href, navigate, isActive }"
                :to="item.to"
                custom
              >
                <a
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-indigo-800 text-white'
                      : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium focus:underline focus:outline-none',
                  ]"
                  @click="navigate"
                >
                  <component
                    :is="item.icon"
                    class="mr-3 h-6 w-6 shrink-0 text-indigo-300"
                    aria-hidden="true"
                  />
                  {{ t(item.name) }}
                </a>
              </router-link>
            </nav>
            <ActivePublisherSelect v-if="authStore.isAdmin" />
          </div>
          <div
            v-if="authStore.user"
            class="flex shrink-0 border-t border-indigo-800 p-4"
          >
            <div class="flex items-center">
              <div>
                <img
                  class="inline-block h-9 w-9 rounded-full"
                  :src="authStore.user.picture"
                  :alt="`Avatar ${authStore.user.name}`"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-white">
                  {{ authStore.user.name }}
                </p>
                <button
                  class="text-sm font-medium text-indigo-200 focus:underline focus:outline-none"
                  @click="logout"
                >
                  {{ t('auth.logout') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
      <!-- Open mobile sidebar button -->
      <nav
        aria-label="Open sidebar button"
        class="pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden"
      >
        <button
          class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </nav>
      <main
        id="main-content"
        class="relative z-0 flex-1 overflow-y-auto focus:outline-none"
      >
        <router-view />
      </main>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '../store/authStore'
import { useSettingsStore } from '../store/settingsStore'
import { ref, computed } from 'vue'
import ActivePublisherSelect from '../components/ActivePublisherSelect.vue'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { navigation } from '../utils/mainNavigation'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const authStore = useAuthStore()
const settingsStore = useSettingsStore()
const sidebarOpen = ref(false)
const logout = () => authStore.logout()

const navigationToDisplay = computed(() =>
  navigation.filter((item) => {
    if (item.alwaysShow) {
      return true
    }
    if (!authStore.isAdmin && item.onlyAdmin) {
      return false
    }
    if (
      (!authStore.isAdmin || !authStore.isCampaignManager) &&
      item?.onlyCampaignManager
    ) {
      return false
    }
    if (
      authStore.isAdmin &&
      !settingsStore.activePublisher.id &&
      item.hideIfNoPublisherSelected
    ) {
      return false
    }
    if (settingsStore.activePublisher.id && !item.hideIfNoPublisherSelected) {
      return false
    }
    return true
  }),
)
</script>
