<template>
  <div v-if="selectedEvent">
    <router-link
      v-if="!selectedEvent.mock"
      :to="{
        name: 'events',
        params: { id: route.params.id },
        query: { ...route.query },
      }"
      class="mr-auto inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500 hover:underline"
    >
      <ArrowSmallLeftIcon class="h-6 w-6" /> {{ t('company.events.goBack') }}
    </router-link>

    <p v-if="!selectedEvent.mock" class="mt-4 flex gap-2">
      {{ formattedDate }}
      <span
        class="rounded-full bg-indigo-100 px-2 py-1 text-xs text-indigo-600"
        >{{ usedConfig.label }}</span
      >
      <component
        :is="statusToDisplay.icon"
        v-if="statusToDisplay"
        class="w-6 fill-white"
        :class="statusToDisplay.color"
      />
    </p>

    <ErrorMessage
      v-if="selectedEvent.error"
      class="mt-5"
      :error="selectedEvent.error"
    />

    <div class="mt-5 overflow-hidden bg-white shadow sm:rounded-lg">
      <div
        class="flex px-4 py-5 hover:cursor-pointer sm:px-5"
        @click="$refs.collapseCreatedButton.click()"
      >
        <h3
          class="grow text-lg font-medium leading-6"
          :class="
            selectedEvent.vacancyEvents.created.length
              ? 'text-gray-900'
              : 'text-gray-400'
          "
        >
          {{ selectedEvent.vacancyEvents.created.length }}
          {{
            selectedEvent.mock
              ? t(
                  'company.events.createdMockVacancies',
                  selectedEvent.vacancyEvents.created.length,
                )
              : t(
                  'company.events.createdVacancies',
                  selectedEvent.vacancyEvents.created.length,
                )
          }}
        </h3>
        <button
          ref="collapseCreatedButton"
          :disabled="!selectedEvent.vacancyEvents.created.length"
          class="text-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 disabled:text-gray-400"
          @click.stop="collapseCreatedPanel = !collapseCreatedPanel"
        >
          <ChevronDoubleUpIcon
            class="w-5 transition"
            :class="collapseCreatedPanel ? 'rotate-180' : ''"
          />
        </button>
      </div>
      <div
        class="break-words border-t border-gray-200 transition-[max-height] duration-500"
        :class="!collapseCreatedPanel ? 'h-fit max-h-[99rem]' : 'h-0 max-h-0'"
      >
        <List
          v-slot="{ entity: vacancyEvent }"
          :entities="selectedEvent.vacancyEvents.created"
        >
          <router-link
            v-slot="{ href, navigate }"
            :to="`/vacancies/${vacancyEvent.vacancy.id}/vacancy`"
            custom
          >
            <a
              :href="!selectedEvent.mock ? href : null"
              class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
              @click="wrapNavigate(navigate, $event)"
            >
              <div class="flex flex-col gap-4 px-5 py-3 text-sm sm:flex-row">
                <div class="sm:w-1/2">
                  <span
                    class="mr-2 inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800"
                  >
                    {{ vacancyEvent.vacancy.id }}
                  </span>
                  <span class="text-indigo-800">{{
                    vacancyEvent.vacancy.title
                  }}</span>
                  <span
                    v-if="vacancyEvent.vacancy.archived"
                    class="ml-2 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                    >{{ t('archived') }}</span
                  >
                </div>
                <ul
                  class="flex flex-grow flex-wrap justify-end gap-x-6 gap-y-3 text-xs"
                >
                  <template
                    v-for="publication in vacancyEvent.publicationEvents"
                    :key="publication.id"
                  >
                    <li class="flex items-center gap-2">
                      <span class="text-gray-800">{{
                        publication.vacancyPublication.siteName
                      }}</span>
                      <span class="flex items-center gap-x-1 text-gray-500">
                        <CalendarIcon class="h-4 w-4 text-blue-700" />
                        {{
                          toLocalDate(publication.vacancyPublication.startDate)
                        }}
                        -
                        {{
                          toLocalDate(publication.vacancyPublication.endDate)
                        }}</span
                      >
                    </li>
                  </template>
                </ul>
              </div>
            </a>
          </router-link>
        </List>
      </div>
    </div>

    <div class="mt-5 overflow-hidden bg-white shadow sm:rounded-lg">
      <div
        class="flex px-4 py-5 hover:cursor-pointer sm:px-5"
        @click="$refs.collapseUpdatedButton.click()"
      >
        <h3
          class="grow text-lg font-medium leading-6"
          :class="
            selectedEvent.vacancyEvents.updated.length
              ? 'text-gray-900'
              : 'text-gray-400'
          "
        >
          {{ selectedEvent.vacancyEvents.updated.length }}
          {{
            selectedEvent.mock
              ? t(
                  'company.events.updatedMockVacancies',
                  selectedEvent.vacancyEvents.updated.length,
                )
              : t(
                  'company.events.updatedVacancies',
                  selectedEvent.vacancyEvents.updated.length,
                )
          }}
        </h3>
        <button
          ref="collapseUpdatedButton"
          :disabled="!selectedEvent.vacancyEvents.updated.length"
          class="text-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 disabled:text-gray-400"
          @click.stop="collapseUpdatedPanel = !collapseUpdatedPanel"
        >
          <ChevronDoubleUpIcon
            class="w-5 transition"
            :class="collapseUpdatedPanel ? 'rotate-180' : ''"
          />
        </button>
      </div>
      <div
        class="break-words border-t border-gray-200 transition-[max-height] duration-500"
        :class="!collapseUpdatedPanel ? 'h-fit max-h-[99rem]' : 'h-0 max-h-0'"
      >
        <List
          v-slot="{ entity: vacancyEvent }"
          :entities="selectedEvent.vacancyEvents.updated"
        >
          <router-link
            v-slot="{ href, navigate }"
            :to="`/vacancies/${vacancyEvent.vacancy.id}/vacancy`"
            custom
          >
            <a
              :href="!selectedEvent.mock ? href : null"
              class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
              @click="wrapNavigate(navigate, $event)"
            >
              <div class="flex flex-col gap-4 px-5 py-3 text-sm sm:flex-row">
                <div class="sm:w-1/2">
                  <span
                    class="mr-2 inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800"
                  >
                    {{ vacancyEvent.vacancy.id }}
                  </span>
                  <span class="text-indigo-800">{{
                    vacancyEvent.vacancy.title
                  }}</span>
                  <span
                    v-if="vacancyEvent.vacancy.archived"
                    class="ml-2 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                    >{{ t('archived') }}</span
                  >
                </div>
                <ul
                  class="flex flex-grow flex-wrap justify-end gap-x-6 gap-y-3 text-xs"
                >
                  <template
                    v-for="publication in vacancyEvent.publicationEvents"
                    :key="publication.id"
                  >
                    <li class="flex items-center gap-2">
                      <span class="text-gray-800">{{
                        publication.vacancyPublication.siteName
                      }}</span>
                      <span class="flex items-center gap-x-1 text-gray-500">
                        <CalendarIcon class="h-4 w-4 text-blue-700" />
                        {{
                          toLocalDate(publication.vacancyPublication.startDate)
                        }}
                        -
                        {{
                          toLocalDate(publication.vacancyPublication.endDate)
                        }}</span
                      >
                    </li>
                  </template>
                </ul>
              </div>
            </a>
          </router-link>
        </List>
      </div>
    </div>

    <div class="mt-5 overflow-hidden bg-white shadow sm:rounded-lg">
      <div
        class="flex px-4 py-5 hover:cursor-pointer sm:px-5"
        @click="$refs.collapseArchivedButton.click()"
      >
        <h3
          class="grow text-lg font-medium leading-6"
          :class="
            selectedEvent.vacancyEvents.archived.length
              ? 'text-gray-900'
              : 'text-gray-400'
          "
        >
          {{ selectedEvent.vacancyEvents.archived.length }}
          {{
            selectedEvent.mock
              ? t(
                  'company.events.archivedMockVacancies',
                  selectedEvent.vacancyEvents.archived.length,
                )
              : t(
                  'company.events.archivedVacancies',
                  selectedEvent.vacancyEvents.archived.length,
                )
          }}
        </h3>
        <button
          ref="collapseArchivedButton"
          :disabled="!selectedEvent.vacancyEvents.archived.length"
          class="text-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 disabled:text-gray-400"
          @click.stop="collapseArchivedPanel = !collapseArchivedPanel"
        >
          <ChevronDoubleUpIcon
            class="w-5 transition"
            :class="collapseArchivedPanel ? 'rotate-180' : ''"
          />
        </button>
      </div>
      <div
        class="break-words border-t border-gray-200 transition-[max-height] duration-500"
        :class="!collapseArchivedPanel ? 'h-fit max-h-[99rem]' : 'h-0 max-h-0'"
      >
        <List
          v-slot="{ entity: vacancyEvent }"
          :entities="selectedEvent.vacancyEvents.archived"
        >
          <router-link
            v-slot="{ href, navigate }"
            :to="`/vacancies/${vacancyEvent.vacancy.id}/vacancy`"
            custom
          >
            <a
              :href="!selectedEvent.mock ? href : null"
              class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
              @click="wrapNavigate(navigate, $event)"
            >
              <div class="flex flex-col gap-4 px-5 py-3 text-sm sm:flex-row">
                <div class="sm:w-1/2">
                  <span
                    class="mr-2 inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800"
                  >
                    {{ vacancyEvent.vacancy.id }}
                  </span>
                  <span class="text-indigo-800">{{
                    vacancyEvent.vacancy.title
                  }}</span>
                  <span
                    v-if="vacancyEvent.vacancy.archived"
                    class="ml-2 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                    >{{ t('archived') }}</span
                  >
                </div>
                <ul
                  class="flex flex-grow flex-wrap justify-end gap-x-6 gap-y-3 text-xs"
                >
                  <template
                    v-for="publication in vacancyEvent.publicationEvents"
                    :key="publication.id"
                  >
                    <li class="flex items-center gap-2">
                      <span class="text-gray-800">{{
                        publication.vacancyPublication.siteName
                      }}</span>
                      <span class="flex items-center gap-x-1 text-gray-500">
                        <CalendarIcon class="h-4 w-4 text-blue-700" />
                        {{
                          toLocalDate(publication.vacancyPublication.startDate)
                        }}
                        -
                        {{
                          toLocalDate(publication.vacancyPublication.endDate)
                        }}</span
                      >
                    </li>
                  </template>
                </ul>
              </div>
            </a>
          </router-link>
        </List>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import {
  ChevronDoubleUpIcon,
  ArrowSmallLeftIcon,
  CalendarIcon,
} from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { format, parseISO } from 'date-fns'
import { useImporterStore } from '../../store/importerStore'
import { useSettingsStore } from '../../store/settingsStore'
import List from '../List.vue'
import ErrorMessage from '../ErrorMessage.vue'
import { possibleStates } from '../../utils/vacancyImportStates'

const route = useRoute()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const settingsStore = useSettingsStore()
const importerStore = useImporterStore()

const { selectedEvent, configs } = storeToRefs(importerStore)

const collapseCreatedPanel = ref(true)
const collapseUpdatedPanel = ref(true)
const collapseArchivedPanel = ref(true)

const usedConfig = computed(() => {
  const config = configs.value.find(
    (config) => config.id === selectedEvent.value.importConfigurationId,
  )
  if (config) {
    return {
      ...config,
      label: importerStore.availableATS.find(
        (item) => item.value === config.ats,
      ).label,
    }
  }
  return {}
})

if (route.params.eventId) {
  importerStore.loadEvent(route.params.eventId)
}
if (!importerStore.configs.length && route.name === 'eventDetail') {
  importerStore.loadConfig(route.params.id)
}

const formattedDate = computed(() => {
  return selectedEvent.value?.dateCreated
    ? format(parseISO(selectedEvent.value.dateCreated), 'dd MMM yyyy, HH:mm')
    : ''
})

const statusToDisplay = computed(() => {
  return selectedEvent.value?.status
    ? possibleStates.find((state) => state.code === selectedEvent.value?.status)
    : null
})

const toLocalDate = (applicationDate) => {
  if (applicationDate.startsWith('2999'))
    return t('vacancy.publication.noEndDate')

  return format(parseISO(applicationDate), 'dd/MM/yy')
}

const wrapNavigate = (navigate, event) => {
  if (selectedEvent.value?.mock) {
    return
  }

  const publisherId = usedConfig.value?.publisher?.id
  const publisher = settingsStore.publisherList.find(
    (publisher) => publisher.id === publisherId,
  )

  if (publisher) {
    settingsStore.activePublisher = publisher
    settingsStore.userRoleChanged = true

    nextTick(() => {
      navigate(event)
    })
  }
}
</script>
