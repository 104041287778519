<template>
  <div
    class="grid grid-cols-12 px-6 pb-6 pt-4 sm:space-y-6 lg:space-x-12 lg:space-y-0"
  >
    <div class="col-span-12 space-y-4 lg:col-span-4">
      <div>
        <label
          for="email"
          class="inline-flex items-center text-sm font-medium text-gray-700"
          >{{ t('candidateGateway.sendEmailStep.to') }}*
          <HelpPopover :text="t('candidateGateway.sendEmailStep.toPopover')"
        /></label>
        <div class="mt-1">
          <input
            id="email"
            ref="inputTo"
            v-model.trim="toEmail"
            :disabled="isPreview"
            type="text"
            name="email"
            :class="[
              v$['toEmail'].$error
                ? 'border-red-300 pr-10 text-red-900 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
              isPreview ? 'bg-gray-100' : '',
            ]"
            :aria-invalid="v$['toEmail'].$error"
            :aria-describedby="v$['toEmail'].$error ? `toEmail-error` : ''"
            class="block w-full rounded-md shadow-sm sm:text-sm"
            @focusout="validate"
            @click="flowStore.lastActiveInput = 'inputTo'"
          />

          <div v-if="v$['toEmail'].$error">
            <p
              v-for="error in v$['toEmail'].$errors"
              id="toEmailError"
              :key="error.$uid"
              class="mt-2 text-sm text-red-600"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <label for="from" class="block text-sm font-medium text-gray-700">{{
          t('candidateGateway.sendEmailStep.from')
        }}</label>
        <div class="mt-1">
          <input
            id="from"
            ref="inputFrom"
            v-model.trim="fromEmail"
            :disabled="isPreview"
            type="text"
            name="from"
            :aria-invalid="v$['fromEmail'].$error"
            :aria-describedby="v$['fromEmail'].$error ? `fromEmail-error` : ''"
            :class="[
              v$['fromEmail'].$error
                ? 'border-red-300 pr-10 text-red-900 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
              isPreview ? 'bg-gray-100' : '',
            ]"
            class="block w-full rounded-md shadow-sm sm:text-sm"
            @click="flowStore.lastActiveInput = 'inputFrom'"
            @focusout="validate"
          />
          <div v-if="v$['fromEmail'].$error">
            <p
              v-for="error in v$['fromEmail'].$errors"
              id="fromEmailError"
              :key="error.$uid"
              class="mt-2 text-sm text-red-600"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <label
          for="fromName"
          class="flex items-center text-sm font-medium text-gray-700"
          >{{ t('candidateGateway.sendEmailStep.fromName') }}
          <HelpPopover
            :text="t('candidateGateway.sendEmailStep.fromNamePopover')"
        /></label>
        <div class="mt-1">
          <input
            id="fromName"
            ref="inputFromName"
            v-model.trim="flowStore.activeAction.config.fromName"
            type="text"
            name="from"
            :class="[isPreview ? 'bg-gray-100' : '']"
            :disabled="isPreview"
            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            @click="flowStore.lastActiveInput = 'inputFromName'"
          />
        </div>
      </div>
      <div>
        <label for="reply-to" class="block text-sm font-medium text-gray-700">{{
          t('candidateGateway.sendEmailStep.replyTo')
        }}</label>
        <div class="mt-1">
          <input
            id="reply-to"
            ref="inputReplyTo"
            v-model.trim="replyTo"
            :disabled="isPreview"
            type="text"
            name="reply-to"
            :aria-invalid="v$['replyTo'].$error"
            :aria-describedby="v$['replyTo'].$error ? `replyTo-error` : ''"
            class="block w-full rounded-md shadow-sm sm:text-sm"
            :class="[
              v$['replyTo'].$error
                ? 'border-red-300 pr-10 text-red-900 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
              isPreview ? 'bg-gray-100' : '',
            ]"
            @click="flowStore.lastActiveInput = 'inputReplyTo'"
            @focusout="validate"
          />
          <div v-if="v$['replyTo'].$error">
            <p
              v-for="error in v$['replyTo'].$errors"
              id="replyToError"
              :key="error.$uid"
              class="mt-2 text-sm text-red-600"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-12 lg:col-span-8">
      <div>
        <label for="subject" class="block text-sm font-medium text-gray-700">{{
          t('candidateGateway.sendEmailStep.subject')
        }}</label>
        <div class="mt-1">
          <input
            id="subject"
            ref="inputSubject"
            v-model.trim="flowStore.activeAction.config.subject"
            :disabled="isPreview"
            type="text"
            name="subject"
            :class="[isPreview ? 'bg-gray-100' : '']"
            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            @click="flowStore.lastActiveInput = 'inputSubject'"
          />
        </div>
      </div>
      <div class="mt-5">
        <label for="subject" class="block text-sm font-medium text-gray-700">{{
          'Body'
        }}</label>
        <TipTap
          ref="rteEditor"
          v-model="flowStore.activeAction.config.content"
          :extras="[]"
          :isPreview="isPreview"
          :suggestionlist="suggestionlist"
          @click="flowStore.lastActiveInput = 'inputContent'"
        />
      </div>
    </div>
  </div>
  <AvailableFields v-if="!isPreview" @on-tag-click="insertTag" />
</template>

<script setup>
import { ref, nextTick, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import TipTap from '../TipTapEditor.vue'
import AvailableFields from './AvailableFields.vue'
import { useFlowStore } from '../../store/flowStore'
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import HelpPopover from '../HelpPopover.vue'

const props = defineProps({
  isPreview: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const flowStore = useFlowStore()

const toEmail = computed({
  get() {
    return flowStore.activeAction.config.to
  },
  set(value) {
    flowStore.activeAction.config.to = value
  },
})
const fromEmail = computed({
  get() {
    return flowStore.activeAction.config.from
  },
  set(value) {
    flowStore.activeAction.config.from = value
  },
})
const replyTo = computed({
  get() {
    return flowStore.activeAction.config.replyTo
  },
  set(value) {
    flowStore.activeAction.config.replyTo = value
  },
})

const emailFields = [
  '{{candidate.email}}',
  '{{site.fromEmail}}',
  '{{vacancy.contactEmail}}',
]

const emailFieldValidator = (emails) => {
  let result = true
  const values = emails?.split(';') || []
  values.forEach((email) => {
    email = email.trim()
    if (emailFields.includes(email)) {
      return
    } else if (email === '') {
      return
    } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      result = false
    }
  })
  return result
}

const multipleEmailRule = helpers.withMessage(
  t('validations.wrongMultipleEmailFormat'),
  emailFieldValidator,
)
const emailRule = helpers.withMessage(
  t('validations.wrongEmailFormat'),
  emailFieldValidator,
)

const rules = {
  toEmail: {
    multipleEmailRule,
    required: helpers.withMessage(t('validations.emptyField'), required),
  },
  replyTo: { emailRule },
  fromEmail: { emailRule },
}
const v$ = useVuelidate(rules, { toEmail, replyTo, fromEmail })

const validate = () => {
  v$.value.$touch()
}

const rteEditor = ref(null)
const inputTo = ref(null)
const inputFrom = ref(null)
const inputReplyTo = ref(null)
const inputFromName = ref(null)
const inputSubject = ref(null)

const replaceInputTag = (tag, field, input) => {
  const oldPosition = input.value.selectionStart
  flowStore.activeAction.config[field] = [
    flowStore.activeAction.config[field].slice(0, input.value.selectionStart),
    `{{${tag}}}`,
    flowStore.activeAction.config[field].slice(input.value.selectionEnd),
  ].join('')
  nextTick(() => {
    input.value.setSelectionRange(
      oldPosition + `{{${tag}}}`.length,
      oldPosition + `{{${tag}}}`.length,
    )
    input.value.focus()
  })
}

const insertTag = (tag) => {
  if (flowStore.lastActiveInput === 'inputTo') {
    replaceInputTag(tag, 'to', inputTo)
  } else if (flowStore.lastActiveInput === 'inputSubject') {
    replaceInputTag(tag, 'subject', inputSubject)
  } else if (flowStore.lastActiveInput === 'inputFrom') {
    replaceInputTag(tag, 'from', inputFrom)
  } else if (flowStore.lastActiveInput === 'inputFromName') {
    replaceInputTag(tag, 'fromName', inputFromName)
  } else if (flowStore.lastActiveInput === 'inputReplyTo') {
    replaceInputTag(tag, 'replyTo', inputReplyTo)
  } else {
    const transaction = rteEditor.value.editor.commands.insertContent(
      `<span data-type="mention" data-id="${tag}" contenteditable="false">{{${tag}}}</span>`,
    )
    rteEditor.value.editor.view.dispatch(transaction)
  }
}

const suggestionlist = computed(() => {
  const list = []
  if (!flowStore.availableFields) {
    return list
  }
  mapFields('candidate', list, ['metadata', 'customFields'])
  mapFields('vacancy', list)
  mapFields('company', list)
  mapFields('site', list)
  mapCustomFields(list)
  addMetaFields(list)
  return list
})

const mapFields = (category, list, excludeList = []) => {
  if (!flowStore.availableFields[category]) {
    return
  }
  Object.keys(flowStore.availableFields[category]).forEach((key) => {
    if (!excludeList.includes(key)) {
      list.push(`${category}.${key}`)
    }
  })
}
const mapCustomFields = (list) => {
  if (!flowStore.availableFields?.candidate?.customFields) {
    return
  }
  flowStore.availableFields.candidate.customFields.forEach((field) => {
    list.push(`candidate.customFields.${field.reference}.question`)
    list.push(`candidate.${field.reference}.answer`)
  })
}
const addMetaFields = (list) => {
  const metaFields = [
    'candidate.metadata.firstTouchUtm.campaign',
    'candidate.metadata.firstTouchUtm.content',
    'candidate.metadata.firstTouchUtm.medium',
    'candidate.metadata.firstTouchUtm.source',
    'candidate.metadata.firstTouchUtm.term',
    'candidate.metadata.lastTouchUtm.campaign',
    'candidate.metadata.lastTouchUtm.content',
    'candidate.metadata.lastTouchUtm.medium',
    'candidate.metadata.lastTouchUtm.source',
    'candidate.metadata.lastTouchUtm.term',
    'candidate.metadata.page.baseUrl',
    'candidate.metadata.page.title',
    'candidate.metadata.page.url',
    'candidate.metadata.referrer',
  ]
  list.push(...metaFields)
}
</script>
