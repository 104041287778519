export const flowActionsTypes = {
  'vacancy-apply': [
    { title: 'Send email', value: 'email', slug: 'send-email', level: 1 },
    {
      title: 'Apply email (client)',
      value: 'apply-client',
      slug: 'send-email',
      level: 2,
    },
    {
      title: 'Apply email (candidate)',
      value: 'apply-candidate',
      slug: 'send-email',
      level: 2,
    },
    {
      title: 'Send to Recruitee',
      value: 'ats-recruitee',
      slug: 'submit-candidate-to-ats-recruitee',
      level: 1,
    },
    {
      title: 'Send to Connexys',
      value: 'ats-connexys',
      slug: 'submit-candidate-to-ats-connexys',
      level: 1,
    },
    {
      title: 'Send to Mysolution',
      value: 'ats-mysolution',
      slug: 'submit-candidate-to-ats-mysolution',
      level: 1,
    },
    {
      title: 'Send to AFAS',
      value: 'ats-afas',
      slug: 'submit-candidate-to-ats-afas',
      level: 1,
    },
  ],
  'vacancy-orientation-day-apply': [
    { title: 'Send email', value: 'email', slug: 'send-email', level: 1 },
    {
      title: 'Apply email (client)',
      value: 'apply-client',
      slug: 'send-email',
      level: 2,
    },
    {
      title: 'Apply email (candidate)',
      value: 'apply-candidate',
      slug: 'send-email',
      level: 2,
    },
    {
      title: 'Send to Recruitee',
      value: 'ats-recruitee',
      slug: 'submit-candidate-to-ats-recruitee',
      level: 1,
    },
    {
      title: 'Send to Connexys',
      value: 'ats-connexys',
      slug: 'submit-candidate-to-ats-connexys',
      level: 1,
    },
    {
      title: 'Send to Mysolution',
      value: 'ats-mysolution',
      slug: 'submit-candidate-to-ats-mysolution',
      level: 1,
    },
    {
      title: 'Send to AFAS',
      value: 'ats-afas',
      slug: 'submit-candidate-to-ats-afas',
      level: 1,
    },
  ],
  'share-by-email': [
    { title: 'Send email', value: 'email', slug: 'send-email', level: 1 },
    {
      title: 'Share email (candidate)',
      value: 'share-candidate',
      slug: 'send-email',
      level: 2,
    },
  ],
  'contact-form': [
    { title: 'Send email', value: 'email', slug: 'send-email', level: 1 },
    {
      title: 'Contact form email (client)',
      value: 'contact-form-client',
      slug: 'send-email',
      level: 2,
    },
  ],
  'company-jobalert': [
    { title: 'Send email', value: 'email', slug: 'send-email', level: 1 },
    {
      title: 'Job alert email (client)',
      value: 'job-alert-client',
      slug: 'send-email',
      level: 2,
    },
  ],
  'vacancy-newsletter-signup': [
    { title: 'Send email', value: 'email', slug: 'send-email', level: 1 },
    {
      title: 'Newsletter signup email (client)',
      value: 'newsletter-signup-client',
      slug: 'send-email',
      level: 2,
    },
  ],
}
