<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.salaryScales')"
        :isLoading="salaryScaleGroupListStore.isLoading"
        :enableSearch="false"
        @update:searchTerm="updateFilter"
      >
        <router-link
          to="/salary-scales/new"
          class="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {{ t('sites.createNew') }}
        </router-link>
      </ListHeader>

      <ErrorMessage
        v-if="salaryScaleGroupListStore.error"
        :error="salaryScaleGroupListStore.error"
      />

      <List
        v-slot="{ entity: salaryScaleGroup }"
        :entities="salaryScaleGroupListStore.salaryScaleGroups"
      >
        <router-link
          :to="`/salary-scales/${salaryScaleGroup.id}`"
          class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
        >
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="truncate text-sm font-medium text-indigo-600">
                {{ salaryScaleGroup.name }}
              </p>
            </div>
          </div>
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import { useSalaryScaleGroupListStore } from '../store/salaryScaleGroupListStore'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const salaryScaleGroupListStore = useSalaryScaleGroupListStore()
salaryScaleGroupListStore.load()

const updateFilter = (filter) => {
  salaryScaleGroupListStore.filter = filter
  salaryScaleGroupListStore.page.number = 1
  salaryScaleGroupListStore.load()
}
</script>
