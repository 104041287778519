<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <PageHeader
        :title="vacancyStore.vacancy.title"
        :isLoading="vacancyStore.isLoading"
        :pill="vacancyStore.vacancy.id"
      />

      <div
        v-if="settingsStore.userRoleChanged"
        class="rounded-md bg-blue-50 p-4"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <InformationCircleIcon
              class="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-blue-700">
              {{
                t('vacancy.roleAlertTitle', {
                  publisherName: settingsStore.activePublisher.name,
                })
              }}
            </h3>
            <div class="mt-2 text-sm text-blue-600">
              <p>
                {{ t('vacancy.roleAlertText') }}
              </p>
            </div>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button
                type="button"
                class="inline-flex rounded-md bg-blue-50 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-green-50"
                @click="dismissMessage"
              >
                <span class="sr-only">Dismiss</span>
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <TabNavigation storeName="vacancy" :tabs="tabs" class="mb-5" />

      <div v-if="activeTab === 'vacancy'">
        <General />
      </div>
      <div v-if="activeTab === 'publications'">
        <EmptyStateCard
          v-if="vacancyStore.vacancy.id === 'new'"
          :title="t('vacancy.tabs.publications')"
          :message="t('vacancy.publications.vacancyIdRequired')"
        >
          <template #icon>
            <NewspaperIcon />
          </template>
        </EmptyStateCard>
        <Publications v-else />
      </div>
      <div v-if="activeTab === 'campaignPages'">
        <EmptyStateCard
          v-if="!vacancyStore.isLoading && !vacancyStore.storyblokPages.length"
          :title="t('vacancy.tabs.campaignPages')"
          :message="t('campaignPages.empty')"
        >
          <template #icon>
            <NewspaperIcon />
          </template>
        </EmptyStateCard>

        <CampaignPages v-else />
      </div>
      <div v-if="activeTab === 'candidates'">
        <div v-if="vacancyStore.vacancy.id === 'new'">
          <EmptyStateCard
            :title="t('vacancy.tabs.candidates')"
            :message="t('candidates.newMessage')"
          >
            <template #icon>
              <UsersIcon />
            </template>
          </EmptyStateCard>
        </div>
        <Candidates v-else />
      </div>
      <div v-if="activeTab === 'candidateDetail'">
        <CandidateDetail />
      </div>
      <div v-if="activeTab === 'flows'">
        <EmptyStateCard
          v-if="vacancyStore.vacancy.id === 'new'"
          :title="t('vacancy.tabs.flows')"
          :message="t('vacancy.flows.vacancyIdRequired')"
        >
          <template #icon>
            <SparklesIcon />
          </template>
        </EmptyStateCard>
        <VacancyFlow v-else />
      </div>
    </div>
  </div>
</template>

<script setup>
import General from '../components/vacancy/General.vue'
import Publications from '../components/vacancy/Publications.vue'
import TabNavigation from '../components/TabNavigation.vue'
import {
  UsersIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/20/solid'
import Candidates from '../components/vacancy/candidate/Candidates.vue'
import VacancyFlow from '../components/vacancy/VacancyFlow.vue'
import { NewspaperIcon, SparklesIcon } from '@heroicons/vue/24/outline'
import PageHeader from '../components/PageHeader.vue'
import { useI18n } from 'vue-i18n'
import { useVacancyStore } from '../store/vacancyStore'
import { useSettingsStore } from '../store/settingsStore'
import { useRoute } from 'vue-router'
import { computed, onUnmounted } from 'vue'
import EmptyStateCard from '../components/EmptyStateCard.vue'
import CandidateDetail from '../components/vacancy/candidate/CandidateDetail.vue'
import { useAuthStore } from '../store/authStore'
import CampaignPages from '../components/vacancy/CampaignPages.vue'

onUnmounted(() => {
  settingsStore.userRoleChanged = false
})

const vacancyStore = useVacancyStore()
const settingsStore = useSettingsStore()

const authStore = useAuthStore()
const route = useRoute()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const dismissMessage = () => {
  settingsStore.userRoleChanged = false
}

const tabs = authStore.isAdmin
  ? ['vacancy', 'publications', 'campaignPages', 'candidates', 'flows']
  : ['vacancy', 'publications']

vacancyStore.loadSalaryScales()
vacancyStore.load(route.params.id)

if (authStore.isAdmin) {
  vacancyStore.loadStoryblokPages(route.params.id)
  vacancyStore.loadVacancyFlows(route.params.id)
}

const activeTab = computed(() => {
  return route.name
})
</script>
