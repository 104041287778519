<template>
  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6 xl:col-span-3">
      <FormImage
        id="fullLogoUrl"
        :label="t('site.fullLogoUrl')"
        :imageUrl="fullLogoUrl"
        :rules="fullLogoUrlRules"
        :recommendation="t('site.fullLogo.recommendation')"
        @imagechange="updateFullLogoImage"
        @discard-image="discardFullLogoImage"
      />
    </div>
    <div class="col-span-6 xl:col-span-3">
      <FormImage
        id="faviconUrl"
        :label="t('site.faviconUrl')"
        :imageUrl="faviconUrl"
        :rules="faviconUrlRules"
        :recommendation="t('site.favicon.recommendation')"
        @imagechange="updateFaviconImage"
        @discard-image="discardFaviconImage"
      />
    </div>
  </div>
</template>

<script setup>
import { useSiteStore } from '../../store/siteStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import FormImage from '../FormImage.vue'
import { getImgSrc } from '../../utils/imgSrc'

const siteStore = useSiteStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const fullLogoUrl = computed(() =>
  getImgSrc({
    imgUrl: siteStore.site.fullLogoUrl,
  }),
)
const fullLogoUrlRules = {
  $autoDirty: true,
}

const updateFullLogoImage = (value) => {
  siteStore.fullLogoImage = value
}

const discardFullLogoImage = () => {
  siteStore.fullLogoImage = null
  siteStore.site.fullLogoUrl = null
}

const faviconUrl = computed(() =>
  getImgSrc({
    imgUrl: siteStore.site.faviconUrl,
  }),
)
const faviconUrlRules = {
  $autoDirty: true,
}

const updateFaviconImage = (value) => {
  siteStore.faviconImage = value
}

const discardFaviconImage = () => {
  siteStore.faviconImage = null
  siteStore.site.faviconUrl = null
}
</script>
