<template>
  <div
    v-if="
      !candidateStore.candidate.anonymizedDate && filteredCustomQuestions.length
    "
    class="mt-5 overflow-hidden bg-white shadow sm:rounded-lg"
  >
    <div
      class="flex px-4 py-5 hover:cursor-pointer sm:px-5"
      @click="$refs.collapseButton.click()"
    >
      <h3 class="grow text-lg font-medium leading-6 text-gray-900">
        {{ t('candidates.customQuestions') }}
      </h3>
      <button
        ref="collapseButton"
        class="text-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500"
        @click.stop="collapsePanel = !collapsePanel"
      >
        <ChevronDoubleUpIcon
          class="w-5 transition"
          :class="collapsePanel ? 'rotate-180' : ''"
        />
      </button>
    </div>
    <div
      class="border-t border-gray-200 transition-[max-height] duration-500"
      :class="!collapsePanel ? 'h-fit max-h-screen' : 'h-0 max-h-0'"
    >
      <dl class="sm:divide-y sm:divide-gray-200">
        <div
          v-for="question in filteredCustomQuestions"
          :key="question.id"
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ question.question }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {{ question.answer }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { useCandidateStore } from '../../../store/candidateStore'
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import { ChevronDoubleUpIcon } from '@heroicons/vue/24/outline'

const candidateStore = useCandidateStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const filteredCustomQuestions = computed(() => {
  //filter out linkedIn custom quesiton, that is wellKnown field in our case
  return (candidateStore.candidate?.customFields || []).filter(
    (question) =>
      typeof question.answer !== 'string' ||
      !question.answer.toLowerCase().includes('linkedin'),
  )
})

const collapsePanel = ref(true)
</script>
