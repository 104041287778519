<template>
  <div class="grid grid-cols-12 items-center gap-4 text-sm text-gray-700">
    <div class="col-span-12">
      <button
        type="button"
        class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="addNewVideo"
      >
        <PlusCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
        {{ t('vacancy.addVideo') }}
      </button>
    </div>
    <div
      v-for="(video, index) in videoUrls"
      :key="index"
      class="col-span-12 items-center gap-x-2"
    >
      <div class="ml-1 flex items-end gap-x-2">
        <label class="block text-sm font-medium text-gray-700"
          >Video {{ index + 1 }}</label
        >
      </div>
      <div class="flex items-center gap-x-2">
        <div class="h-16 grow">
          <FormVideo
            :video="video"
            :index="index"
            @videoLinkUpdate="updateVideo"
          />
        </div>
        <button
          type="button"
          class="mb-4 w-4 cursor-pointer"
          @click="discardVideo(index)"
        >
          <XMarkIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import FormVideo from './FormVideo.vue'
import { XMarkIcon, PlusCircleIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  videoUrls: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['updateVideos'])
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const addNewVideo = () => {
  const videoLinks = [...props.videoUrls]
  videoLinks.push('')
  emit('updateVideos', videoLinks)
}

const discardVideo = (index) => {
  const videoLinks = [...props.videoUrls]
  videoLinks.splice(index, 1)
  emit('updateVideos', videoLinks)
}

const updateVideo = ({ value, index }) => {
  const videoLinks = [...props.videoUrls]
  videoLinks[index] = value
  emit('updateVideos', videoLinks)
}
</script>
