<template>
  <div
    v-if="
      candidateStore.candidate.contactQuestion &&
      !candidateStore.candidate.anonymizedDate
    "
    class="mt-5 overflow-hidden bg-white shadow sm:rounded-lg"
  >
    <div
      class="flex px-4 py-5 hover:cursor-pointer sm:px-5"
      @click="$refs.collapseButton.click()"
    >
      <h3 class="grow text-lg font-medium leading-6 text-gray-900">
        {{ t('candidates.contactQuestion') }}
      </h3>
      <button
        ref="collapseButton"
        class="text-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500"
        @click.stop="collapsePanel = !collapsePanel"
      >
        <ChevronDoubleUpIcon
          class="w-5 transition"
          :class="collapsePanel ? 'rotate-180' : ''"
        />
      </button>
    </div>
    <div
      class="break-words border-t border-gray-200 transition-[max-height] duration-500"
      :class="!collapsePanel ? 'h-fit max-h-screen' : 'h-0 max-h-0'"
    >
      <div class="flex items-baseline gap-4 px-8 pb-6 pt-4">
        <svg
          class="h-10 w-10 text-gray-400"
          fill="currentColor"
          viewBox="0 0 32 32"
          aria-hidden="true"
        >
          <path
            d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
          />
        </svg>
        <p class="text-sm text-gray-900">
          {{ candidateStore.candidate.contactQuestion }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCandidateStore } from '../../../store/candidateStore'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { ChevronDoubleUpIcon } from '@heroicons/vue/24/outline'

const candidateStore = useCandidateStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const collapsePanel = ref(false)
</script>
