<template>
  <ul class="flex flex-col space-y-6">
    <Site
      v-for="site in vacancyStore.sites"
      :key="site.id"
      :site="site"
      class="bg-white shadow sm:rounded-md"
    />
  </ul>
  <div v-if="vacancyStore.sites.length">
    <TwoColumnFormDivider />
    <SocialCampaigns />
    <Jobmarketing />
  </div>

  <FormSave
    :validationErrors="v$.$errors"
    :validationTouch="v$.$touch"
    storeAction="savePublications"
    storeName="vacancy"
  />
</template>

<script setup>
import Site from './Site.vue'
import FormSave from '../FormSave.vue'
import SocialCampaigns from './SocialCampaigns.vue'
import Jobmarketing from './Jobmarketing.vue'
import { useVacancyStore } from '../../store/vacancyStore'
import TwoColumnFormDivider from '../TwoColumnFormDivider.vue'
import { useVuelidate } from '@vuelidate/core'

const vacancyStore = useVacancyStore()
const v$ = useVuelidate()
</script>
