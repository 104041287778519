<template>
  <span
    ref="tooltipBtn"
    :tabindex="settingsStore.tooltipKeyboardNavigation ? 0 : -1"
    class="text-indigo-600 outline-none transition-all duration-150 ease-linear hover:text-indigo-400 focus:text-indigo-400"
    @focusin="toggleTooltip"
    @focusout="toggleTooltip"
    @mouseenter="toggleTooltip"
    @mouseleave="toggleTooltip"
  >
    <QuestionMarkCircleIcon class="h-4 w-4" aria-hidden="true" />
  </span>
  <div
    v-if="tooltipShow"
    ref="tooltipContent"
    role="tooltip"
    :style="floatingStyles"
    class="z-50 ml-1 flex max-w-xs break-words rounded-lg border bg-white text-left text-sm font-normal leading-normal no-underline shadow"
  >
    <div>
      <div
        v-if="title"
        class="border-blueGray-100 mb-0 rounded-t-lg border-b border-solid bg-indigo-600 p-3 font-semibold uppercase text-black opacity-75"
      >
        {{ title }}
      </div>
      <div class="p-3 text-black">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
import { ref } from 'vue'
import { useFloating } from '@floating-ui/vue'
import { useSettingsStore } from '../store/settingsStore'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  placement: {
    type: String,
    default: 'bottom-start',
  },
})

const settingsStore = useSettingsStore()
const tooltipBtn = ref(null)
const tooltipContent = ref(null)
const { floatingStyles } = useFloating(tooltipBtn, tooltipContent, {
  placement: props.placement,
})

let tooltipShow = ref(false)

const toggleTooltip = () => {
  if (tooltipShow.value) {
    tooltipShow.value = false
  } else {
    tooltipShow.value = true
  }
}
</script>
