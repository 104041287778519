import { defineStore } from 'pinia'
import tooManagement from '../api/tooManagement'

export const usePublisherListStore = defineStore('publisherListStore', {
  state: () => ({
    isLoading: false,
    error: null,
    publishers: [],
    filter: '',
    includeArchived: false,
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load() {
      this.error = null
      this.isLoading = true

      try {
        const publishers = await tooManagement.getPublishers({
          filter: this.filter,
          page: this.page.number,
          size: this.page.size,
          sortBy: 'id',
          sortOrder: 'desc',
          includeArchived: this.includeArchived,
        })
        // Check if this is the page we requested.
        // Useful if a user requests the next page before the previous page was loaded.
        if (publishers.meta.page.number === this.page.number) {
          this.publishers = publishers.data
          this.page = publishers.meta.page
        }
      } catch (error) {
        this.publishers = []
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
  },
})
