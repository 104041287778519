<template>
  <div class="py-6">
    <div class="max-w-7xl">
      <EmptyStateCard
        v-if="isEmpty"
        class="mt-5"
        :title="t('menu.jobAlerts')"
        :message="t('jobAlerts.noAlerts')"
      >
        <template #icon>
          <BellAlertIcon class="" />
        </template>
      </EmptyStateCard>

      <ErrorMessage v-if="jobAlertStore.error" :error="jobAlertStore.error" />

      <div
        v-if="!isEmpty"
        class="text-md ml-1.5 py-2 font-semibold text-gray-900"
      >
        {{ t('jobAlerts.vacanciesSent') }}
      </div>
      <List
        v-if="!isEmpty"
        v-slot="{ entity: jobAlertVacancy }"
        :sortOptions="[
          /* add sort options when sorting is ready on BE*/
        ]"
        :entities="jobAlertStore.vacancies"
        :page="jobAlertStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
      >
        <div>
          <div
            class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
          >
            <div class="px-4 py-4 sm:px-6">
              <div class="flex items-center justify-between">
                <p class="truncate text-sm font-medium text-indigo-600">
                  {{ jobAlertVacancy.title }}
                </p>
                <div class="ml-auto flex flex-col gap-1 md:flex-row">
                  <span
                    v-if="jobAlertVacancy.archived"
                    class="ml-0 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 xl:ml-auto"
                    >{{ t('archived') }}</span
                  >
                  <span
                    v-show="jobAlertVacancy?.published"
                    class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
                  >
                    {{ t('vacancy.publication.badges.published') }}
                  </span>
                  <div class="flex shrink-0"></div>
                  <p
                    class="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800"
                  >
                    № {{ jobAlertVacancy.id }}
                  </p>
                </div>
              </div>
              <div class="mt-2 sm:flex sm:justify-between">
                <div class="sm:flex">
                  <p class="flex items-center text-xs text-gray-500">
                    {{ jobAlertVacancy.companyName }}
                  </p>
                </div>
                <div
                  class="mt-2 flex items-center text-xs text-gray-500 sm:mt-0"
                >
                  <span class="flex gap-x-1">
                    <CalendarIcon class="h-4 w-4" />{{
                      format(jobAlertVacancy.creationDate, 'dd/MM/yyyy')
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </List>
    </div>
  </div>
</template>

<script setup>
import ErrorMessage from '../ErrorMessage.vue'
import EmptyStateCard from '../EmptyStateCard.vue'
import { useRoute } from 'vue-router'
import List from '../List.vue'
import { BellAlertIcon, CalendarIcon } from '@heroicons/vue/24/outline'
import { useJobAlertStore } from '../../store/jobAlertStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const route = useRoute()
const jobAlertStore = useJobAlertStore()

const isEmpty = computed(
  () => !jobAlertStore.isLoading && jobAlertStore.vacancies.length === 0,
)

const updatePageNumber = (number) => {
  jobAlertStore.page.number = number
  jobAlertStore.load(route.params.id)
}
const updatePageSize = (pageSize) => {
  jobAlertStore.page.size = pageSize
  jobAlertStore.load(route.params.id)
}
</script>
