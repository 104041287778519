import {defineStore} from 'pinia'
import tooManagement from '../api/tooManagement'

export const useCompanyListStore = defineStore('companyListStore', {
  state: () => ({
    isLoading: false,
    error: null,
    companies: [],
    filter: '',
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load() {
      this.error = null
      this.isLoading = true

      try {
        const companies = await tooManagement.getCompanies(
          {
            filter: this.filter,
            page: this.page.number,
            size: this.page.size,
          },
          true
        )
        // Check if this is the page we requested.
        // Useful if a user requests the next page before the previous page was loaded.
        if (companies.meta.page.number === this.page.number) {
          this.companies = companies.data
          this.page = companies.meta.page
        }

        this.isLoading = false
      } catch (error) {
        this.companies = []
        this.isLoading = false
        this.error = error
      }
    },
  }
})
