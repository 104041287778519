<template>
  <Listbox
    v-slot="{ open }"
    v-model="selectedATS"
    class="col-span-6"
    :disabled="!!importerStore.activeConfig.id"
  >
    <div class="relative mt-1">
      <ListboxLabel class="block text-sm font-medium text-gray-700">{{
        t('company.importer.type')
      }}</ListboxLabel>
      <ListboxButton
        class="relative mt-1 min-h-[2.4rem] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
      >
        <span
          class="block truncate"
          :class="{ 'text-gray-500': !!importerStore.activeConfig.id }"
          >{{ selectedATS.label }}</span
        >
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronDownIcon
            class="mr-1 h-5 w-5 text-gray-400"
            :class="open ? 'rotate-180' : 'rotate-0'"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leaveActiveClass="transition duration-100 ease-in"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <ListboxOptions
          class="absolute z-[1] max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption value="" />
          <ListboxOption
            v-for="ats in importerStore.availableATS"
            v-slot="{ active, selected }"
            :key="ats.value"
            :value="ats"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900',
                'relative cursor-default select-none px-4 py-2',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
                >{{ ats.label }}</span
              >
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useImporterStore } from '../../store/importerStore'

const importerStore = useImporterStore()

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const selectedATS = computed({
  get() {
    return (
      importerStore.availableATS.find(
        (ats) => ats.value === importerStore.activeConfig.ats,
      ) || {}
    )
  },
  set(newValue) {
    importerStore.resetConfig()
    importerStore.activeConfig.ats = newValue.value
  },
})
</script>
