<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <div class="flex items-center gap-x-2">
        <PageHeader
          v-if="!salaryScaleGroupNameEdit"
          :title="salaryScaleGroupName"
          :isLoading="salaryScaleGroupStore.isLoading"
        />

        <div v-else class="h-12 w-full">
          <FormInput
            id="salaryScaleGroupName"
            type="text"
            :value="salaryScaleGroupName"
            :onInput="editSalaryScaleGroupName"
            :rules="nameRequired"
          />
        </div>

        <button
          type="button"
          class="w-4 text-gray-600"
          @click="toggleGroupNameChange"
        >
          <PencilSquareIcon v-if="!salaryScaleGroupNameEdit" class="mb-3" />
          <CheckIcon v-else />
        </button>
      </div>

      <div class="mt-9">
        <div class="justify-end sm:flex sm:items-center">
          <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              class="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="addNewScale"
            >
              {{ t('salaryScales.addScale') }}
            </button>
          </div>
        </div>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <div
                class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {{ t('salaryScales.table.id') }}
                      </th>
                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {{ t('salaryScales.table.name') }}
                      </th>
                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {{ t('salaryScales.table.min') }}
                      </th>
                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {{ t('salaryScales.table.max') }}
                      </th>
                      <th
                        scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <span class="sr-only">
                          {{ t('salaryScales.edit') }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr
                      v-for="(scale, index) in salaryScaleGroupStore.scales"
                      :key="scale.id"
                      class="align-top hover:bg-gray-50"
                    >
                      <td
                        :class="{
                          'pb-8': v$.$error && activeEditRow === index,
                        }"
                        class="whitespace-nowrap py-4 pl-4 pr-3 align-middle text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        {{ scale.id }}
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        <span v-if="activeEditRow !== index">{{
                          scale.name
                        }}</span>
                        <span v-else>
                          <FormInput
                            :id="`scale-name-${index}`"
                            type="text"
                            :value="scale.name"
                            :onInput="updateScaleName"
                            :rules="nameRequired"
                            :inputParams="{ index }"
                          />
                        </span>
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        <span v-if="activeEditRow !== index">{{
                          scale.min
                        }}</span>
                        <span v-else>
                          <FormInput
                            :id="`scale-min-${index}`"
                            type="number"
                            :value="scale.min"
                            :onInput="updateScaleField"
                            :rules="minValueRule"
                            :inputParams="{ index, fieldName: 'min' }"
                          />
                        </span>
                      </td>
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        <span v-if="activeEditRow !== index">{{
                          scale.max
                        }}</span>
                        <span v-else>
                          <FormInput
                            :id="`scale-max-${index}`"
                            type="number"
                            :value="scale.max"
                            :onInput="updateScaleField"
                            :rules="minValueRule"
                            :inputParams="{ index, fieldName: 'max' }"
                          />
                        </span>
                      </td>
                      <td
                        :class="{
                          'pb-8': v$.$error && activeEditRow === index,
                        }"
                        class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right align-middle text-sm font-medium sm:pr-6"
                      >
                        <button
                          type="button"
                          class="text-indigo-600 hover:text-indigo-900"
                          @click="toggleRowEdit(index)"
                        >
                          <CheckIcon
                            v-if="activeEditRow === index"
                            class="w-5 text-gray-500"
                          />
                          <PencilIcon v-else class="w-5 text-gray-500" />
                          <span class="sr-only">, {{ scale.name }}</span>
                        </button>
                        <button
                          v-if="scale.id === 'new'"
                          type="button"
                          class="text-indigo-600 hover:text-indigo-900"
                          @click="deleteScaleRow(index)"
                        >
                          <TrashIcon
                            v-if="activeEditRow !== index"
                            class="ml-4 w-5 text-gray-500"
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="saveBtn" ref="saveBtn" class="mt-8">
        <FormSave
          :validationErrors="v$.$errors"
          :validationTouch="v$.$touch"
          :additionalParams="{
            salaryScaleGroupId: route.params.id,
            name: salaryScaleGroupName,
          }"
          storeName="salaryScaleGroup"
          storeAction="save"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref, computed, onUnmounted } from 'vue'
import PageHeader from '../components/PageHeader.vue'
import FormSave from '../components/FormSave.vue'
import FormInput from '../components/FormInput.vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, minValue } from '@vuelidate/validators'
import {
  CheckIcon,
  PencilIcon,
  TrashIcon,
  PencilSquareIcon,
} from '@heroicons/vue/20/solid'
import { useSalaryScaleGroupStore } from '../store/salaryScaleGroupStore'
import { nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSalaryScaleGroupListStore } from '../store/salaryScaleGroupListStore'

onUnmounted(() => {
  salaryScaleGroupStore.$reset()
  salaryScaleGroupListStore.$reset()
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const v$ = useVuelidate()
const route = useRoute()
const saveBtn = ref(null)
const salaryScaleGroupNameEdit = ref(false)
const activeEditRow = ref(null)
const salaryScaleGroupStore = useSalaryScaleGroupStore()
const salaryScaleGroupListStore = useSalaryScaleGroupListStore()

salaryScaleGroupStore.load(route.params.id)
salaryScaleGroupListStore.load()

const nameRequired = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const minValueRule = {
  min: helpers.withMessage(t('validations.minScaleValue'), minValue(0)),
}

const salaryScaleGroupName = computed(() => {
  if (route.params.id === 'new') {
    return salaryScaleGroupStore.tempSalaryScaleGroupName
  }
  const group = salaryScaleGroupListStore.salaryScaleGroups.find((group) => {
    return group.id == route.params.id
  })
  return group?.name
})

const addNewScale = () => {
  salaryScaleGroupStore.scales.push({
    id: 'new',
    name: 'Salary scale',
    min: 0,
    max: 0,
  })
  nextTick(() => {
    activeEditRow.value = salaryScaleGroupStore.scales.length - 1
    saveBtn.value.scrollIntoView({
      behavior: 'smooth',
    })
  })
}

const toggleRowEdit = (rowIndex) => {
  if (
    salaryScaleGroupStore.scales[rowIndex].id !== 'new' &&
    activeEditRow.value === rowIndex
  ) {
    salaryScaleGroupStore.editedScalesIds.push(
      salaryScaleGroupStore.scales[rowIndex].id
    )
  }
  if (activeEditRow.value === rowIndex) {
    v$.value.$touch()
    if (!v$.value.$error) {
      activeEditRow.value = null
      return
    }
  }
  activeEditRow.value = rowIndex
}

const toggleGroupNameChange = () => {
  v$.value.$touch()
  if (!v$.value.$error) {
    salaryScaleGroupNameEdit.value = !salaryScaleGroupNameEdit.value
  }
}

const editSalaryScaleGroupName = (value) => {
  if (route.params.id === 'new') {
    salaryScaleGroupStore.tempSalaryScaleGroupName = value
  } else {
    const group = salaryScaleGroupListStore.salaryScaleGroups.find((group) => {
      return group.id == route.params.id
    })
    group.name = value
  }
}

const updateScaleField = (value, { index, fieldName }) => {
  salaryScaleGroupStore.scales[index][fieldName] = parseInt(value)
}
const updateScaleName = (value, { index }) => {
  salaryScaleGroupStore.scales[index].name = value
}

const deleteScaleRow = (index) => {
  salaryScaleGroupStore.scales.splice(index, 1)
}
</script>
