<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="mb-3 text-2xl font-semibold text-gray-900">Dashboard</h1>
      <!-- Replace with your content -->
      <div class="py-4">
        <div
          class="h-96 rounded-lg border-4 border-dashed border-gray-200"
        ></div>
      </div>
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'Dashboard',
  setup() {
    const router = useRouter()
    router.push('/vacancies')
  },
}
</script>
