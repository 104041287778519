<template>
  <div
    v-if="subscription.state === 'CREATED' && subscription.features?.length"
    class="mb-4 rounded-md bg-blue-50 p-4"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <InformationCircleIcon
          class="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-blue-700">
          {{ t('company.subscription.activationMessage') }}
        </h3>
      </div>
    </div>
  </div>

  <TwoColumnForm
    :title="t('company.tabs.companySubscription')"
    class="relative"
  >
    <div
      v-if="subscriptionStore.subscriptions.length"
      class="left-0 top-10 mb-6 w-72 md:absolute"
    >
      <button
        type="button"
        class="inline-flex items-center rounded-md border border-indigo-600 bg-transparent px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        :disabled="
          subscriptionStore.isLoading ||
          subscriptionStore.isSaving ||
          !subscriptionStore.selectedSubscription.id
        "
        @click="subscriptionStore.addSubscription()"
      >
        {{ t('company.subscription.addSubscription') }}
      </button>

      <div
        v-if="subscriptionStore.subscriptions.length > 1"
        ref="subscriptionListElement"
        class="mt-2 flex max-h-[6.75rem] flex-col overflow-y-auto pb-1 pr-1 md:mt-8"
      >
        <p class="mb-2 text-xs font-medium">
          {{ t('company.subscription.existingSubscriptions') }}
        </p>
        <button
          v-for="(sub, index) in subscriptionStore.subscriptions"
          :key="sub.id"
          class="flex items-center justify-between rounded-md px-2 py-1 text-left text-sm"
          :class="{
            'bg-indigo-100':
              sub.id === subscriptionStore.selectedSubscription.id,
          }"
          :disabled="subscriptionStore.isLoading || subscriptionStore.isSaving"
          @click="subscriptionStore.changeSelectedSubscription(index)"
        >
          <div>
            <span
              class="capitalize"
              :class="{
                'font-bold text-green-600': sub.state === 'ACTIVATED',
                'text-red-700': sub.state === 'CANCELLED',
                italic: !sub.id,
              }"
              >{{ subscriptionLabel(sub) }}</span
            >
            <span v-if="sub.id" class="ml-2 text-xs text-gray-600"
              >({{ format(sub.startDate, 'dd/MM/yyyy') }}-{{
                format(sub.endDate, 'dd/MM/yyyy')
              }})</span
            >
          </div>
          <span
            v-show="sub.id === subscriptionStore.selectedSubscription.id"
            class="hidden md:inline"
            >&gt;</span
          >
        </button>
      </div>
    </div>

    <div class="lg:col-start-3 lg:row-end-1">
      <h2 class="sr-only">
        {{ t('company.subscription.subscriptionSummary') }}
      </h2>

      <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <div class="flex flex-wrap">
          <div class="flex w-full gap-x-2 px-6 pt-4">
            <div class="text-sm font-semibold leading-6 text-gray-900">
              {{ t('company.subscription.subscription') }}
            </div>
            <div
              v-if="subscription.state"
              :class="{
                'text-indigo-700 ring-indigo-600/20':
                  subscription.state === 'CREATED',
                'text-red-700 ring-red-600/20':
                  subscription.state === 'CANCELLED',
                'text-green-700 ring-green-600/20':
                  subscription.state === 'ACTIVATED',
                'text-yellow-700 ring-yellow-600/20':
                  subscription.state === 'EXPIRED',
              }"
              class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
            >
              {{ t(`company.subscription.${subscription.state}`) }}
            </div>
            <div v-if="subscription.state === 'CREATED'" class="ml-auto">
              <button
                type="button"
                class="rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
                @click="activateSubscription"
              >
                {{ t('company.subscription.activate') }}
              </button>
            </div>
            <div
              v-if="subscription.state === 'ACTIVATED'"
              class="ml-auto flex gap-x-2"
            >
              <button
                type="button"
                class="rounded bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm hover:bg-red-100"
                @click="cancelSubscription"
              >
                {{ t('company.subscription.cancel') }}
              </button>
            </div>
            <div
              v-if="subscription.state === 'CANCELLED'"
              class="ml-auto flex gap-x-2"
            >
              <button
                type="button"
                class="rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                @click="reopenSubscription"
              >
                {{ t('company.subscription.reopen') }}
              </button>
            </div>
          </div>
          <div class="flex w-full items-end px-5">
            <Listbox
              v-slot="{ open }"
              v-model="selectedPlan"
              class="w-full"
              :disabled="!subscriptionEditable"
            >
              <div class="relative mt-2">
                <ListboxButton
                  class="relative mt-1 min-h-[2.4rem] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:bg-gray-100 sm:text-sm"
                >
                  <span class="block truncate">{{ selectedPlan.label }}</span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronDownIcon
                      class="mr-1 h-5 w-5 text-gray-400"
                      :class="open ? 'rotate-180' : 'rotate-0'"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leaveActiveClass="transition duration-100 ease-in"
                  leaveFromClass="opacity-100"
                  leaveToClass="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-[1] max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption value="" />
                    <ListboxOption
                      v-for="plan in subscriptionStore.availablePlans"
                      v-slot="{ active, selected }"
                      :key="plan.value"
                      :value="plan"
                      as="template"
                    >
                      <li
                        :class="[
                          active
                            ? 'bg-indigo-100 text-indigo-900'
                            : 'text-gray-900',
                          'relative cursor-default select-none px-4 py-2',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate',
                          ]"
                          >{{ plan.label }}</span
                        >
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>

          <div
            v-if="v$.selectedPlan.$errors.length"
            class="ml-6 mt-4 flex items-center gap-x-2"
          >
            <ExclamationCircleIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <p
              v-for="(error, index) in v$.selectedPlan.$errors"
              id="importer-sites-error"
              :key="index"
              class="text-sm text-red-600"
            >
              {{ error.$message }}
            </p>
          </div>

          <div
            class="mt-3 grid w-full grid-cols-8 items-center gap-x-4 gap-y-4 border-t border-gray-900/5 px-6 py-3"
          >
            <div
              class="col-span-8 flex flex-auto items-center gap-x-4 lg:col-span-5"
            >
              <label
                for="subscriptionPeriod"
                class="flex items-center gap-x-1 text-gray-600"
              >
                <CalendarDaysIcon class="h-5 w-5" />
                {{ t('company.subscription.period') }}</label
              >

              <DatePicker
                id="subscriptionPeriod"
                range
                :value="subscriptionPeriod"
                type="dateTime"
                format="DD MMMM YYYY"
                inputClass="focus:border-indigo-500 border-gray-300 disabled:bg-gray-100 focus:border-indigo-500 focus:ring-indigo-500 w-full block rounded-md shadow-sm focus:outline-none sm:text-sm"
                :clearable="true"
                :editable="false"
                :disabled="!subscriptionEditable"
                @change="updateDateRange"
              />
            </div>

            <div class="col-span-8 ml-auto flex items-center lg:col-span-3">
              <label
                for="autoRenew"
                class="ml-1 mr-2 flex items-center gap-x-1 text-gray-600"
              >
                <ArrowPathIcon class="h-5 w-5" />
                {{ t('company.subscription.autoRenew') }}</label
              >

              <Toggle
                srLabel="autoRenew"
                :checked="subscription.autoRenew"
                :onToggle="toggleAutoRenew"
                :disabled="!subscriptionEditable"
              />
            </div>
          </div>
          <div
            v-if="v$.subscriptionPeriod.$errors.length"
            class="mb-4 ml-6 flex items-center gap-x-2"
          >
            <ExclamationCircleIcon
              class="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <p
              v-for="(error, index) in v$.subscriptionPeriod.$errors"
              id="importer-sites-error"
              :key="index"
              class="text-sm text-red-600"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="flex w-full border-t border-gray-900/5 px-6 py-3">
            <button
              v-if="selectedPlan.value"
              :disabled="!subscriptionEditable"
              class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 disabled:text-gray-500"
              @click="addSubscriptionFeature"
            >
              <PlusCircleIcon class="h-4 w-4" />
              {{ t('company.subscription.newFeature') }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-4"></div>
    </div>
  </TwoColumnForm>

  <SubscriptionFeature
    v-for="(subscriptionFeature, index) in subscription.features"
    :key="subscriptionFeature.id"
    :subscriptionFeature="subscriptionFeature"
    :subscription="subscription"
    :disabled="!subscriptionEditable"
    :index="index"
  />
  <div class="mt-4 flex justify-end">
    <FormSave
      :validationErrors="v$.$errors"
      :validationTouch="v$.$touch"
      storeAction="saveSubscription"
      storeName="subscription"
      :additionalParams="{
        principalId: route.params.id,
        principalType,
      }"
    />
  </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import DatePicker from 'vue-datepicker-next'
import { format } from 'date-fns'
import 'vue-datepicker-next/index.css'
import SubscriptionFeature from './SubscriptionFeature.vue'
import {
  CalendarDaysIcon,
  ArrowPathIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/vue/20/solid'
import { useSubscriptionStore } from '../../store/subscriptionStore'
import FormSave from '../FormSave.vue'
import { ref, computed, watch, onUnmounted, nextTick } from 'vue'
import Toggle from '../Toggle.vue'
import { helpers } from '@vuelidate/validators'
import TwoColumnForm from '../TwoColumnForm.vue'

const props = defineProps({
  principalType: {
    type: String,
    required: true,
    validator(value) {
      // The value must match one of these strings
      return ['COMPANY', 'PUBLISHER'].includes(value)
    },
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const route = useRoute()
const subscriptionStore = useSubscriptionStore()

subscriptionStore.load(
  route.params.id,
  props.principalType,
  route.query.subscriptionId,
)

onUnmounted(() => {
  subscriptionStore.$reset()
})

const subscriptionListElement = ref(null)

const subscription = computed(() => {
  return subscriptionStore.selectedSubscription
})

const subscriptionPeriod = computed(() => {
  return subscription.value.startDate && subscription.value.endDate
    ? [
        new Date(subscription.value.startDate),
        new Date(subscription.value.endDate),
      ]
    : [null, null]
})

const selectedPlan = computed({
  get() {
    return (
      subscriptionStore.availablePlans.find(
        (plan) => plan.value === subscriptionStore.selectedSubscription.plan,
      ) || {}
    )
  },
  set(newValue) {
    subscriptionStore.selectedSubscription.plan = newValue.value
  },
})

const subscriptionEditable = computed(
  () =>
    subscription.value.state !== 'CANCELLED' &&
    subscription.value.state !== 'EXPIRED',
)

const addSubscriptionFeature = () => {
  const example = {
    id: 'new',
    slug: 'publish-on-site',
    resources: [{ type: 'site', id: 10, name: 'Gemeentebanen' }],
    planCredits: 10,
    payAsYouGo: true,
    reversible: true,
    disabled: false,
    usages: [],
  }
  subscriptionStore.selectedSubscription.features.push(example)
}

const activateSubscription = () => {
  subscriptionStore.changeSubscriptionState('ACTIVATED')
}
const cancelSubscription = () => {
  subscriptionStore.changeSubscriptionState('CANCELLED')
}
const reopenSubscription = () => {
  subscriptionStore.changeSubscriptionState('CREATED')
}

const toggleAutoRenew = (value) => {
  subscriptionStore.selectedSubscription.autoRenew = value
}

const updateDateRange = (date) => {
  subscriptionStore.selectedSubscription.startDate = date[0]
  subscriptionStore.selectedSubscription.endDate = date[1]
}

const subscriptionLabel = (subscription) => {
  if (!subscription.id) {
    return t(`company.subscription.unsaved`)
  }

  return t(`subscriptions.${subscription.plan}`)
}

const validatePlan = (plan) => {
  return Boolean(plan.value)
}

const validatePeriod = (period) => {
  return period[0] instanceof Date && period[1] instanceof Date
}

const v$ = useVuelidate(
  {
    selectedPlan: {
      required: helpers.withMessage(
        t('company.subscription.validations.emptyPlan'),
        validatePlan,
      ),
    },
    subscriptionPeriod: {
      required: helpers.withMessage(
        t('company.subscription.validations.emptyPeriod'),
        validatePeriod,
      ),
    },
  },
  { selectedPlan, subscriptionPeriod },
)

watch(selectedPlan, () => v$.value.selectedPlan.$touch())
watch(subscriptionPeriod, () => v$.value.subscriptionPeriod.$touch())
watch(
  () => subscription.value.id,
  () => v$.value.$reset(),
)
watch(
  () => subscriptionStore.subscriptions.length,
  async (newAmount) => {
    if (newAmount > 0) {
      await nextTick()
      if (subscriptionListElement.value) {
        // We scroll to the bottom of the list just in case, since there's not a lot of vertical space
        // in this container
        subscriptionListElement.value.scrollTop =
          subscriptionListElement.value.scrollHeight
      }
    }
  },
)
</script>
