import regionMapping from './regionMapping'

const locationIQEndpointURL = 'https://eu1.locationiq.com'
const locationIQAPIKey = 'pk.5fdc8fa072e4284ac8441b92636b5b16'

const addressIsNotEmpty = (address) =>
  address &&
  (address.street || address.houseNumber || address.city || address.zipCode)

const searchLocation = async (query, language = 'nl') => {
  try {
    const response = await fetch(
      `${locationIQEndpointURL}/v1/autocomplete.php?key=${locationIQAPIKey}&q=${query}&normalizecity=1&accept-language=${language}`,
    )

    const data = await response.json()

    // check for error response
    if (!response.ok) {
      return []
    }

    return data.map((location) => ({
      label: location.display_name,
      value: location,
    }))
  } catch (error) {
    return []
  }
}

const getRegion = async ({ latitude, longitude }) => {
  if (latitude && longitude) {
    try {
      const response = await fetch(
        `${locationIQEndpointURL}/v1/reverse.php?key=${locationIQAPIKey}&lat=${latitude}&lon=${longitude}&format=json&countrycodes=nl&normalizecity=1&accept-language=nl`,
      )
      const data = await response.json()
      if (!response.ok) {
        return ''
      }
      return regionMapping[data.address.state] || ''
    } catch (error) {
      return ''
    }
  }
  return ''
}

export { locationIQAPIKey, addressIsNotEmpty, searchLocation, getRegion }
