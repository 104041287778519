<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.flows')"
        :isLoading="flowListStore.isLoading"
        :enableSearch="!isEmpty"
        :searchPlaceholder="t('vacancy.flows.searchFlow')"
        :searchTerm="flowListStore.filter"
        @update:searchTerm="updateFilter"
      >
        <div class="relative z-20 inline-flex">
          <Menu as="span" class="relative -ml-px block">
            <MenuButton
              class="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span class="sr-only">{{
                t('vacancy.flows.menu.openOptions')
              }}</span>
              <span class="mr-1">{{ t('vacancy.flows.newFlow') }}</span>
              <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <transition
              enterActiveClass="transition ease-out duration-100"
              enterFromClass="transform opacity-0 scale-95"
              enterToClass="transform opacity-100 scale-100"
              leaveActiveClass="transition ease-in duration-75"
              leaveFromClass="transform opacity-100 scale-100"
              leaveToClass="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1">
                  <MenuItem
                    v-for="flow in flowListStore.flowTypes"
                    :key="flow.name"
                    @click="addNewFlow(flow.value)"
                  >
                    <span
                      class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                    >
                      {{ t(`candidates.flowType.${flow.value}`) }}
                    </span>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </ListHeader>
      <EmptyStateCard
        v-if="isEmpty"
        class="mt-5"
        :title="t('vacancy.tabs.flows')"
        :message="t('vacancy.flows.emptyFlowList')"
      >
        <template #icon>
          <SparklesIcon class="" />
        </template>
      </EmptyStateCard>
      <div
        v-if="!isEmpty"
        class="mb-4 flex flex-wrap gap-6 rounded-md pl-0.5 md:flex-row"
      >
        <div
          class="flex w-full flex-col items-center gap-2 md:w-auto md:flex-row"
        >
          <Listbox
            v-model="flowTypeSlug"
            as="div"
            class="flex w-full flex-col gap-2 md:flex-row md:items-center"
            @update:modelValue="updateFilter('')"
          >
            <ListboxLabel class="block text-sm font-medium text-gray-700">
              {{ t('candidates.filters.flowType') }}
            </ListboxLabel>
            <div class="relative md:w-60">
              <ListboxButton
                class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              >
                <span class="ml-2 block truncate">{{
                  flowTypeSlug
                    ? t(`candidates.flowType.${flowTypeSlug}`)
                    : t('candidates.filters.all')
                }}</span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2"
                >
                  <ChevronUpDownIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <transition
                leaveActiveClass="transition ease-in duration-100"
                leaveFromClass="opacity-100"
                leaveToClass="opacity-0"
              >
                <ListboxOptions
                  class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    v-for="(type, index) in flowTypes"
                    :key="index"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="type"
                  >
                    <li
                      :class="[
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      ]"
                    >
                      <span
                        :class="[
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate',
                        ]"
                      >
                        {{
                          type
                            ? t(`candidates.flowType.${type}`)
                            : t('candidates.filters.all')
                        }}
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
          <div class="flex w-full items-center gap-2 md:w-auto">
            <input
              id="includeArchived"
              :checked="flowListStore.includeArchived"
              type="checkbox"
              @change="toggleIncludeArchived"
            />
            <label
              for="includeArchived"
              class="inline-flex items-center gap-1 text-sm font-medium text-gray-700"
            >
              {{ t('include') }}
              <!-- {{ t('candidates.filters.include') }} -->
              <span
                class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                >{{ t('archived') }}</span
              ></label
            >
          </div>
        </div>
      </div>
      <List
        v-if="!isEmpty"
        v-slot="{ entity: flow }"
        :entities="flowListStore.flows"
        :page="flowListStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
      >
        <router-link
          :to="`/flows/${flow.id}`"
          class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
        >
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="flex min-w-0 flex-1 items-center">
              <div class="min-w-0 flex-1 md:grid md:grid-cols-9 md:gap-2">
                <div
                  class="flex items-center justify-between gap-x-2 md:col-span-3"
                >
                  <span class="text-sm font-medium">
                    {{ flow.name }}
                  </span>
                </div>
                <div
                  class="flex items-center justify-between gap-x-2 md:col-span-4"
                >
                  <div
                    v-if="flow.vacancies.length > 0"
                    class="mr-auto hidden lg:flex lg:gap-2"
                  >
                    <span
                      v-if="flow.firstFlowVacancy"
                      class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >{{
                        `№ ${flow.firstFlowVacancy.id} - ${flow.firstFlowVacancy.company} - ${flow.firstFlowVacancy.title}`
                      }}</span
                    >
                    <span
                      v-else
                      class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >{{ `№ ${flow.vacancies[0]}` }}</span
                    >
                    <span
                      v-if="flow.vacancies.length > 1"
                      class="inline-flex items-center rounded-full px-0.5 py-0.5 text-xs font-medium text-gray-600"
                      >and {{ flow.vacancies.length - 1 }} more
                    </span>
                    <span
                      v-if="flowListStore.isLoading"
                      class="inline-flex animate-pulse items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                      >...</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-wrap items-center justify-between gap-x-2 md:col-span-2"
                >
                  <span
                    v-if="flow.archived"
                    class="ml-0 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 xl:ml-auto"
                    >{{ t('archived') }}</span
                  >
                  <span
                    :class="{ 'ml-auto': !flow.archived }"
                    class="inline-flex items-center rounded-full bg-sky-100 px-2.5 py-0.5 text-xs font-medium text-sky-800"
                    >{{ t(`candidates.flowType.${flow.flowTypeSlug}`) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import EmptyStateCard from '../components/EmptyStateCard.vue'
import { computed, ref, onBeforeMount, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useFlowListStore } from '../store/flowListStore'
import { useI18n } from 'vue-i18n'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { SparklesIcon } from '@heroicons/vue/24/outline'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/24/outline'

const flowListStore = useFlowListStore()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const route = useRoute()
const router = useRouter()

const hasFiltersApplied = computed(
  () => flowListStore.filter !== '' || flowListStore.flowTypeSlug !== ''
)

const isEmpty = computed(
  () =>
    !flowListStore.isLoading &&
    !hasFiltersApplied.value &&
    flowListStore.flows.length === 0
)

onUnmounted(() => {
  flowListStore.$reset()
})
onBeforeMount(() => {
  if (route.query.flowTypeSlug) {
    flowTypeSlug.value = route.query.flowTypeSlug
    flowListStore.flowTypeSlug = flowTypeSlug.value
  }
  flowListStore.load()
})

const flowTypes = [
  '',
  'vacancy-apply',
  'vacancy-orientation-day-apply',
  'vacancy-newsletter-signup',
  'company-jobalert',
  'contact-form',
  'share-by-email',
]
const flowTypeSlug = ref(flowTypes[0])

const addNewFlow = (flowType) => {
  router.push(`/flows/new?flowType=${flowType}`)
}

const updateFilter = (filter) => {
  flowListStore.filter = filter
  flowListStore.flowTypeSlug = flowTypeSlug.value
  flowListStore.page.number = 1

  router.push({
    query: {
      ...route.params.query,
      flowTypeSlug: flowTypeSlug.value,
    },
  })

  flowListStore.load()
}
const updatePageSize = (pageSize) => {
  flowListStore.page.size = pageSize
  flowListStore.load()
}
const toggleIncludeArchived = () => {
  flowListStore.includeArchived = !flowListStore.includeArchived
  flowListStore.load()
}
const updatePageNumber = (number) => {
  flowListStore.page.number = number
  flowListStore.load()
}
</script>
