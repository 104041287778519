<template>
  <div class="p-6">
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="baseUrl"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.baseUrl"
          :label="`${t('vacancy.flows.atsFields.baseUrl')}*`"
          :onInput="updateBaseUrl"
          :rules="websiteUrlRule"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="applyUrl"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.applyUrl"
          :label="`${t('vacancy.flows.atsFields.applyUrl')}`"
          :onInput="updateApplyUrl"
          :rules="{}"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="issuer"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.issuer"
          :label="`${t('vacancy.flows.atsFields.issuer')}*`"
          :onInput="updateIssuer"
          :rules="requiredRule"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="publicKey"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.publicKey"
          :label="`${t('vacancy.flows.atsFields.publicKey')}*`"
          :onInput="updatePublicKey"
          :rules="requiredRule"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 mb-3 mt-8 text-base font-medium">
        {{ t('vacancy.flows.atsFields.privateFields') }}
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="privateKey"
          type="password"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.secretParams.privateKey"
          :label="`${t('vacancy.flows.atsFields.privateKey')}*`"
          :onInput="updatePrivateKey"
          :rules="requiredRule"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { required, helpers, url } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { useFlowStore } from '../../store/flowStore'
import FormInput from '../FormInput.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const props = defineProps({
  isPreview: {
    type: Boolean,
    default: false,
  },
})

const requiredRule = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const websiteUrlRule = {
  url: helpers.withMessage(t('validations.invalidUrl'), url),
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const updateBaseUrl = (value) => {
  flowStore.activeAction.config.params.baseUrl = value
}
const updateIssuer = (value) => {
  flowStore.activeAction.config.params.issuer = value
}
const updatePublicKey = (value) => {
  flowStore.activeAction.config.params.publicKey = value
}
const updateApplyUrl = (value) => {
  flowStore.activeAction.config.params.applyUrl = value
}
const updatePrivateKey = (value) => {
  flowStore.activeAction.config.secretParams.privateKey = value
}
const flowStore = useFlowStore()
</script>
