import { defineStore } from 'pinia'
import { parseJSON } from 'date-fns'
import { useSettingsStore } from './settingsStore'
import { useAuthStore } from './authStore'
import tooPublishers from '../api/tooPublishers'
import tooManagement from '../api/tooManagement'

const parseVacancyCreationDateAsUTC = (vacancy) => ({
  ...vacancy,
  creationDate: parseJSON(vacancy.creationDate),
})

export const useVacancyListStore = defineStore('vacancyListStore', {
  state: () => ({
    isLoading: false,
    error: null,
    vacancies: [],
    filter: '',
    sortBy: 'id',
    sortOrder: 'desc',
    includeArchived: false,
    page: {
      number: 1,
      size: 10,
      totalElements: 0,
      totalPages: 0,
    },
  }),
  actions: {
    async load() {
      this.error = null
      this.isLoading = true
      const settingsStore = useSettingsStore()
      const authStore = useAuthStore()

      try {
        const params = {
          filter: this.filter,
          page: this.page.number,
          size: this.page.size,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          includeArchived: this.includeArchived,
        }
        const vacancies =
          !settingsStore.activePublisher.id && authStore.isAdmin
            ? await tooManagement.getVacancies(params)
            : await tooPublishers.getVacancies(params)
        // Check if this is the page we requested.
        // Useful if a user requests the next page before the previous page was loaded.
        if (vacancies.meta.page.number === this.page.number) {
          this.vacancies = vacancies.data.map(parseVacancyCreationDateAsUTC)
          this.page = vacancies.meta.page
        }
      } catch (error) {
        this.vacancies = []
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
  },
})
