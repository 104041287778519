<template>
  <div class="bg-white shadow sm:rounded-md">
    <div class="px-5 py-5">
      <div class="text-lg font-medium leading-6 text-gray-900">
        {{ t('socialCampaigns.networksTitle') }}
      </div>

      <div class="text-sm font-normal text-gray-900">
        {{ t('socialCampaigns.networksSubtitle') }}
      </div>

      <div class="mt-5 text-sm font-normal text-gray-900">
        {{ t('socialCampaigns.networksDescription') }}
      </div>

      <div class="mt-5 flex items-center justify-between rounded p-5">
        <img
          class="w-48"
          src="/social_icons.png"
          alt="Logo Jobsrepublic Publisher"
        />
        <Toggle
          srLabel="Social networks advertising"
          :checked="vacancyStore.hasSocialCampaignAds"
          :onToggle="onToggle"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Toggle from '../Toggle.vue'
import { useI18n } from 'vue-i18n'
import { useVacancyStore } from '../../store/vacancyStore'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const vacancyStore = useVacancyStore()

const onToggle = () => {
  vacancyStore.hasSocialCampaignAds = !vacancyStore.hasSocialCampaignAds
}
</script>
