const s3BaseUrl =
  /^https:\/\/s3-eu-west-1.amazonaws.com\/jobsrepublic-too-images(-test|-dev)?\//
const s3BaseUrlAlt =
  /^https:\/\/jobsrepublic-too-images(-test|-dev)?.s3.eu-west-1.amazonaws.com\//

/**
 *
 * @param {Object}
 * imgUrl: url String
 * @returns parsed url String
 */
export function getImgSrc({ imgUrl, ...options }) {
  if (s3BaseUrl.test(imgUrl) || s3BaseUrlAlt.test(imgUrl)) {
    return getImgSrcS3({ imgUrl, ...options })
  }

  return imgUrl
}

/**
 *
 * @param {Object}
 * imgUrl: url String
 * width, height: numeric pixel value
 * quality: fractionary numeric value (from 0 to 1)
 * trim: String see imgix docs
 * trimcolor: String see imgix docs
 * @returns parsed url string
 */
function getImgSrcS3({
  imgUrl,
  width,
  height,
  quality,
  trim, // 'color', 'auto'
  trimcolor, // 'ffffff'
  smart = false,
  fit,
  crop,
}) {
  const queryParams = []

  if (typeof width !== 'undefined') {
    queryParams.push(`w=${width}`)
  }

  if (typeof height !== 'undefined') {
    queryParams.push(`h=${height}`)
  }
  if (typeof quality !== 'undefined') {
    queryParams.push(`q=${quality}`)
  }
  if (typeof trim !== 'undefined') {
    queryParams.push(`trim=${trim}`)
  }
  if (typeof trimcolor !== 'undefined') {
    queryParams.push(`trimcolor=${trimcolor}`)
  }
  if (typeof fit !== 'undefined') {
    queryParams.push(`fit=${fit}`)
  }
  if (typeof crop !== 'undefined') {
    queryParams.push(`crop=${crop}`)
  }

  if (smart) {
    queryParams.push('fit=facearea')
  }

  const queryString = queryParams.length
    ? `?${queryParams.sort().join('&')}`
    : ''

  const prefix = s3BaseUrl.test(imgUrl) ? s3BaseUrl : s3BaseUrlAlt
  return `${imgUrl.replace(
    prefix,
    'https://jobsrepublic-too-images$1.imgix.net/'
  )}${queryString}`
}
