<template>
  <div :class="[subtitle ? 'mb-6' : 'mb-4']">
    <h1 class="flex items-center text-2xl font-semibold text-gray-900">
      {{ titleDisplay }}
      <LoadingSpinner v-if="isLoading" class="inline h-5 w-5 text-gray-400" />
      <span
        v-if="pill"
        class="mx-2 rounded-full bg-white px-2 text-xs font-semibold leading-5 text-gray-800"
      >
        {{ pill === 'new' ? '' : '№' }} {{ pill }}
      </span>
    </h1>

    <p v-if="subtitle" class="mt-1 truncate text-sm text-gray-500">
      {{ subtitle }}
    </p>
  </div>
</template>

<script setup>
import LoadingSpinner from './LoadingSpinner.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  pill: {
    type: [String, Number],
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const titleDisplay = computed(() =>
  props.title ? props.title : t('untitledEntity'),
)
</script>
