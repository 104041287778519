<template>
  <div
    v-for="executionFlow in candidateStore.candidate.flowExecutions"
    :key="executionFlow.flowId"
    class="mt-5 overflow-hidden bg-white shadow sm:rounded-lg"
  >
    <div
      class="flex flex-col justify-between gap-2 px-4 py-5 sm:flex-row sm:items-center sm:px-5"
    >
      <h3
        class="flex items-center gap-2 text-lg font-medium leading-6 text-gray-900"
      >
        {{ executionFlow.flowName }}

        <span
          class="inline-flex h-fit items-center rounded-full px-2.5 py-0.5 text-xs font-medium"
          :class="{
            'bg-blue-100 text-blue-800':
              normalizeStatus(executionFlow.state) === 'pending' ||
              normalizeStatus(executionFlow.state) === 'skipped',
            'bg-green-100 text-green-800':
              normalizeStatus(executionFlow.state) === 'success',
            'bg-red-100 text-red-800':
              normalizeStatus(executionFlow.state) === 'failed',
          }"
          >{{ executionFlow.state }}</span
        >
      </h3>
      <div v-if="executionFlow.executionDate" class="flex items-center gap-2">
        <span class="text-xs text-gray-600">
          {{ toLocalDateTime(new Date(executionFlow.executionDate)) }}</span
        >

        <span
          class="w-fit rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-semibold text-gray-800"
          >{{ timeAgo.format(new Date(executionFlow.executionDate)) }}</span
        >
      </div>
    </div>

    <div
      class="flex flex-col items-center break-words rounded-b-md border-t border-gray-200 bg-gray-100/50 p-6 sm:flex-row sm:px-5"
    >
      <template
        v-for="(action, index) in executionFlow.actionExecutions"
        :key="action.id"
      >
        <button
          type="button"
          class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        >
          <EnvelopeIcon
            v-if="action.flowActionTypeSlug === 'send-email'"
            class="-ml-1 mr-3 w-5"
          />

          <EllipsisHorizontalCircleIcon
            v-if="normalizeStatus(action.state) === 'pending'"
            class="absolute -right-3 -top-3 w-7 fill-white text-blue-500"
          />
          <CheckCircleIcon
            v-if="normalizeStatus(action.state) === 'success'"
            class="absolute -right-3 -top-3 w-7 fill-white text-green-500"
          />
          <MinusCircleIcon
            v-if="normalizeStatus(action.state) === 'skipped'"
            class="absolute -right-3 -top-3 w-7 fill-white text-gray-500"
          />
          <XCircleIcon
            v-if="normalizeStatus(action.state) === 'failed'"
            class="absolute -right-3 -top-3 w-7 fill-white text-red-500"
          />

          {{ action.flowActionName }}
        </button>

        <span
          v-if="index !== executionFlow.actionExecutions.length - 1"
          class="mx-1 rotate-90 text-gray-300 sm:hidden"
          >-&gt;</span
        >
        <span
          v-if="index !== executionFlow.actionExecutions.length - 1"
          class="mx-1 hidden text-gray-300 sm:block"
          >---&gt;</span
        >
      </template>
    </div>

    <p
      v-if="flowErrorMessage(executionFlow)"
      class="bg-red-50 px-5 py-4 text-sm text-red-800"
    >
      {{ flowErrorMessage(executionFlow) }}
    </p>
  </div>
</template>

<script setup>
import { useCandidateStore } from '../../../store/candidateStore'
import {
  EnvelopeIcon,
  CheckCircleIcon,
  XCircleIcon,
  EllipsisHorizontalCircleIcon,
  MinusCircleIcon,
} from '@heroicons/vue/24/outline'
import TimeAgo from 'javascript-time-ago'
import { format } from 'date-fns'

const candidateStore = useCandidateStore()

const timeAgo = new TimeAgo()
const toLocalDateTime = (applicationDate) => {
  return applicationDate ? format(applicationDate, 'dd MMM yyyy, HH:mm') : ''
}

const normalizeStatus = (status) => {
  let newStatus = ''
  switch (status) {
    case 'Processing':
    case 'Pending':
      newStatus = 'pending'
      break
    case 'Skipped':
      newStatus = 'skipped'
      break
    case 'Successful':
      newStatus = 'success'
      break
    case 'Temporary failure':
    case 'Failure':
      newStatus = 'failed'
  }

  return newStatus
}

const flowErrorMessage = (flow) => {
  if (normalizeStatus(flow.state) === 'failed') {
    const failedAction = flow.actionExecutions.find(
      (action) => normalizeStatus(action.state) === 'failed',
    )
    return failedAction?.error || ''
  }
  return ''
}
</script>
