import {
  CogIcon,
  ClipboardDocumentListIcon,
  UserGroupIcon,
  BuildingOfficeIcon,
  SparklesIcon,
  GlobeAltIcon,
  UserIcon,
  BellAlertIcon,
  ScissorsIcon,
  CalendarDaysIcon,
} from '@heroicons/vue/24/outline'

export const navigation = [
  {
    name: 'menu.sites',
    to: '/sites',
    icon: GlobeAltIcon,
    onlyAdmin: true,
  },
  {
    name: 'menu.companies',
    to: '/companies',
    icon: BuildingOfficeIcon,
    onlyAdmin: true,
  },
  {
    name: 'menu.subscriptions',
    to: '/subscriptions',
    icon: CalendarDaysIcon,
    onlyAdmin: true,
  },
  {
    name: 'menu.publishers',
    to: '/publishers',
    icon: UserIcon,
    onlyAdmin: true,
  },
  {
    name: 'menu.allCandidates',
    to: '/candidates',
    icon: UserGroupIcon,
    onlyAdmin: true,
    onlyCampaignManager: true,
  },
  {
    name: 'menu.vacancies',
    to: '/vacancies',
    icon: ClipboardDocumentListIcon,
    alwaysShow: true,
  },
  {
    name: 'menu.flows',
    to: '/flows',
    icon: SparklesIcon,
    onlyAdmin: true,
    hideIfNoPublisherSelected: true,
  },
  {
    name: 'menu.salaryScales',
    to: '/salary-scales',
    icon: ScissorsIcon,
    onlyAdmin: true,
  },
  {
    name: 'menu.jobAlerts',
    to: '/job-alerts',
    icon: BellAlertIcon,
    onlyAdmin: true,
  },
  {
    name: 'menu.settings',
    to: '/settings',
    icon: CogIcon,
    alwaysShow: true,
  },
]
