<template>
  <div class="py-6">
    <div class="max-w-7xl px-4 sm:px-6 md:px-8">
      <ListHeader
        :title="t('menu.sites')"
        :isLoading="siteListStore.isLoading"
        :enableSearch="true"
        :searchPlaceholder="t('sites.searchPlaceholder')"
        :searchTerm="siteListStore.filter"
        @update:searchTerm="updateFilter"
      >
        <router-link
          to="/sites/new/site"
          class="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {{ t('sites.createNew') }}
        </router-link>
      </ListHeader>

      <ErrorMessage v-if="siteListStore.error" :error="siteListStore.error" />

      <List
        v-slot="{ entity: site }"
        :entities="siteListStore.sites"
        :page="siteListStore.page"
        :updatePageNumber="updatePageNumber"
        :updatePageSize="updatePageSize"
      >
        <router-link
          :to="`/sites/${site.id}/site`"
          class="block text-indigo-600 hover:bg-gray-50 focus:underline focus:outline-none"
        >
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="truncate text-sm font-medium text-indigo-600">
                {{ site.name }}
              </p>
              <div class="ml-2 flex shrink-0">
                <p class="flex items-center text-xs text-gray-500">
                  {{ site.siteUrl }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </List>
    </div>
  </div>
</template>

<script setup>
import ListHeader from '../components/ListHeader.vue'
import List from '../components/List.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import { useSiteListStore } from '../store/siteListStore'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const siteListStore = useSiteListStore()
siteListStore.filter = ''
siteListStore.load()

const updateFilter = (filter) => {
  siteListStore.filter = filter
  siteListStore.page.number = 1
  siteListStore.load()
}
const updatePageSize = (pageSize) => {
  siteListStore.page.size = pageSize
  siteListStore.load()
}
const updatePageNumber = (number) => {
  siteListStore.page.number = number
  siteListStore.load()
}
</script>
