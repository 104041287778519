<template>
  <div class="flex flex-col gap-y-4">
    <div class="flex flex-col rounded-md bg-white shadow-md">
      <VacancyFlowSelectbox flowTypeSlug="vacancy-apply" />

      <div v-if="applyFlow">
        <FlowPreview :flowId="applyFlow.id" />
      </div>
    </div>
    <div class="flex flex-col rounded-md bg-white shadow-md">
      <VacancyFlowSelectbox flowTypeSlug="vacancy-orientation-day-apply" />

      <div v-if="orientationDayApplyFlow">
        <FlowPreview :flowId="orientationDayApplyFlow.id" />
      </div>
    </div>
    <div class="flex flex-col rounded-md bg-white shadow-md">
      <VacancyFlowSelectbox flowTypeSlug="contact-form" />

      <div v-if="contactFormFlow">
        <FlowPreview :flowId="contactFormFlow.id" />
      </div>
    </div>
    <div class="flex flex-col rounded-md bg-white shadow-md">
      <VacancyFlowSelectbox flowTypeSlug="share-by-email" />

      <div v-if="shareByEmailFlow">
        <FlowPreview :flowId="shareByEmailFlow.id" />
      </div>
    </div>
    <div class="flex flex-col rounded-md bg-white shadow-md">
      <VacancyFlowSelectbox flowTypeSlug="company-jobalert" />

      <div v-if="companyJobAlertFlow">
        <FlowPreview :flowId="companyJobAlertFlow.id" />
      </div>
    </div>
    <div class="flex flex-col rounded-md bg-white shadow-md">
      <VacancyFlowSelectbox flowTypeSlug="vacancy-newsletter-signup" />

      <div v-if="newsletterSignupFlow">
        <FlowPreview :flowId="newsletterSignupFlow.id" />
      </div>
    </div>
    <FormSave
      :validationErrors="v$.$errors"
      :validationTouch="v$.$touch"
      storeAction="saveVacancyFlows"
      storeName="vacancy"
    />
  </div>
</template>

<script setup>
import VacancyFlowSelectbox from './VacancyFlowSelectbox.vue'
import FormSave from '../FormSave.vue'
import FlowPreview from '../flows/FlowPreview.vue'
import { useVuelidate } from '@vuelidate/core'
import { useVacancyStore } from '../../store/vacancyStore'
import { useFlowStore } from '../../store/flowStore'
import { computed, onUnmounted } from 'vue'

const vacancyStore = useVacancyStore()
const flowStore = useFlowStore()

onUnmounted(() => {
  flowStore.$reset()
})

const applyFlow = computed(() => {
  return vacancyStore.vacancyFlows.find(
    (flow) => flow.flowTypeSlug === 'vacancy-apply' || null,
  )
})
const orientationDayApplyFlow = computed(() => {
  return vacancyStore.vacancyFlows.find(
    (flow) => flow.flowTypeSlug === 'vacancy-orientation-day-apply' || null,
  )
})
const contactFormFlow = computed(() => {
  return vacancyStore.vacancyFlows.find(
    (flow) => flow.flowTypeSlug === 'contact-form' || null,
  )
})
const shareByEmailFlow = computed(() => {
  return vacancyStore.vacancyFlows.find(
    (flow) => flow.flowTypeSlug === 'share-by-email' || null,
  )
})
const companyJobAlertFlow = computed(() => {
  return vacancyStore.vacancyFlows.find(
    (flow) => flow.flowTypeSlug === 'company-jobalert' || null,
  )
})
const newsletterSignupFlow = computed(() => {
  return vacancyStore.vacancyFlows.find(
    (flow) => flow.flowTypeSlug === 'vacancy-newsletter-signup' || null,
  )
})

const v$ = useVuelidate()
</script>
