import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: ['en'],
  fallbackWarn: false,
  messages,
})

const { t } = i18n.global

export const campaignManagerActions = [
  {
    label: `<span>${t(
      'candidates.actions.markAs',
    )}</span><span class="inline-flex items-center rounded-full ml-1 bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">${t(
      'candidates.status.test',
    )}</span>`,
    value: 'markAsTest',
  },
  {
    label: `<span>${t(
      'candidates.actions.remove',
    )}</span><span class="inline-flex items-center rounded-full ml-1 mr-1 bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">${t(
      'candidates.status.test',
    )}</span><span>${t('candidates.actions.mark')}</span>`,
    value: 'removeTest',
  },
  {
    label: `<span>${t(
      'candidates.actions.markAs',
    )}</span><span class="inline-flex items-center rounded-full ml-1 bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-8000">${t(
      'candidates.status.duplicate',
    )}</span>`,
    value: 'markAsDuplicate',
  },
  {
    label: `<span>${t(
      'candidates.actions.remove',
    )}</span><span class="inline-flex items-center rounded-full ml-1 mr-1 bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-8000">${t(
      'candidates.status.duplicate',
    )}</span><span>${t('candidates.actions.mark')}</span>`,
    value: 'removeDuplicate',
  },
]
