import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './index.css'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'
import dutch from 'i18n-iso-countries/langs/nl.json'

TimeAgo.addDefaultLocale(en)

countries.registerLocale(english)
countries.registerLocale(dutch)

export const i18n = createI18n({
  legacy: false,
  fallbackLocale: ['en'],
  fallbackWarn: false,
  missing: (locale, key) => {
    // We only care about missing translations if:
    // - The user is on a non-admin view
    // - The user is on an admin view and the english translation is missing (admin sections are not translated to other languages)
    const adminsOnly = router.currentRoute.value.meta.onlyAdmin
    if (!adminsOnly || locale === 'en') {
      console.warn(`Not found '${key}' key in '${locale}' locale messages.`)
    }
  },
  messages,
})

let app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Sentry.init({
  app,
  dsn: 'https://95765e4cfd5b4a169613f5dcbe36f6fb@o200990.ingest.sentry.io/5951932',
  environment: import.meta.env.VITE_ENV,
  denyUrls: ['localhost', 'local-dev.io'],
})

app.use(i18n)
app.use(pinia)
app.use(router)
app.mount('#app')
