<template>
  <div class="p-6">
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="vacancyId"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.vacancyId"
          :label="`${t('vacancy.flows.atsFields.vacancyId')}*`"
          :onInput="updateVacancyId"
          :rules="requiredRule"
        />
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="countryCode"
          type="text"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.countryCode"
          :label="`${t('vacancy.flows.atsFields.countryCode')}*`"
          :onInput="updateCountryCode"
          :rules="requiredRule"
        />
      </div>
      <div class="col-span-2">
        <FormInput
          id="afasUrl"
          type="url"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.params.afasUrl"
          :label="`${t('vacancy.flows.atsFields.afasUrl')}*`"
          :onInput="updateAfasUrl"
          :rules="websiteUrlRule"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 mb-3 mt-8 text-base font-medium">
        {{ t('vacancy.flows.atsFields.privateFields') }}
      </div>
      <div class="col-span-2 lg:col-span-1">
        <FormInput
          id="afasToken"
          type="password"
          :disabled="isPreview"
          :inputClass="isPreview ? 'bg-gray-100' : ''"
          :value="flowStore.activeAction.config.secretParams.afasToken"
          :label="`${t('vacancy.flows.atsFields.afasToken')}*`"
          :onInput="updateAfasToken"
          :rules="requiredRule"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { required, helpers, url } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { useFlowStore } from '../../store/flowStore'
import FormInput from '../FormInput.vue'

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const props = defineProps({
  isPreview: {
    type: Boolean,
    default: false,
  },
})

const requiredRule = {
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const websiteUrlRule = {
  url: helpers.withMessage(t('validations.invalidUrl'), url),
  required: helpers.withMessage(t('validations.emptyField'), required),
}
const updateVacancyId = (value) => {
  flowStore.activeAction.config.params.vacancyId = value
}
const updateCountryCode = (value) => {
  flowStore.activeAction.config.params.countryCode = value
}
const updateAfasUrl = (value) => {
  flowStore.activeAction.config.params.afasUrl = value
}
const updateAfasToken = (value) => {
  flowStore.activeAction.config.secretParams.afasToken = value
}
const flowStore = useFlowStore()
</script>
