<template>
  <Multiselect
    id="publisherSalaryScales"
    ref="multiselect"
    :modelValue="publisherStore.salaryScaleGroups"
    :max-height="400"
    :options="
      async function (query) {
        return await fetchSalaryScaleGroups(query)
      }
    "
    :filterResults="false"
    :minChars="0"
    :delay="0"
    searchable
    object
    valueProp="id"
    trackBy="name"
    label="name"
    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    mode="tags"
    @change="(e) => updateSalaryScaleGroups(e)"
  />
</template>

<script setup>
import Multiselect from '@vueform/multiselect'
import { useSalaryScaleGroupListStore } from '../../store/salaryScaleGroupListStore'
import { usePublisherStore } from '../../store/publisherStore'
import { onUnmounted } from 'vue'

const publisherStore = usePublisherStore()
const salaryScaleListStore = useSalaryScaleGroupListStore()

const updateSalaryScaleGroups = (salaryScaleGroups) => {
  publisherStore.salaryScaleGroups = salaryScaleGroups
}

let firstFetch = true
const fetchSalaryScaleGroups = async (query) => {
  query = query || ''
  if (firstFetch) {
    firstFetch = false
    await salaryScaleListStore.load()
  }

  return salaryScaleListStore.salaryScaleGroups.filter((group) =>
    group.name?.toLowerCase().includes(query.toLowerCase()),
  )
}

onUnmounted(() => {
  salaryScaleListStore.$reset()
})
</script>
