import { useAuthStore } from '../store/authStore.js'
import { useSettingsStore } from '../store/settingsStore'
import router from '../router.js'

const baseUrlsList = {
  too: import.meta.env.VITE_API_BASE_URL,
  candidateGateway: import.meta.env.VITE_API_CG_BASE_URL,
  vacancyImporter: import.meta.env.VITE_API_VI_BASE_URL,
}

const getBaseUrl = (baseUrlEndpoint) =>
  baseUrlsList[baseUrlEndpoint] !== undefined
    ? baseUrlsList[baseUrlEndpoint]
    : baseUrlsList['too']

const checkForErrors = async (response) => {
  let responseData
  try {
    responseData = await response.json()
  } catch (e) {
    //if there is no response, return empty object
    responseData = { data: {} }
  }
  // check for error response
  if (response.status >= 400) {
    // Show 403 page if server returned HTTP 403
    if (response.status === 403) {
      router.replace('/403')
    }

    const errorObj = responseData.error || responseData

    // get error message from body or default to response statusText
    const error =
      `${errorObj?.title}: ${errorObj?.detail}` || response.statusText
    return Promise.reject(error)
  }
  // return the data object from the result
  return responseData
}

const getCustomHeaders = () => {
  const settingsStore = useSettingsStore()
  if (settingsStore.activePublisher.id) {
    return { 'X-Publisher': settingsStore.activePublisher.id }
  } else {
    return {}
  }
}

const get = async (
  path,
  searchParams,
  customHeaders,
  baseUrlEndpoint = null,
) => {
  const authStore = useAuthStore()
  await authStore.checkTokenSilently()
  const params = new URLSearchParams(searchParams)
  const headers = {
    ...customHeaders,
    ...{
      Authorization: `Bearer ${authStore.token}`,
    },
  }
  const baseUrl = getBaseUrl(baseUrlEndpoint)

  const response = await fetch(`${baseUrl}/${path}?${params.toString()}`, {
    headers: headers,
  })

  return await checkForErrors(response)
}

const post = async (path, payload, customHeaders, baseUrlEndpoint = null) => {
  const authStore = useAuthStore()
  await authStore.checkTokenSilently()
  const headers = {
    ...customHeaders,
    ...{
      Authorization: `Bearer ${authStore.token}`,
      'Content-Type': 'application/json',
    },
  }
  const baseUrl = getBaseUrl(baseUrlEndpoint)

  const response = await fetch(`${baseUrl}/${path}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload),
  })

  return await checkForErrors(response)
}

const put = async (path, payload, customHeaders, baseUrlEndpoint) => {
  const authStore = useAuthStore()
  await authStore.checkTokenSilently()
  const headers = {
    ...customHeaders,
    ...{
      Authorization: `Bearer ${authStore.token}`,
      'Content-Type': 'application/json',
    },
  }
  const baseUrl = getBaseUrl(baseUrlEndpoint)
  const options = {
    method: 'PUT',
    headers,
  }
  if (payload) {
    options.body = JSON.stringify(payload)
  }

  const response = await fetch(`${baseUrl}/${path}`, options)

  return await checkForErrors(response)
}

const patch = async (path, payload, customHeaders, baseUrlEndpoint) => {
  const authStore = useAuthStore()
  await authStore.checkTokenSilently()
  const headers = {
    ...customHeaders,
    ...{
      Authorization: `Bearer ${authStore.token}`,
      'Content-Type': 'application/json',
    },
  }
  const baseUrl = getBaseUrl(baseUrlEndpoint)

  const response = await fetch(`${baseUrl}/${path}`, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(payload),
  })

  return await checkForErrors(response)
}

const del = async (path, payload, customHeaders, baseUrlEndpoint) => {
  const authStore = useAuthStore()
  await authStore.checkTokenSilently()
  const headers = {
    ...customHeaders,
    ...{
      Authorization: `Bearer ${authStore.token}`,
      'Content-Type': 'application/json',
    },
  }
  const baseUrl = getBaseUrl(baseUrlEndpoint)
  const options = {
    method: 'DELETE',
    headers: headers,
  }
  if (payload) {
    options.body = JSON.stringify(payload)
  }

  const response = await fetch(`${baseUrl}/${path}`, options)

  return await checkForErrors(response)
}

const postImage = async (path, imageFile, customHeaders, baseUrlEndpoint) => {
  const authStore = useAuthStore()
  await authStore.checkTokenSilently()

  let formdata = new FormData()
  formdata.append('imageFile', imageFile)
  const headers = {
    ...customHeaders,
    ...{
      Authorization: `Bearer ${authStore.token}`,
    },
  }
  const baseUrl = getBaseUrl(baseUrlEndpoint)

  const response = await fetch(`${baseUrl}/${path}`, {
    method: 'POST',
    headers: headers,
    body: formdata,
  })

  const data = await checkForErrors(response)

  return data
}

export {
  get,
  post,
  put,
  patch,
  del,
  postImage,
  checkForErrors,
  getCustomHeaders,
}
