<template>
  <div class="p-6">
    <div class="mb-10 flex items-start justify-start">
      <PageHeader
        :title="flowStore.flow.name"
        :isLoading="flowStore.isLoading"
      />
      <span
        class="mx-2 mt-1.5 items-center rounded-full bg-green-100 px-2.5 py-1 text-xs font-medium text-green-800 sm:inline-flex"
        >{{ t(`candidates.flowType.${flowStore.flow.flowTypeSlug}`) }}</span
      >
    </div>

    <TwoColumnForm :title="t('vacancy.flows.cardTitle')">
      <TwoColumnFormCard>
        <div class="mb-4">
          <label class="mb-1 block text-sm font-medium text-gray-700">
            {{ t('archived') }}
          </label>
          <Toggle
            :srLabel="t('archived')"
            :checked="flowStore.flow.archived"
            :onToggle="onToggle"
          />
        </div>
        <FormInput
          id="flowName"
          type="text"
          label="Name"
          :value="flowStore.flow.name"
          :onInput="renameFlowName"
          :rules="flowNameRules"
        />
      </TwoColumnFormCard>
    </TwoColumnForm>
    <TwoColumnFormDivider />
    <TwoColumnForm :title="t('vacancy.flows.companies')">
      <TwoColumnFormCard>
        <Companies />
      </TwoColumnFormCard>
    </TwoColumnForm>
    <TwoColumnFormDivider />
    <TwoColumnForm :title="t('vacancy.flows.actions')" />

    <div
      class="my-6 w-full bg-white p-3 text-left text-base text-gray-700 shadow sm:rounded-md"
    >
      <div class="flow-actions flex flex-wrap items-center px-6 py-3">
        <InputActionButton />
        <FlowAction
          v-for="(action, actionIndex) in flowStore.flowActions"
          :key="actionIndex"
          :action="action"
          :flow="flowStore.flow"
          :actionIndex="actionIndex"
          @rename-flow-action="renameFlowAction"
        />
        <NewFlowAction :isLast="true" :flow="flowStore.flow" />
      </div>
      <EmailActionContent v-if="isActiveAction('send-email')" />
      <RecruiteeAtsAction
        v-if="isActiveAction('submit-candidate-to-ats-recruitee')"
      />
      <MysolutionAtsAction
        v-if="isActiveAction('submit-candidate-to-ats-mysolution')"
      />
      <AfasAtsAction v-if="isActiveAction('submit-candidate-to-ats-afas')" />
      <ConnexysAtsAction
        v-if="isActiveAction('submit-candidate-to-ats-connexys')"
      />
    </div>

    <div class="relative">
      <FormSave
        :validationErrors="v$.$errors"
        :validationTouch="v$.$touch"
        :storeAction="'saveAll'"
        :storeName="'flow'"
      />
      <button
        v-if="flowStore.flow.id !== 'new'"
        type="button"
        :class="{
          'right-44': flowStore.isSavedSuccessfully,
          'right-28': flowStore.isSaving,
          'right-20': !flowStore.isSaving,
        }"
        class="absolute bottom-0 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        @click="duplicateFlow"
      >
        {{ t('vacancy.flows.duplicate') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { onUnmounted } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useFlowStore } from '../store/flowStore'
import { helpers, required } from '@vuelidate/validators'
import FlowAction from '../components/flows/FlowAction.vue'
import RecruiteeAtsAction from '../components/flows/RecruiteeAtsAction.vue'
import MysolutionAtsAction from '../components/flows/MysolutionAtsAction.vue'
import AfasAtsAction from '../components/flows/AfasAtsAction.vue'
import FormInput from '../components/FormInput.vue'
import PageHeader from '../components/PageHeader.vue'
import FormSave from '../components/FormSave.vue'
import TwoColumnForm from '../components/TwoColumnForm.vue'
import Toggle from '../components/Toggle.vue'
import TwoColumnFormCard from '../components/TwoColumnFormCard.vue'
import TwoColumnFormDivider from '../components/TwoColumnFormDivider.vue'
import InputActionButton from '../components/flows/InputActionButton.vue'
import NewFlowAction from '../components/flows/NewFlowAction.vue'
import EmailActionContent from '../components/flows/EmailActionContent.vue'
import Companies from '../components/flows/Companies.vue'
import ConnexysAtsAction from '../components/flows/ConnexysAtsAction.vue'

const flowStore = useFlowStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})
const v$ = useVuelidate()
flowStore.load(route.params.id, route?.query?.flowType)

onUnmounted(() => {
  flowStore.$reset()
})

const onToggle = () => {
  flowStore.flow.archived = !flowStore.flow.archived
}
const renameFlowAction = ({ action, name }) => {
  action.name = name
}
const flowNameRules = {
  required: helpers.withMessage(
    t('vacancy.flows.validationErrors.emptyFlow'),
    required,
  ),
  $autoDirty: true,
}

const renameFlowName = (value) => {
  flowStore.flow.name = value
}

const isActiveAction = (slug) => {
  return flowStore.activeAction.flowActionTypeSlug === slug
}

const duplicateFlow = () => {
  flowStore.duplicateFlow()
  router.push(`/flows/new?flowType=${flowStore.flow.flowTypeSlug}`)
}
</script>
