<template>
  <div class="col-span-6">
    <FormInput
      id="companySlug"
      type="text"
      :value="config.companySlug"
      :label="t('company.importer.connexys.companySlug')"
      :onInput="(value) => (config.companySlug = value)"
      :rules="requiredRule"
    />
  </div>
  <div class="col-span-6">
    <FormInput
      id="publicKey"
      type="text"
      :value="config.publicKey"
      :label="t('company.importer.connexys.publicKey')"
      :onInput="(value) => (config.publicKey = value)"
      :rules="requiredRule"
    />
  </div>
  <div class="col-span-6">
    <FormInput
      id="privateKey"
      type="password"
      :value="config.privateKey"
      :label="t('company.importer.connexys.privateKey')"
      :onInput="(value) => (config.privateKey = value)"
      :rules="requiredRule"
    />
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { required, helpers } from '@vuelidate/validators'
import FormInput from '../FormInput.vue'

const props = defineProps({ modelValue: Array })
const emit = defineEmits(['update:modelValue'])

const config = reactive({
  companySlug:
    props.modelValue?.find((prop) => prop.name === 'companySlug')?.value || '',
  publicKey:
    props.modelValue?.find((prop) => prop.name === 'publicKey')?.value || '',
  privateKey:
    props.modelValue?.find((prop) => prop.name === 'privateKey')?.value || '',
})

watch(config, (value) => {
  emit('update:modelValue', [
    {
      name: 'companySlug',
      value: value.companySlug,
      secret: false,
    },
    {
      name: 'publicKey',
      value: value.publicKey,
      secret: false,
    },
    {
      name: 'privateKey',
      value: value.privateKey,
      secret: true,
    },
  ])
})

const { t } = useI18n({
  inheritLocale: true,
  useScope: 'global',
})

const requiredRule = {
  required: helpers.withMessage(t('validations.emptyField'), required),
  $autoDirty: true,
}
</script>
